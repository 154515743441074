@import 'assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: row;

  .mainContainer {
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .content {
    .mainContainer {
      width: 100vw;
      height: auto;
    }
  }
}
