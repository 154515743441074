@import 'assets/styles/variables.scss';

.header_controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.row_hover {
  &:hover {
    & > td:last-child {
      .controls {
        display: flex;
      }
    }
  }
}

td.nameCell,
td.availableCell,
td.groupCell,
td.sourceCell {
  max-width: 0;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  -webkit-text-size-adjust: 100%;
  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }
}

td.nameCell {
  width: 60%;
  min-width: 400px;
}
td.sourceCell {
  width: 10%;
  min-width: 130px;
}
td.availableCell {
  width: 10%;
  min-width: 130px;
  text-align: center;
}
td.groupCell {
  width: 20%;
  min-width: 300px;
}

$button_width: 20px;
$gap: 10px;
$cell_width: calc((#{$button_width} * 2) + (#{$gap} * 3));

td.controlsCell {
  width: $cell_width;
  min-width: $cell_width;
  padding: 0;

  .controls {
    display: none;

    text-align: center;
    justify-content: center;
    column-gap: $gap;
    .icon_button {
      svg {
        color: black;
        width: $button_width;
        height: $button_width;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header_controls {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    margin-bottom: 0;
  }

  td.controlsCell {
    .controls {
      display: flex;
    }
  }
}
