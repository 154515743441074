@import 'assets/styles/variables.scss';

.error {
  position: absolute;
  font-weight: 400;
  font-size: 10px;
  left: 0;
  bottom: -16px;
  color: $error_color;
}
