@import 'assets/styles/variables.scss';

.tooltipBlock {
  position: relative;
  & svg {
    position: absolute;
    top: -14px;
    right: -8px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 100%;
    &:focus {
      outline: none;
    }
  }
}

div.tooltipBlock__text {
  font-family: $monserrat;
  background: $main_color;
  margin-bottom: 5px;
  font-size: 12px;
  padding: 10px;
  background: $main_color;
  border-radius: 8px;
  color: white;
  line-height: 10px;
  margin-left: 10px;
  white-space: nowrap;
  z-index: 3;
  span {
    color: $main_color;
  }
}
