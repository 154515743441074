@import 'assets/styles/variables.scss';

.popup {
  width: 465px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .content {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  &__row {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    z-index: 1305;

    & > svg {
      width: 20px;
      height: 20px;
      cursor: grab;
    }
  }

  .field_row {
    width: calc(100% - 20px - 15px);
  }
}
