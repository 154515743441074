// Font family
$monserrat: Montserrat, sans-serif;

// Colors
$main_color: #507a8e;
$secondary_color: #000;
$success_color: #00c600;
$regular_color: #7ec37e;
$alert_color: #da9898;
$warning_color: #e1cd80;
$error_color: #e92d2d;
$extra_color: #ffd83b;

$filters_secondary_color: #9a3d3e;

$project_csca_color: #bfbfbf;

$hovered-row-color: #e3eaed;

// Breakpoints
$breakpoint-443: 443px;
$breakpoint-1180: 1180px;
$breakpoint-1280: 1280px;
$breakpoint-1450: 1450px;

// Shadow
$shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$shadow_hover: 0 2px 4px -1px rgb(0, 0, 0, 0.2), 0px 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12);

// asset status colors
$operational-color: #7ec37e;
$non-critical-color: #e1cd80;
$critical-color: #da9898;
$beyond-repair-color: #0c0c0c;
$total-color: transparent;

// table styles
$no-data-table-color: #f7f7f7;

// fields
$field_default_height: 34px;

// sidebar
$sidebar_background_color: #383838;
$sidebar_scrollbar_track_color: rgb(176, 176, 176);
$sidebar_scrollbar_thumb_color: #656565;
$sidebar_item_active_background: rgba(255, 255, 255, 0.2);
$sidebar_search_bar_background_color: #000000;
$sidebar_search_field_main_color: #fff;

// table
$table_border_color: #dcdcdc;
$table_error_cell_background: #ff000012;

//home page colors
$card_background_color: #f6f6f6;
$card_controls_hover_color: #ffe680;
$card_text_color: #383838;

// Text
$highlighted_text_color: #add8e6;

:export {
  operationalColor: $operational-color;
  nonCriticalColor: $non-critical-color;
  criticalColor: $critical-color;
  beyondRepairColor: $beyond-repair-color;
  totalColor: $total-color;
}
