.statistics {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  &__dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}
