.block__cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 34px;
  position: relative;

  &_label {
    display: flex;
    align-items: center;
  }

  &_dates {
    display: flex;
    align-items: center;
    column-gap: 5px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  &.checkbox {
    width: 297px;
  }

  .radios {
    width: 310px;
    & > div {
      flex-direction: column;
      row-gap: 15px;
      & > label {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0;
        column-gap: 20px;
      }
    }
  }

  .separator {
    position: absolute;
    border-bottom: 1px solid rgba(220, 220, 220, 1);
    height: 0;
    width: 100%;
    top: -10px;
  }
}
