@import 'assets/styles/variables.scss';

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupContainer {
  width: 600px;
  max-width: 600px !important;
  padding: 20px 50px 35px;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.popupTitle h2,
.popupText p {
  font-family: $monserrat;
}

.popupText {
  padding: 0 !important;
  overflow: visible !important;
}

.popupTitle {
  padding: 0 !important;

  .headerTitle {
    margin-bottom: 30px;
    display: flex;
    h2 {
      font-weight: 700;
      font-size: 24px;
      max-width: 340px;
      line-height: 34px;
      text-align: left;
      margin: 0;
    }
  }
}

.formWrapper {
  .labelBlock {
    margin-bottom: 20px;
    & > span {
      font-size: 14px;
      font-family: $monserrat;
      line-height: 17px;
    }
  }
  .uploadWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
    & > label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      margin-bottom: 15px;
    }
    & > span {
      margin: 0;
      font-weight: 400;
      font-size: 10px;
      color: $error_color;
      font-family: $monserrat;
      position: absolute;
      top: 19px;
      left: 5px;
    }
    .fileBlock {
      display: flex;
      cursor: pointer;
      .fileName {
        font-size: 14px;
        line-height: 17px;
        font-family: $monserrat;
      }
      img {
        margin-right: 10px;
      }
    }
  }
}

.popupControls {
  padding: 0 !important;
  flex-direction: column;
  .buttonBlock {
    display: flex;
    margin: 20px 0 0 !important;
    button {
      width: 200px;
      min-width: 200px;
      &:first-child {
        margin: 0 30px 0 0 !important;
      }
      &:last-child {
        margin: 0 !important;
      }
    }
  }
}
