@import 'assets/styles/variables.scss';

.counts {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 220px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    font-family: $monserrat;
    font-size: 14px;
  }
  &__total {
    font-weight: 700;
  }
  &__number {
    font-weight: 700;
  }
}
