@import 'assets/styles/variables.scss';

.container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  &:has(> div:nth-child(2)) {
    grid-template-columns: 1fr 360px;
  }

  .filter,
  .legend {
    position: absolute;
    top: 30px;
    z-index: 1;
  }

  .filter {
    left: 30px;
    max-width: calc(100% - 60px - 230px);

    @media screen and (max-width: $breakpoint-1180) {
      top: auto;
      left: auto;
      right: 15px;
      bottom: 160px;
    }
  }

  .legend {
    right: 30px;

    @media screen and (max-width: $breakpoint-1180) {
      top: 80px;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}
