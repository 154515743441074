@import 'assets/styles/variables.scss';

.popup {
  width: 550px;
  padding: 40px 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 40px;
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
    & > button {
      width: 160px !important;
      min-width: 160px !important;
      height: 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .footer {
      & > button {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
