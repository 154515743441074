@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;

  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $main_color;

    &__controls {
      display: flex;
      align-items: center;
      column-gap: 20px;

      .downloadButton {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
        font-family: $monserrat;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        outline: none;
        padding: 0;
        img {
          margin-right: 10px;
        }
      }

      .chevronStyle {
        display: flex;
        border: none;
        background: none;
        cursor: pointer;
        width: 24px;
        height: 24px;

        & > svg,
        & > img {
          margin-right: -10px;
        }
      }
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
      padding-bottom: 10px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px;
    word-break: break-all;
    white-space: normal;
  }

  .commonControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 0;
    &__statuses {
      display: flex;
      justify-content: space-between;
      width: 475px;

      & > label {
        font-size: 14px;
        line-height: 32px;
      }
    }
    &__configuration {
      display: flex;
      align-items: center;
      column-gap: 20px;
      &_switcher {
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .formCell {
    display: flex;
    justify-content: space-between;
    width: 475px;
    margin: 20px 0 30px;

    label {
      font-size: 14px;
      line-height: 32px;
    }

    &.singleStatusCell {
      width: 370px;
      margin: 20px 0;
    }
  }

  .pageTopBlock {
    margin: 30px 0 20px;

    &__summary {
      display: flex;
    }
  }

  .pieChart {
    width: 300px;
    height: 100px;
  }

  .tableWrapper {
    position: relative;

    .menuButtons {
      position: absolute;
      top: -40px;
      right: 0;

      & > button {
        border-radius: 100%;
        min-width: 26px;
        height: 26px;
        color: #000;
        cursor: pointer;
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .menuContainer {
    padding: 10px 0;
    border-radius: 8px !important;

    .listWrapper {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      width: 250px;
      overflow-x: hidden;
      overflow-y: visible;
      padding: 0 10px;
      margin-bottom: 15px;
      box-sizing: border-box;

      label {
        margin: 5px 0;
        display: flex;
        align-items: flex-start;
        span {
          font-family: $monserrat;
          line-height: 24px;
          white-space: normal;
          word-break: break-all;
        }
      }

      hr {
        width: calc(100% - 2px);
        border-top: 1px solid $main_color;
        border-color: $main_color;
        background-color: $main_color;
        color: $main_color;
        border-bottom: none;
      }

      span.checkbox {
        margin-right: 20px;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
      .checked {
        color: $main_color !important;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }

    .menuContainerControls {
      display: flex;
      justify-content: space-between;
      margin: 20px 10px 0;
      button {
        width: 50%;
        min-width: 40%;
        height: 25px;
      }
      button:first-child {
        margin: 0 10px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }

  .mobilePopper {
    width: calc(100% - 40px) !important;
  }

  .tableContainer {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    th {
      white-space: nowrap;
      text-transform: uppercase;

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    td {
      -webkit-text-size-adjust: 100%;
    }

    td:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    .firstRow {
      min-width: 220px;
      width: 220px;
      z-index: 5;

      &.stickyRow {
        position: sticky;
        left: 0;
      }
      & > span {
        text-decoration: underline;
        cursor: pointer;
      }

      & > div {
        white-space: nowrap;
        display: flex;
        justify-content: space-between;

        & > span {
          text-transform: uppercase;
          text-decoration: underline;
          cursor: pointer;
          max-width: calc(220px - 16px - 16px - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > img {
          cursor: pointer;
        }
      }
    }

    .locationCell {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 10px;
      }
    }

    .totalCell {
      div {
        display: flex;
        justify-content: center;
        img {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .emptyResponseTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }

    .sortHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;

      & > svg {
        opacity: 0.2;
        transition: all 0.15s ease-in-out;
      }

      &:hover,
      &.sorted {
        & > svg {
          opacity: 1;
        }
      }
      &.ascend {
        & > svg {
          transform: rotate(180deg);
        }
      }
    }

    .tableIndicator {
      display: inline-block;
      font-size: 14px;
      padding: 2px 9px;
      min-width: 7px;
      border-radius: 17px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.Operational {
        background: $regular_color;
      }
      &.Critical {
        background: $alert_color;
      }
      &.NonCritical {
        background: $warning_color;
      }
      &.BeyondRepair {
        background: #0c0c0c;
        color: #fff;
      }
    }
  }

  .emptyDashboardBlock {
    height: calc(100vh - 340px);

    h3 {
      text-align: center;
      margin: 80px 0;
      word-break: break-word;
    }
  }

  .footerControls {
    padding-top: 35px;
    text-align: right;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 50px 20px 20px;
    .pageHeader {
      border-bottom: none;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 10;
      padding: 10px 20px;
      align-items: center;

      h1 {
        padding-left: 40px;
        line-height: 40px;
        padding-bottom: 0;
      }
    }

    .commonControls {
      &__statuses {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }
    }

    .formCell {
      flex-direction: column;
      width: 100%;

      &.singleStatusCell {
        width: 100%;
      }

      .inputWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;

        & > div:first-child {
          max-width: 100%;
          min-width: 85%;
          width: 100%;
        }
      }
    }

    .pageTopBlock {
      &__summary {
        flex-direction: column;
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      margin-bottom: 30px;

      .firstRow {
        min-width: 140px;
        width: 140px;
        z-index: 5;
        padding: 8px 10px;

        & > div {
          & > img {
            margin-left: 5px;
          }
        }
      }
    }

    .buttonStyle {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      background-color: #f6f6f6;
      color: #000;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      font-family: $monserrat;
      line-height: 15px;
      margin-top: 20px;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);

      & > span {
        column-gap: 5px;
      }
    }

    .mainBlock {
      border-bottom: 1px solid $main_color;
    }

    .mainBlock:last-child {
      border-bottom: none;
    }

    .popper {
      position: fixed !important;
      width: 100vw;
      height: 100%;
      transform: none !important;
      will-change: auto !important;
      box-sizing: border-box;
      overflow-y: auto;
      top: 0 !important;
      left: 0 !important;
      background-color: white;
    }

    .menuContainer {
      margin: 0;
      padding: 70px 20px 20px;
      border-radius: 0 !important;
      width: calc(100% - 40px);
      min-height: calc(100% - 90px);
      flex-direction: column;
      justify-content: space-between;

      .listWrapper {
        max-height: 100%;
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 20px;

        label {
          margin: 2px 0 15px 0;
        }
      }
    }
  }
}
