@import 'assets/styles/variables.scss';

.pictureBlock {
  position: relative;

  height: 262px;
  border-radius: 8px;
  background: #e0e0e0;
  border: 10px solid #c4c4c4;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .galleryPicture {
    width: 325px;
    object-fit: cover;
    cursor: pointer;
  }

  .dummyPicture {
    width: 100px;
    object-fit: scale-down;
  }

  .expandHint {
    position: absolute;
    top: 5px;
    right: 5px;
    background: $main_color;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    padding: 7px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }
  p {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-weight: 700;
    font-size: 14px;
    background: #fff;
    padding: 4px 6px;
    border-radius: 8px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pictureBlock {
    height: 400px;
    left: -20px;
    width: 100vw;
    .galleryPicture {
      width: 100%;
      max-width: 480px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
