div.table {
  grid-area: levels;
  tr.total {
    & > td {
      background: #7aa5ba;
      color: white;
      font-weight: 700;
    }
    &:hover {
      & > td {
        background: #7aa5ba;
      }
    }
  }

  th,
  td {
    height: 40px;
    padding: 4px 12px;
    &:nth-child(1) {
      width: 60%;
      min-width: 200px;
    }
    &:not(:nth-child(1)) {
      width: 20%;
      min-width: 110px;
      text-align: center;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;

    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    td.projectCell {
      font-weight: 700;
    }
  }
}
