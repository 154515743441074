@import 'assets/styles/variables.scss';

.header {
  display: flex;
  align-items: center;
  &__title {
    font-family: $monserrat;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
}
