@import 'assets/styles/variables.scss';

.tableContainer {
  border-radius: 8px;
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint-1180) {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }

  table {
    border-collapse: initial;
  }

  td {
    vertical-align: top;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }
  th {
    padding: 8px 16px;
  }

  .prefixCell {
    width: 25%;
    min-width: 160px;
    background-color: #fff;
    vertical-align: top;
    white-space: normal;
    word-break: break-word;
  }
  .quantityCell {
    width: 20%;
    min-width: 150px;
    background-color: #fff;
    vertical-align: top;
    white-space: normal;
    word-break: break-word;
  }
  .assetCell {
    width: 20%;
    min-width: 200px;
    background-color: #fff;
    vertical-align: top;
    white-space: normal;
    word-break: break-word;
  }
  .locationCell {
    width: 15%;
    min-width: 220px;
    white-space: normal;
    word-break: break-word;
    vertical-align: middle;
  }
  .destinationCell {
    width: 15%;
    min-width: 200px;
    vertical-align: middle;
    & > div {
      width: 100%;
      max-width: 100%;
    }
    &:has(div) {
      padding: 0 5px;
    }
  }
  .sublocationCell {
    width: 15%;
    min-width: 200px;
    vertical-align: middle;
    & > div {
      width: 100%;
      & > div {
        width: 100%;
        background-color: transparent;
      }
    }
    &:has(div) {
      padding: 0 5px;
    }
  }
}
