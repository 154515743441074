@import 'assets/styles/variables.scss';

%cell-sizes {
  &:nth-child(1) {
    width: 15%;
    min-width: 120px;
  }
  &:nth-child(2) {
    width: 10%;
    min-width: 100px;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 125px;
  }
  &:nth-child(4) {
    width: 10%;
    min-width: 140px;
  }
  &:nth-child(5) {
    width: 30%;
    min-width: 300px;
  }
  &:nth-child(6) {
    width: 10%;
    min-width: 170px;
  }
  &:nth-child(7) {
    width: 10%;
    min-width: 210px;
  }
}

div.table {
  border-radius: 8px;
  margin-bottom: 15px;
  table {
    border-collapse: initial;
  }

  th {
    white-space: nowrap;
  }
  th,
  td {
    height: 40px;
    @extend %cell-sizes;
  }
  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:nth-child(4) {
      & > span {
        margin: 0 auto;
      }
    }
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }
  td.empty {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  & > section {
    padding-top: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    width: 100vw;
  }

  .footer {
    padding: 0 20px 0;
  }
}
