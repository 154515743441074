@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerBlock {
    margin: 0 0 50px;
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px;
  }
  .formBlock {
    width: 427px;
    margin-bottom: 40px;
    .nameBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & > label {
        margin: 0;
        font-size: 14px;
      }
    }
    .filesBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
      & > label {
        line-height: 33px;
        font-size: 14px;
      }
    }
    .descriptionBlock {
      display: flex;
      flex-direction: column;
      & > label {
        margin: 0 0 20px;
        font-size: 14px;
      }
    }
  }

  .footerControls {
    margin: 0;
    button:first-child {
      margin: 0 30px 0 0;
    }
    button:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 70px 20px 20px;
    min-height: 100vh;
    height: auto;

    .headerBlock {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px 20px 0;
      background: #fff;
      z-index: 5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: none;
      margin: 0;

      h1 {
        margin: 0 !important;
        padding-left: 40px;
        line-height: 40px;
      }
    }

    .formBlock {
      width: 100%;
      .nameBlock {
        flex-direction: column;
        align-items: flex-start;
        & > label {
          margin-bottom: 14px;
        }
      }
      .filesBlock {
        flex-direction: column;
        align-items: flex-start;
      }
      .descriptionBlock {
        margin-top: 10px;
        label {
          margin: 0 0 14px;
        }
      }
    }
    .footerControls {
      display: flex;
      justify-content: space-between;
      margin: auto 0 0;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
}
