@import 'assets/styles/variables.scss';

.dropzoneWrapper {
  width: 250px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: $field_default_height;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border-radius: 8px;
  background: #f7f7f7;
}

.dropzone_input {
  display: none;
}

.dzu_inputLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  & > svg {
    height: 17px;
    width: 17px;
    margin-right: 6px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dropzoneWrapper {
    width: 100% !important;
  }

  .dzu-dropzone {
    width: 100% !important;
  }

  .dzu-imageWrapper {
    margin-right: 10px;
  }

  .dropzone {
    width: 100%;
  }
}
