@import 'assets/styles/variables.scss';

.search {
  position: relative;
  white-space: nowrap;
  width: 300px;
  min-width: 300px;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__input {
    background: #f7f7f7;
    border-radius: 8px;
    width: 100%;

    fieldset {
      border: none;
      border-radius: 8px;
    }

    input {
      font: {
        family: $monserrat;
        weight: 400;
        size: 12px;
      }
      padding: 8px;
      line-height: 14px;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }

    .clear {
      width: 15px;
      height: 15px;
      margin: 0;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .search {
    width: 100%;
    min-width: calc(100% - 32px - 20px);
  }
}
