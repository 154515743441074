@import 'assets/styles/variables.scss';

.subhead {
  font-weight: bold;
  font-family: $monserrat;
  font-size: 18px;
  margin: 0;
  display: block;
}

.section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 10px;
  .container {
    display: flex;
    column-gap: 100px;
    &__block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 427px;
    }
  }
}

.otherRow {
  width: calc(427px + 110px + 15px);

  .otherField {
    display: flex;
    align-items: center;
    position: relative;

    & > span {
      position: absolute;
      bottom: -16px;
      font-size: 10px;
      font-family: $monserrat;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .section {
    .container {
      width: 100%;
      flex-direction: column;
      row-gap: 20px;
      &__block {
        width: 100%;
      }
    }
  }

  .otherRow {
    width: 100%;
    .otherField {
      width: 100%;
      margin-bottom: 17px;

      & > span {
        bottom: -20px;
      }
    }
  }
}
