@import 'assets/styles/variables.scss';

.wrapper {
  position: relative;
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  div.wrapper__input {
    background: #f7f7f7;
    border-radius: 8px;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    & > div {
      & > div {
        padding: 10px 10px 25px !important;
      }
    }
    &.error {
      border-color: $error_color;
    }
    fieldset {
      border: none;
    }
    textarea {
      font-family: $monserrat;
      font-weight: 400;
      padding: 0;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      color: #000;
    }
  }
  &__counter {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 10px;
    font-weight: 500;
  }
  &__error {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
  }
}

.fullHeight {
  height: 100%;
  & > div {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
}
