@import 'assets/styles/variables.scss';

.filterWrapper {
  display: flex;
  margin-bottom: 15px;

  span.filterButton {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background-color: #f6f6f6;
    color: black;

    &__opened,
    &__opened:hover {
      background-color: $main_color;
      color: white;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;
  padding: 35px 40px 20px;
  width: 850px;
  box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;

  .formBlock {
    display: flex;
    justify-content: space-between;
    .formRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      flex-basis: 47%;

      label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
      .inputWrapper {
        position: relative;
      }
    }
  }

  .textInput {
    background: #f7f7f7;
    border-radius: 8px;
    width: 250px;
    margin: 0;
    border: 1px solid transparent;

    &.error {
      border-color: $error_color;
    }

    fieldset {
      border: none;
    }
    input {
      font-family: $monserrat;
      font-weight: 400;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    margin-top: 35px;
    justify-content: flex-end;

    button {
      width: 196px;
      min-width: 196px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .filterWrapper {
    span.filterButton {
      width: 100% !important;
      height: 40px !important;
      background-color: $extra_color !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: #000000;
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);

      &__opened,
      &__opened:hover {
        background-color: $extra_color;
        color: #000000 !important;
      }
    }
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: auto;
  }

  .filterContainer {
    display: flex;
    position: fixed;
    width: 100%;
    margin-left: 0;
    border-radius: 0 !important;
    transform: none !important;
    height: 100%;
    padding: 70px 20px 20px;
    overflow: auto;
    overscroll-behavior: none;

    .formBlock {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .formRow {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        label {
          margin: 0 0 10px 0;
        }
        .inputWrapper {
          width: 100%;
        }
      }
    }

    .textInput {
      width: 100%;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 0;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }

  .headerWrapper {
    position: fixed;
    padding-top: 10px;
    top: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: calc(100vw - 40px);

    h1 {
      font-family: $monserrat;
      padding-left: 40px;
      line-height: 38px;
      margin: 0;
    }
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      display: flex;
      border: none;
      background: none;
      cursor: pointer;

      & > svg,
      & > img {
        margin-right: -14px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
