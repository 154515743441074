@import 'assets/styles/variables.scss';

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 40px;

  &__row {
    font-family: $monserrat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    width: 100%;
    min-height: 25px;

    & > svg {
      width: 20px;
      height: 20px;
      cursor: grab;
    }
  }

  .field_row {
    width: calc(100% - 20px - 15px);
  }
}

.helper {
  z-index: 1305;
}
