@import 'assets/styles/variables.scss';

.header {
  background: #fff;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 60px;
  font-family: $monserrat;
  font-size: 24px;
  font-weight: 700;
  box-shadow: 0 3px 4px #0000001a;
}
