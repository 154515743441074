@import 'assets/styles/variables.scss';

.wrapper {
  width: 100%;
  position: relative;
  .inputWrapper {
    box-sizing: border-box;
    background: #f7f7f7;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid transparent;
    width: 100%;
    padding: 0 30px 0 0;

    & input {
      font-family: $monserrat;
      font-size: 14px;
      height: 30px;
      box-sizing: border-box;
      padding: 8px 16px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      border-radius: 8px;
      background: #f7f7f7;
    }
    &.error {
      border-color: $error_color;
    }
    &.extended {
      padding: 10px 30px 10px 16px;
    }

    .controls {
      position: absolute;
      right: 5px;
      min-height: 30px;
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      &.stretched {
        height: calc(100% - 20px);
      }
      &__clear {
        svg {
          color: black;
        }
      }
    }
  }
}

.list {
  width: 250px;
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    font-family: $monserrat;
    padding: 5px 12px;
    display: flex;
    cursor: pointer;
    &.selected {
      background-color: #fafafa;
      font-weight: 600;

      & svg {
        color: #1890ff;
      }
    }

    &:hover {
      background-color: #fafafa;
      font-weight: 600;
    }

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }
}
