.previewWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    display: block;
    font-size: 14px;
    margin-left: 5px;
  }

  div.spinner {
    width: 14px !important;
    height: 14px !important;
    svg {
      width: 100%;
      height: 100%;
      color: black;
    }
  }
}
