@import 'assets/styles/variables.scss';

.layoutWrapper {
  position: relative;
  width: 250px;

  .inputLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding: 0 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    svg {
      height: 17px;
      width: 17px;
      margin-right: 6px;
    }
  }

  .deprecatedInput {
    cursor: not-allowed;
    opacity: 0.5;

    & ~ p {
      margin: 0;
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 5px;
      bottom: -14px;
      color: $error_color;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin: 10px 0 0;
  &__video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 200px;
      overflow: hidden;
      display: block;
      font-size: 14px;
      margin-left: 10px;
    }
    &_delete {
      width: 20px;
      height: 20px;
      svg {
        color: black;
        width: 100%;
        height: 100%;
      }
    }
  }
}

div.previewWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    display: block;
    font-size: 14px;
    margin-left: 10px;
  }

  .previewImageLoading {
    opacity: 0.5;
  }

  div.previewImageSpinner {
    width: 17px !important;
    height: 17px !important;
    margin-right: 1px;

    svg {
      width: 100%;
      height: 100%;
      color: #000;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .layoutWrapper {
    width: 100%;
  }
}
