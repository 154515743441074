@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding-bottom: 10px;
    margin: 0 0 50px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 25px;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
    }

    &__controls {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: black;
      }
    }
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px;
  }

  .formWrapper {
    margin-bottom: 50px;

    .formRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;

      label {
        margin: 0;
        font-size: 14px;
      }

      .inputWrapper {
        position: relative;

        & > span {
          position: absolute;
          font-weight: 400;
          font-size: 10px;
          left: 0;
          bottom: -18px;
          color: $error_color;
        }
      }
    }
  }

  .textInput,
  .multiTextInput {
    background: #f7f7f7;
    border-radius: 8px;
    width: 250px;
    margin: 0;
    border: 1px solid transparent;

    & > div {
      padding: 10px 10px 25px !important;
    }

    &.error {
      border-color: $error_color;
    }

    fieldset {
      border: none;
    }
    input,
    textarea {
      font-family: $monserrat;
      font-weight: 400;
      padding: 0;
      font-size: 14px;
      line-height: 14px;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
    input {
      padding: 8px 16px;
    }
  }

  label {
    margin-bottom: 20px;
  }

  .multiTextInput {
    width: 427px;

    & > div {
      color: #000;
    }
  }

  .margedHeader {
    margin-top: 50px;
  }

  .expandedRow {
    cursor: default !important;

    button {
      margin-left: auto;
    }
  }

  .accordionButton {
    background: $main_color;
    color: #fff;
    font-family: $monserrat;
    font-weight: 400;
    text-transform: lowercase;
    text-transform: capitalize;
    min-height: 25px;
    padding: 0;
    border-radius: 8px;
    width: 106px;

    &:hover {
      background: $main_color;
    }
  }

  .rowLink {
    font-weight: 500;
    font-size: 9px;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }

  .table {
    border-collapse: initial;
    overflow-x: initial;

    table {
      border-collapse: initial;
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:nth-child(1) {
        width: 10%;
        min-width: 110px;
      }
      &:nth-child(2) {
        width: 80%;
        min-width: 300px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 110px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding-top: 10px;
      top: 0;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      z-index: 1;

      button {
        background: #f6f6f6;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
      }

      &__controls {
        position: fixed;
        top: 20px;
        right: 20px;

        svg {
          margin-right: 0;
        }
      }
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
      padding: 20px 0 30px;
      line-height: 40px;
      word-break: break-word;
    }

    .formWrapper {
      margin-bottom: 0;
    }

    .multiTextInput {
      width: calc(100vw - 40px);
    }

    .accordionTitle {
      min-width: auto;
      max-width: calc(100vw - 210px);
      font-size: 14px;
      white-space: normal;
      padding: 5px 10px 5px 0;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
    }

    .accordionButton {
      max-height: 35px;
    }

    .table {
      td {
        &:nth-child(1) {
          min-width: 85px;
        }
        &:nth-child(2) {
          min-width: auto;
          padding: 8px 4px;
        }
        &:nth-child(3) {
          min-width: 100px;
        }
      }
    }
  }

  textarea {
    width: calc(100vw - 60px) !important;
  }
}
