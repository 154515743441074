@import 'assets/styles/variables.scss';

.popup {
  width: 550px;
  padding: 25px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
    margin-bottom: 50px;
    &__row,
    &__upload {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
    }

    &__row {
      align-items: center;
    }

    &__upload {
      align-items: flex-start;
      & > label {
        line-height: 32px;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__main,
    &__extra {
      display: flex;
      width: 350px;
      button {
        width: 160px;
        min-width: 160px;
        &:first-child {
          margin: 0 30px 0 0 !important;
        }
        &:last-child {
          margin: 0 !important;
        }
      }
    }

    &__main {
      justify-content: space-between;
    }
    &__extra {
      justify-content: flex-end;
      margin: 20px 0 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    padding: 70px 20px 20px;
    min-height: 100%;
    overflow: auto;

    .form {
      row-gap: 20px;
      &__row,
      &__upload {
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 10px;
      }

      &__row {
        align-items: flex-start;
      }

      &__upload {
        & > label {
          line-height: normal;
        }
      }
    }

    .footer {
      margin-top: auto;
      &__main,
      &__extra {
        width: 100%;
        button {
          width: calc(50% - 15px);
          min-width: calc(40% - 15px);
          &:first-child {
            margin: 0 !important;
          }
          &:last-child {
            margin: 0 !important;
          }
        }
      }

      &__main {
        justify-content: space-between;
      }
      &__extra {
        justify-content: flex-end;
        margin: 20px 0 0;
      }
    }
  }
}
