@import 'assets/styles/variables.scss';

.picker {
  position: relative;
  font-family: $monserrat;
  .root {
    width: 100%;
    height: $field_default_height;
    background: #f7f7f7;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 8px 4px 8px 14px;
    box-sizing: border-box;
    outline: none;
    font: {
      family: $monserrat;
      size: 14px;
      weight: 400;
    }
    cursor: default;
    color: #000;
    line-height: 14px;
    pointer-events: none;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
}

.calendar {
  position: relative;

  .controls {
    button {
      font-family: $monserrat;
      font-weight: 500;
      font-size: 14px;
      color: $main_color;
      display: flex;
      align-items: center;
      margin: 5px 0 5px;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }

  table {
    td,
    th {
      font-size: 12px;
    }
  }
}

svg.eventIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  right: 5px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

input.invalid {
  border-color: #e92d2d !important;
}

.popover {
  & > div[class='rdtPicker'] {
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
      rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
  }
}

.top {
  & > div[class='rdtPicker'] {
    bottom: 100%;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popover {
    & > div[class='rdtPicker'] {
      right: 50%;
      transform: translateX(50%);
    }
  }
}
