@import 'assets/styles/variables.scss';

.content {
  display: grid;
  grid-template-columns: 1fr 345px;
  grid-template-rows: auto auto;
  column-gap: 50px;
  row-gap: 40px;

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    grid-column: 1;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .content {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    &__info {
      grid-column: 1;
      grid-row: 2;
    }
  }
}
