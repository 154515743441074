@import 'assets/styles/variables.scss';

.dialog {
  width: 930px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-family: $monserrat;
  box-sizing: border-box;

  .form {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    row-gap: 20px;
    &__block {
      display: flex;
      flex-direction: column;
      flex-basis: 47%;
      row-gap: 20px;
      &_cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 33.6px;
      }
    }
  }
}
