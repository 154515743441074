@import 'assets/styles/variables.scss';

.tag {
  width: 35px;
  height: 20px;
  background-color: rgb(217, 217, 217);
  border-radius: 8px;
  font-size: 12px;
  font-family: $monserrat;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: default;
}
