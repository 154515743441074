@import 'assets/styles/variables.scss';

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupContainer {
  width: 520px;
  padding: 40px 80px;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.popupTitle h2,
.popupText p {
  font-family: $monserrat;
}

.popupTitle {
  padding: 0 !important;

  .headerTitle {
    margin-bottom: 30px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      max-width: 340px;
      line-height: 34px;
      text-align: left;
      margin: 0;
    }
  }
}

.popupText {
  padding: 0 !important;
  overflow: visible !important;
  p {
    color: #000;
    font-weight: 400;
    margin: 0;
    line-height: 40px;
  }
}

.popupControls {
  margin: 40px -10px 0;
  padding: 0 !important;
}

.formColumn,
.formCell {
  display: flex;
}

.formColumn {
  flex-direction: column;
}

.formControl {
  width: 100%;
}

.formCell {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;

  label {
    width: calc(100% - 90px);
    position: relative;
    margin: 0 !important;
    span {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .inputWrapper {
    position: relative;
    display: flex;

    & > span {
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 0;
      bottom: -14px;
      color: $error_color;
    }
  }

  input {
    width: 0;
    height: auto;
  }
}

.alignTop {
  align-items: flex-start;
  margin-bottom: 0;
  label {
    line-height: 32px;
  }
}

.checkbox {
  margin: 0 10px 0 0 !important;
  color: $main_color !important;
  position: static !important;
  width: 26px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
    width: 26px;
  }
  & span:last-child {
    display: none;
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.sortControls {
  white-space: nowrap;
  .upText,
  .downText {
    font-family: $monserrat;
    margin-left: 22px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
  }
  .downText {
    margin-right: 15px;
  }

  .upButton,
  .downButton {
    cursor: pointer;
    opacity: 0.8;

    svg {
      font-size: 30px;
    }
  }

  .upButton {
    svg {
      transform: rotate(180deg);
    }
  }
  .downButton {
    margin-right: 5px;
    margin-left: 25px;
  }

  .disabled {
    cursor: default;
    color: #ccc;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popupContainer {
    position: fixed !important;
    margin: 0 !important;
    width: 100% !important;
    padding: 80px 20px 20px;
    height: 100% !important;
    box-sizing: border-box;
    border-radius: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .popupTitle {
    position: fixed;
    padding: 0 20px 0 !important;
    top: 0;
    left: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    & > h2 {
      width: 100%;
    }

    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h2 {
        padding-left: 40px;
        line-height: 60px;
        margin: 0;
      }
    }
  }

  .popupControls {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 0 0;
    button {
      margin: 0 !important;
      width: 50% !important;
      min-width: 40% !important;
    }
    button:first-child {
      margin-right: 30px !important;
    }
  }

  .formCell {
    margin-bottom: 30px;
    label {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
