@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

tr.ro_table_row {
  &:hover {
    & > td:last-child {
      .controls {
        display: flex;
      }
    }
  }

  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.idCell {
    width: 10%;
    min-width: 150px;
  }
  td.statusCell {
    width: 15%;
    min-width: 150px;
  }
  td.userCell {
    width: 15%;
    min-width: 180px;
  }
  td.locationCell {
    width: 15%;
    min-width: 200px;
  }
  td.entitiesCell {
    width: 15%;
    min-width: 100px;
  }
  td.createdByCell {
    width: 15%;
    min-width: 220px;
  }
  td.createdAtCell {
    width: 15%;
    min-width: 205px;
  }

  $button_width: 20px;
  $gap: 10px;
  $cell_width: calc(#{$button_width} + (#{$gap} * 2));

  td.controlsCell {
    width: $cell_width;
    min-width: $cell_width;
    padding: 0;

    .controls {
      display: none;
      text-align: center;
      justify-content: center;
      .icon_button {
        svg {
          color: black;
          width: $button_width;
          height: $button_width;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  tr.ro_table_row {
    td.statusCell {
      min-width: 220px;
    }
    td.userCell {
      min-width: 220px;
    }
    td.locationCell {
      min-width: 200px;
    }
    td.entitiesCell {
      min-width: 180px;
    }
    td.createdByCell {
      min-width: 220px;
    }
    td.createdAtCell {
      min-width: 200px;
    }

    td.controlsCell {
      .controls {
        display: flex;
      }
    }
  }
}
