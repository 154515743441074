@import 'assets/styles/variables.scss';

.assets {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .table {
    border-radius: 8px;

    @media screen and (max-width: $breakpoint-1180) {
      position: relative;
      left: -20px;
      width: 100vw !important;
    }

    table {
      border-collapse: initial;
    }

    th {
      padding: 8 16px;
      height: 40px;
    }
    td {
      vertical-align: top;
      white-space: normal;
      word-break: break-all;
      max-width: 0;
      height: 40px;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.prefixCell,
      &.qtyCell,
      &.assetCell {
        width: 20%;
        min-width: 200px;
      }
      &.prefixCell,
      &.qtyCell {
        background-color: white !important;
      }

      &.assetCell {
        padding: 3px 0;
        vertical-align: top;

        &.invalid {
          background-color: #ff000012 !important;
        }

        .asset_search {
          & > div {
            width: 100%;
            max-width: 100%;
            & > div {
              width: 100%;
              background-color: transparent;
            }
          }
        }
      }

      &.originCell {
        width: 40%;
        min-width: 250px;
        vertical-align: middle;
      }
    }
  }
}
