@import 'assets/styles/variables.scss';

.tableContainer {
  border-radius: 8px;
  margin-bottom: 50px;

  @media screen and (max-width: $breakpoint-1180) {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }

  table {
    border-collapse: initial;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    height: 40px;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }
  th {
    padding: 8px 16px;
    &.invalid {
      background-color: #ff000012 !important;
    }
  }

  .prefixCell {
    min-width: 250px;
    width: 30%;
    vertical-align: top;
  }
  .qtyCell {
    vertical-align: top;
    min-width: 150px;
    width: 20%;
  }
  .assetsCell {
    padding: 0 5px;
    min-width: 250px;
    width: 15%;
  }
  .originCell {
    min-width: 230px;
    width: 25%;
  }
}

.inputs__select {
  & > div {
    width: 100%;
    max-width: 100%;
    & > div {
      width: 100%;
      background-color: transparent;
    }
  }
}
