@import 'assets/styles/variables.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 40px;

  &.fixed {
    position: fixed;
    padding: 5px 10px;
    border-radius: 8px;
    bottom: 10px;
    right: 25px;
    background: #fff;
    box-shadow: $shadow;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pagination {
    flex-wrap: wrap;
    align-items: flex-end;
    row-gap: 20px;
    position: relative;
    right: -24px;
  }
}
