@import 'assets/styles/variables.scss';

.container {
  font-family: $monserrat;

  .titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;

    & > h1 {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .helpers {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }

    .switchers {
      display: flex;
    }
  }

  .calendarSection {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 50px;
    position: relative;

    table {
      border-collapse: initial;
      border: none;
    }
    td[role='presentation'],
    th[role='presentation'] {
      border: none;
    }

    & > span {
      position: absolute;
      right: 50px;
      top: 14px;
    }

    & > div {
      & > div {
        margin-bottom: 10px !important;
        h2 {
          font-size: 20px !important;
        }
      }
    }

    .currentMonthDayCell,
    .otherMonthDayCell {
      font-size: 18px;
      font-weight: 500;
    }

    .currentMonthDayCell {
      background-color: #fff;
    }

    .otherMonthDayCell {
      background-color: #f6f6f6;
    }

    .dayHeader {
      font-size: 18px;
      font-weight: 500;
      color: #000;
      line-height: 22px;
      background-color: #f6f6f6;
      padding: 5px 0;
      text-transform: uppercase;
    }

    .described {
      &:after {
        content: ' ';
        border-top: solid 8px #ffd83b;
        border-left: solid 8px transparent;
        border-top-right-radius: 3px;
        border-right: solid 8px #ffd83b;
        border-bottom: solid 8px transparent;
        position: absolute;
        top: -1px;
        right: -1px;
        left: auto;
        bottom: auto;
      }
    }

    .event_block {
      height: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 10px 0 -10px;
      position: relative;
      box-shadow: none;
      overflow: hidden;
      background: none;
      outline: none;
      & > div {
        overflow: hidden;
        & > div {
          & > div {
            & > div {
              text-overflow: ellipsis;
            }
          }
        }
      }
      &:focus:after {
        background: transparent !important;
      }
    }
  }
}

button.dotsButton {
  border-radius: 50%;
  background-color: #aaa;
  width: 20px;
  min-width: 20px;
  height: 20px;
  color: white;
  padding: 0;
  &:hover {
    background-color: #aaa;
  }
}

.paper {
  margin-top: 10px;
  padding: 15px 0;
  border-radius: 8px !important;
  width: 300px;

  ul {
    padding: 0;
    li {
      font-family: $monserrat;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      min-height: 40px;
      padding: 6px 10px;

      svg,
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: scale-down;
        margin-left: 3px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    flex-direction: column !important;
    margin-bottom: 10px !important;
    .switchers {
      justify-content: space-between;
      button {
        padding: 8px 5px;
        margin: 0;
      }
      .monthTitle {
        font-weight: 600;
        font-size: 20px;
        font-family: $monserrat;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .calendarSection {
    border: none !important;
    padding: 0 !important;
    position: relative;
    left: -20px;
    width: 100vw;
    margin-bottom: 15px;

    & > div {
      & > div {
        margin-bottom: 0 !important;
      }
    }

    .currentMonthDayCell,
    .otherMonthDayCell {
      font-size: 12px !important;
    }

    .dayHeader {
      font-size: 14px !important;
      line-height: 22px;
      padding: 0 !important;
    }

    .event_block {
      height: 20px !important;
      overflow-x: hidden;
    }
  }
}
