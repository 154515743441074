@import 'assets/styles/variables.scss';

div.table {
  border-radius: 8px;

  & > table {
    border-collapse: initial;
  }

  th,
  td {
    &.invalid {
      background-color: #ff000012 !important;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &[rowSpan] {
      background-color: white;
      vertical-align: baseline;
    }
    &:has(div) {
      padding: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &:nth-child(1) {
      min-width: 230px;
      width: 30%;
    }
    &:nth-child(2) {
      min-width: 130px;
      width: 10%;
    }
    &:nth-child(3) {
      min-width: 200px;
      width: 20%;
    }
    &:nth-child(4) {
      min-width: 180px;
      width: 20%;
    }
    &:nth-child(5) {
      min-width: 100px;
      width: 10%;
    }
    &:nth-child(6) {
      min-width: 110px;
      width: 10%;
    }
  }
}

.cell_input {
  width: 100%;
  & > div {
    background-color: transparent !important;
  }
}

.error {
  & > span {
    white-space: normal;
    font-size: 9px;
    font-family: $monserrat;
    color: #ff0000;
    display: block;
    padding: 0 16px 8px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    position: relative;
    left: -20px;
    width: 100vw;
  }
}
