@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

tr.ticket_root_cause_table_row {
  &:hover {
    & > td:last-child {
      .controls {
        display: flex;
      }
    }
  }

  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.descriptionCell,
  td.prefixesCell {
    white-space: normal;
    word-break: break-all;
  }

  td.nameCell {
    width: 20%;
    min-width: 250px;
    vertical-align: top;
  }

  td.descriptionCell {
    width: 35%;
    min-width: 250px;
    vertical-align: top;
  }

  td.activeCell {
    width: 10%;
    min-width: 150px;
    vertical-align: top;
  }

  td.prefixesCell {
    width: 35%;
    min-width: 250px;
    vertical-align: top;
  }

  $button_width: 20px;
  $gap: 10px;
  $cell_width: calc(#{$button_width} + (#{$gap} * 2));

  td.controlsCell {
    width: $cell_width;
    min-width: $cell_width;
    padding: 8px 0;

    .controls {
      display: none;
      text-align: center;
      justify-content: center;
      .icon_button {
        svg {
          color: black;
          width: $button_width;
          height: $button_width;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  tr.ticket_root_cause_table_row {
    td.controlsCell {
      .controls {
        display: flex;
      }
    }
  }
}
