@import 'assets/styles/variables.scss';

.tableControls {
  margin-bottom: 10px;

  button.note_editor {
    width: 34px;
    height: 34px;
    background: #f6f6f6;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #ededed;
    }

    svg {
      color: black;
    }
  }

  .statusField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 427px;
    .status_button {
      width: 150px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      font-family: $monserrat;
      background: $extra_color;
      color: black;
    }
  }
}

.tableContainer {
  border-radius: 8px;
  margin-bottom: 15px;
  table {
    border-collapse: initial;
  }
  th {
    border-right: 1px solid #dcdcdc;
  }
  td {
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 8px;
  }
  td:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }
}

.hintLabel {
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
  margin-bottom: 15px;
  display: block;
  opacity: 0.5;
}

@media screen and (max-width: $breakpoint-1180) {
  .tableControls {
    button.note_editor {
      position: relative;
      left: -20px;
    }

    .statusField {
      width: 100%;
      & > div {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
      .status_button {
        margin-left: 20px;
        width: 30px;
        min-width: 30px;
        max-width: 30px;
      }
    }
  }

  .tableContainer {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }
}
