@import 'assets/styles/variables.scss';

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupContainer {
  width: 520px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.statusIndicator {
  position: relative;
  display: block;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  margin: 0 auto;
  margin-right: 15px;

  &.Operational {
    background: $regular_color;
    box-shadow: 0px 1px 4px rgba(0, 147, 0, 0.25);
  }
  &.Critical {
    background: $alert_color;
    box-shadow: 0px 1px 4px rgba(233, 45, 45, 0.25);
  }
  &.NonCritical {
    background: $warning_color;
    box-shadow: 0px 1px 4px rgba(236, 191, 34, 0.25);
  }
  &.BeyondRepair {
    background: #0c0c0c;
    box-shadow: 0px 1px 4px rgba(181, 152, 218, 0.25);
  }

  & svg {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 100%;
  }
}

.summaryCounters {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .blockRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
}

.popupTitle h2,
.popupText p {
  font-family: $monserrat;
}

.popupTitle {
  padding: 0 !important;
  text-transform: uppercase;
  h2 {
    font-weight: 700;
    font-size: 24px;
    max-width: 440px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 30px;
  }
}

.popupText {
  padding: 0 !important;
  overflow: visible !important;
  p {
    color: #000;
    font-weight: 400;
    margin: 0;
  }

  .tableContainer {
    border-radius: 8px;
    max-height: 165px;
    table {
      border-collapse: initial;
    }
    td {
      -webkit-text-size-adjust: 100%;
    }
    td:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    .firstCell,
    .secondCell {
      box-sizing: border-box;
      width: 50%;
      text-align: center;
      height: 40px;
      white-space: normal;
      word-break: break-word;
      padding: 8px 12px;
    }
  }
}

.popupControls {
  margin: 40px -10px 0;
  padding: 0 !important;
  justify-content: center !important;
}

.formColumn,
.formCell {
  display: flex;
}

.formColumn {
  flex-direction: column;
}

.formCell {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  label {
    font-family: $monserrat;
    margin: 0;
    font-size: 14px;
    line-height: 32px;
  }

  .inputWrapper {
    position: relative;
    display: flex;

    & > span {
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 0;
      bottom: -14px;
      color: $error_color;
    }
  }
}

.alignTop {
  align-items: flex-start;
  margin-bottom: 0;
  label {
    line-height: 32px;
  }
}

.checkbox {
  margin: 3px 225px 3px 0 !important;
  color: $main_color !important;
  position: static !important;
  width: 26px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
    width: 26px;
  }
  & span:last-child {
    display: none;
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popupContainer {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 !important;
    width: 100% !important;
    padding: 60px 20px 20px;
    height: auto !important;
    box-sizing: border-box;
    border-radius: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .popupTitle {
    position: fixed;
    padding-top: 10px;
    top: 0;
    left: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    h2 {
      padding-left: 60px;
      padding-top: 11px;
      line-height: 38px;
      margin: 0;
      max-width: 100% !important;
    }
  }

  .titleBlock {
    font-family: $monserrat;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .popupControls {
    & > button {
      width: 100% !important;
    }
  }

  .tableContainer {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }
}
