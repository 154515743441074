@import 'assets/styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $main_color;
  padding-bottom: 10px;
  margin: 0 0 20px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  &__controls {
    display: flex;
    align-items: center;

    button {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }

    svg,
    img {
      margin-right: 10px;
      color: black;
    }
  }
}

.edit_button,
.remove_button,
.download_button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  height: 30px;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.details {
  display: flex;
  &__block {
    width: 450px;
    &:first-child {
      margin-right: 100px;
    }
    &_item {
      display: flex;
      align-items: baseline;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > label {
        margin: 0 5px 0 0;
        white-space: nowrap;
        font: {
          family: $monserrat;
          size: 14px;
          weight: 700;
        }
      }
      & > span {
        position: relative;
        font-size: 14px;
        word-break: break-word;
      }
      .signature {
        padding: 0;
        border-radius: 8px;
        background: $main_color;
        width: 135px;
        height: 30px;
        box-shadow: $shadow;
        &:hover {
          box-shadow: $shadow_hover;
          background: $main_color;
        }
        span {
          font: {
            family: $monserrat;
            size: 12px;
          }
          color: #fff;
          text-transform: uppercase;
        }
      }
      .image {
        width: 135px;
        height: 135px;
        border: 0.5px solid #8a8a8a;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        position: relative;
        .cross {
          position: absolute;
          top: -8px;
          right: -7px;
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 50%;
          &:hover {
            background-color: #f6f6f6;
          }
          svg {
            width: 100%;
            height: 100%;
            color: black;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
      &.sign_vertical {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
      }
    }
  }
  &__record {
    width: 100%;
    &_item {
      display: flex;
      flex-direction: column;
      & > label {
        margin: 0;
        font: {
          family: $monserrat;
          size: 14px;
          weight: 700;
        }
      }
      & > span {
        position: relative;
        font-size: 14px;
        word-break: break-all;
        white-space: pre-line;
      }
    }
  }
  &__label {
    font: {
      family: $monserrat;
      weight: 700;
      size: 14px;
    }
    margin: 0 0 30px;
    line-height: 17px;
  }
}

.vertical {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

$item-cell-width: 15%;
$qty-cell-width: 90px;
%table-cell-sizes {
  &:nth-child(1) {
    width: $item-cell-width;
    min-width: 200px;
  }
  &:nth-child(2) {
    width: calc(100% - #{$item-cell-width} - (#{$qty-cell-width} * 2));
    min-width: 500px;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: $qty-cell-width;
    min-width: $qty-cell-width;
    text-align: center;
  }
}
.deliveryItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  div.table {
    border-radius: 8px;
    margin-bottom: 10px;
    table {
      border-collapse: initial;
    }
    th {
      padding: 0 12px;
      line-height: 17px;
      height: 40px;
      @extend %table-cell-sizes;
    }
    td {
      height: 40px;
      -webkit-text-size-adjust: 100%;
      padding: 8px 12px;
      word-break: break-word;
      white-space: pre-line;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      @extend %table-cell-sizes;
      &:last-child {
        & > div {
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  & > span {
    font: {
      family: $monserrat;
      size: 14px;
      weight: 700;
    }
    padding-right: 20px;
    box-sizing: border-box;
    white-space: nowrap;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    border-bottom: none;
    position: fixed;
    height: 60px;
    padding: 10px 20px 0;
    top: 0;
    left: 0;
    background: #fff;
    width: 100vw;
    box-sizing: border-box;
    z-index: 1;
    margin: 0;

    h1 {
      font-size: 24px;
      line-height: 29px;
      margin: 15px 0 20px;
    }

    &__controls {
      position: fixed;
      right: 15px;
      top: 15px;

      svg {
        margin-right: 0;
      }
    }
  }

  .edit_button,
  .remove_button,
  .download_button {
    padding: 0;
    width: 30px;
    background: #f6f6f6;
    margin: 0;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    & > span {
      display: none;
    }
    img {
      margin-right: 0;
    }
  }

  .details {
    flex-direction: column;
    width: 100%;
    &__block {
      width: 100%;
      &:first-child {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-top: 20px;
      }
      &_item {
        align-items: flex-start;
      }
    }
  }

  .vertical {
    width: 100%;
  }
}
