@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.title {
  font-family: $monserrat;
  font-size: 20px;
  color: black;
  margin: 0;
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .link {
    font-family: $monserrat;
    font-size: 14px;
    text-decoration: underline;
    color: $main_color;
  }
}

div.table {
  border-radius: 8px;

  table {
    border-collapse: initial;
  }

  th {
    white-space: nowrap;
  }

  th,
  td {
    height: 40px;
  }

  .clickable_id {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    .cell_controls {
      display: flex;
      column-gap: 10px;
      text-align: center;
      justify-content: center;
      .icon_button {
        img,
        svg {
          color: black;
        }
        img {
          width: 20px;
          height: 20px;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
      .editingSpan {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &.assets {
    td {
      &:nth-child(1) {
        width: 10%;
        min-width: 125px;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 150px;
      }
      &:nth-child(3) {
        width: 15%;
        min-width: 150px;
      }
      &:nth-child(4) {
        width: 60px;
        min-width: 60px;
      }
      &:nth-child(5) {
        width: 15%;
        min-width: 150px;
      }
      &:nth-child(7) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(8) {
        width: 40%;
        min-width: 300px;
      }
    }

    .opBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        right: -5px;
        top: -13px;
      }
    }
  }

  &.serviceTickets {
    td {
      padding: 8px 10px;
      &:nth-child(1) {
        width: 5%;
        min-width: 65px;
      }
      &:nth-child(2) {
        width: 10%;
        min-width: 105px;
      }
      &:nth-child(3) {
        width: 8%;
        min-width: 80px;
      }
      &:nth-child(4) {
        width: 10%;
        min-width: 132px;
      }
      &:nth-child(5) {
        width: 12%;
        min-width: 131px;
      }
      &:nth-child(6) {
        width: 5%;
        min-width: 80px;

        &:has(span) {
          text-align: center;
        }
      }
      &:nth-child(7) {
        width: 16%;
        min-width: 125px;
      }
      &:nth-child(8) {
        width: 11%;
        min-width: 128px;
      }
      &:nth-child(9) {
        width: 12%;
        min-width: 190px;
      }
      &:nth-child(10) {
        width: 11%;
        min-width: 190px;
      }
    }
  }

  &.assetReports {
    td {
      &:nth-child(1) {
        width: 5%;
        min-width: 90px;
      }
      &:nth-child(2) {
        width: 12%;
        min-width: 190px;
      }
      &:nth-child(3) {
        width: 20%;
        min-width: 180px;
      }
      &:nth-child(4) {
        width: 10%;
        min-width: 130px;
      }
      &:nth-child(5) {
        width: 11%;
        min-width: 180px;
      }
      &:nth-child(6) {
        width: 10%;
        min-width: 180px;
      }
      &:nth-child(7) {
        width: 22%;
        min-width: 180px;
        white-space: normal;
        word-break: break-word;
      }
      &:nth-child(8) {
        width: 10%;
        min-width: 150px;
      }
    }
  }

  &.picklists {
    td {
      &:nth-child(1) {
        width: 16%;
        min-width: 150px;
      }
      &:nth-child(2) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(4) {
        width: 7%;
        min-width: 180px;
      }
      &:nth-child(5) {
        width: 13%;
        min-width: 120px;
      }
      &:nth-child(6) {
        width: 12%;
        min-width: 150px;
      }
      &:nth-child(7) {
        width: 16%;
        min-width: 130px;
      }
      &:nth-child(8) {
        width: 16%;
        min-width: 160px;
      }
    }
  }

  &.leavelists {
    td {
      &:nth-child(1) {
        width: 16%;
        min-width: 150px;
      }
      &:nth-child(2) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(4) {
        width: 7%;
        min-width: 180px;
      }
      &:nth-child(5) {
        width: 13%;
        min-width: 120px;
      }
      &:nth-child(6) {
        width: 12%;
        min-width: 150px;
      }
      &:nth-child(7) {
        width: 16%;
        min-width: 130px;
      }
      &:nth-child(8) {
        width: 16%;
        min-width: 160px;
      }
    }
  }

  &.billOfLadings {
    td {
      &:nth-child(1) {
        width: 5%;
        min-width: 110px;
      }
      &:nth-child(2) {
        width: 10%;
        min-width: 140px;
      }
      &:nth-child(3) {
        width: 15%;
        min-width: 240px;
      }
      &:nth-child(4) {
        width: 15%;
        min-width: 175px;
      }
      &:nth-child(5) {
        width: 15%;
        min-width: 140px;
      }
      &:nth-child(6) {
        width: 10%;
        min-width: 140px;
      }
      &:nth-child(7) {
        width: 15%;
        min-width: 140px;
      }
      &:nth-child(8) {
        width: 15%;
        min-width: 190px;
      }
    }
  }

  &.locations {
    td {
      &:nth-child(1) {
        width: 15%;
        min-width: 200px;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 200px;
        white-space: normal;
        word-break: break-word;
      }
      &:nth-child(4) {
        width: 30%;
        min-width: 400px;
      }
      &:nth-child(5) {
        width: 10%;
        min-width: 160px;
      }
    }
  }

  &.looseItems {
    td {
      &:nth-child(1) {
        width: 30%;
        min-width: 350px;
      }
      &:nth-child(2) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(4) {
        width: 10%;
        min-width: 170px;
      }
      &:nth-child(5) {
        width: 10%;
        min-width: 160px;
      }
      &:nth-child(6) {
        width: 10%;
        min-width: 160px;
        white-space: normal;
        word-break: break-word;
      }
      &:nth-child(7) {
        width: 50px;
        min-width: 50px;
        padding: 0;
        text-align: center;
      }
    }
  }

  &.locationReports {
    td {
      &:nth-child(1) {
        width: 5%;
        min-width: 90px;
      }
      &:nth-child(2) {
        width: 22%;
        min-width: 160px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 130px;
      }
      &:nth-child(4) {
        width: 10%;
        min-width: 150px;
      }
      &:nth-child(5) {
        width: 10%;
        min-width: 120px;
      }
      &:nth-child(6) {
        width: 11%;
        min-width: 180px;
      }
      &:nth-child(7) {
        width: 10%;
        min-width: 155px;
      }
      &:nth-child(8) {
        width: 22%;
        min-width: 180px;
      }
      &:nth-child(9) {
        width: 10%;
        min-width: 120px;
      }
    }
  }

  &.projects {
    td {
      &:nth-child(1) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(2) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(4) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(5) {
        width: 20%;
        min-width: 200px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    position: relative;
    left: -20px;
    width: 100vw;

    &.assets {
      td {
        &:nth-child(2) {
          min-width: 150px;
        }
        &:nth-child(3) {
          min-width: 180px;
        }
        &:nth-child(4) {
          min-width: 180px;
        }
        &:nth-child(6) {
          min-width: 180px;
        }
        &:nth-child(7) {
          min-width: 180px;
        }
        &:nth-child(8) {
          min-width: 350px;
        }
      }
    }

    &.serviceTickets {
      td {
        &:nth-child(1) {
          min-width: 65px;
        }
        &:nth-child(2) {
          min-width: 130px;
        }
        &:nth-child(3) {
          min-width: 100px;
        }
        &:nth-child(4) {
          min-width: 150px;
        }
        &:nth-child(5) {
          min-width: 150px;
        }
        &:nth-child(6) {
          min-width: 80px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
        &:nth-child(8) {
          min-width: 200px;
        }
        &:nth-child(9) {
          min-width: 200px;
        }
      }
    }

    &.picklists {
      td {
        &:nth-child(1) {
          min-width: 200px;
        }
        &:nth-child(2) {
          min-width: 190px;
        }
        &:nth-child(3) {
          min-width: 110px;
        }
        &:nth-child(4) {
          min-width: 170px;
        }
        &:nth-child(5) {
          min-width: 180px;
        }
        &:nth-child(6) {
          min-width: 200px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
        &:nth-child(8) {
          min-width: 200px;
        }
      }
    }

    &.leavelists {
      td {
        &:nth-child(1) {
          min-width: 200px;
        }
        &:nth-child(2) {
          min-width: 190px;
        }
        &:nth-child(3) {
          min-width: 110px;
        }
        &:nth-child(4) {
          min-width: 170px;
        }
        &:nth-child(5) {
          min-width: 180px;
        }
        &:nth-child(6) {
          min-width: 200px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
        &:nth-child(8) {
          min-width: 200px;
        }
      }
    }

    &.billOfLadings {
      td {
        &:nth-child(1) {
          min-width: 110px;
        }
        &:nth-child(2) {
          min-width: 150px;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          min-width: 200px;
        }
      }
    }

    &.looseItems {
      td {
        &:nth-child(1) {
          min-width: 200px;
        }
        &:nth-child(4) {
          min-width: 100px;
        }
        &:nth-child(5) {
          min-width: 100px;
        }
      }
    }
  }
}
