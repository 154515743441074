@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);
}
