@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  column-gap: 20px;
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    width: calc(100% - 20px - 32px);
  }
}
