@import 'assets/styles/variables.scss';

button.move_btn {
  width: 200px;
}

.pageContainer {
  font-family: $monserrat;
  padding: 10px 60px 40px;
  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $main_color;

    &__controls {
      display: flex;
      align-items: center;
      column-gap: 20px;
      padding-bottom: 10px;
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
      padding-bottom: 10px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
    margin: 15px 0 0;
    &__switcher {
      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .tableWrapper {
    position: relative;

    .menuButton {
      button {
        position: absolute;
        top: -40px;
        right: 0;
        border-radius: 100%;
        min-width: 20px;
        height: 22px;
        background: #000;
        color: #fff;
        cursor: pointer;
        padding: 0;
      }
    }

    .menuContainer {
      width: 196px;
      border-radius: 8px;
      margin-top: 5px;

      ul {
        padding: 0;
        li {
          font-family: $monserrat;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          height: 40px;

          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .emptyDashboardBlock {
    height: calc(100vh - 340px);

    h3 {
      text-align: center;
      margin: 80px 0;
      word-break: break-word;
    }
  }

  .tableContainer {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    th {
      white-space: nowrap;
    }

    td {
      -webkit-text-size-adjust: 100%;
    }

    td:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    .firstRow {
      min-width: 220px;
      width: 220px;
      z-index: 5;
      &.stickyRow {
        position: sticky;
        left: 0;
      }
      & > a {
        max-width: calc(220px - 16px - 16px - 2px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      & > div {
        white-space: nowrap;
        display: flex;
        justify-content: space-between;

        & > span {
          text-transform: uppercase;
        }

        & > .rowName {
          text-decoration: underline;
          cursor: pointer;
        }

        & > img {
          cursor: pointer;
        }
      }
    }

    .emptyResponseTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }
  }

  .footerControls {
    padding-top: 35px;
    text-align: right;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.move_btn {
    width: 100%;
  }

  .pageContainer {
    font-family: $monserrat;
    padding: 70px 20px 20px;
    .pageHeader {
      border-bottom: none;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 10;
      padding: 10px 20px;
      align-items: flex-start;
      flex-wrap: wrap;

      h1 {
        padding-left: 40px;
        line-height: 40px;
        padding-bottom: 0;
      }
    }

    .pageTopBlock {
      margin: 10px 0 15px;
    }

    h3 {
      word-break: break-all;
      margin: 0;
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      .firstRow {
        min-width: 140px;
        width: 140px;
        box-sizing: border-box;
        & > div {
          white-space: nowrap;
        }
      }

      td {
        padding: 8px 10px;
      }
    }
  }
}
