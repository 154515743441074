@import 'assets/styles/variables.scss';

.toggleButtonsBlock {
  border-radius: 8px;
  width: 100%;

  & > button {
    font-family: $monserrat;
    font-size: 14px;
    font-weight: 600;
    flex-grow: 1;
    padding: 5px 15px;
    border: none;
    color: #000;
    background: #f7f7f7;
    text-transform: none;
    width: 50%;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  button.selectedTab,
  button.selectedTab:hover {
    color: #fff;
    background-color: $main_color;
  }
}

.filterWrapper {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1001;
}

span.filterButton {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: #000;

  &:hover {
    background-color: #f6f6f6;
  }

  &__opened,
  &__opened:hover {
    background-color: $main_color;

    img {
      filter: invert(100%);
    }

    svg {
      color: #fff;
    }
  }
}

.checkbox {
  margin-right: 120px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.chipsBlock {
  flex-grow: 1;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  left: 30px;
  top: 1px;
  width: 600px;

  .fieldsChip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    font-family: $monserrat;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

.popper {
  z-index: 2;

  .filterContainer {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 20px;
    padding: 20px 0 20px;
    box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
    box-sizing: border-box;
    border-radius: 8px !important;
    min-width: 490px;
    max-height: 600px;
    overflow: auto;

    .formColumn,
    .formCell {
      display: flex;
    }

    .formColumn {
      flex-direction: column;
    }

    .formCell {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 20px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .inputWrapper {
        position: relative;
        width: 100%;

        .textInput {
          background: #f7f7f7;
          border-radius: 8px;
          width: 250px;
          margin: 0;
          border: 1px solid transparent;

          & > div {
            & > div {
              padding: 10px 10px 25px !important;
            }
          }

          fieldset {
            border: none;
          }
          input,
          textarea {
            font-family: $monserrat;
            font-weight: 400;
            padding: 0;
            font-size: 14px;
            line-height: 14px;
            width: 100%;
            color: #000;

            &::placeholder {
              opacity: 1;
              color: #000;
            }
          }
          input {
            padding: 8px 7px;
          }
        }
      }

      label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
        line-height: 31px;
        min-width: 170px;
      }

      .addFieldButton {
        min-width: 31px;
        height: 31px;
        background: $main_color;
        color: #fff;
        font-size: 24px;
        line-height: 0;
        border-radius: 8px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
          background: #ccc;
        }
      }
    }

    .fieldContainer {
      width: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      & > div {
        width: 190px !important;
        box-sizing: border-box;
        & > div {
          width: 100%;
        }
      }

      .textInput {
        width: 50px !important;
      }

      .deleteButton {
        margin-left: 0;
        margin-right: -25px;
        min-width: 14px;
        height: 14px;
        padding: 0;
        background: #000;
        svg {
          color: #fff;
          font-size: 10px;
          width: 10px;
          height: 10px;
        }
      }
    }

    span.checkbox {
      margin-right: 20px;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .checked {
      color: $main_color !important;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }

    .buttonWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: flex-end;
      padding: 0 20px;

      .primary {
        display: flex;
        width: 100%;
        button {
          width: 50%;
          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }

    & > h2 {
      color: black;
      font-family: $monserrat;
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 20px;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .filterWrapper {
    bottom: 160px;
    right: 15px;
    top: auto;
    left: auto;
    z-index: 1;
  }

  span.filterButton {
    width: 32px;
    height: 32px;

    &__opened,
    &__opened:hover {
      background-color: $filters_secondary_color;
    }
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 6 !important;
    overflow: auto;

    .filterContainer {
      display: flex;
      min-width: 100%;
      width: 100%;
      margin-left: 0;
      border-radius: 0 !important;
      transform: none !important;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
      overflow: auto;
      max-height: 100%;

      .formColumn {
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-top: 70px;
        height: calc(100% - 70px);
        overflow: auto;
        overscroll-behavior: none;
      }

      .formCell {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
        padding: 0 20px;

        label {
          font-family: $monserrat;
          margin: 0;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .inputWrapper {
          width: 100%;
          & > div {
            width: 100%;
            & > div {
              width: 100%;
            }
          }
          .textInput {
            width: 100%;
          }
        }
      }

      .fieldContainer {
        width: 100%;
        & > div {
          width: calc(100% - 100px) !important;
        }

        .textInput {
          width: 55px !important;
        }

        .deleteButton {
          margin: 0;
          min-width: 18px;
          height: 18px;
          svg {
            color: #fff;
            font-size: 12px;
          }
        }
      }

      .buttonWrapper {
        align-items: flex-end;
        margin: 20px 0 20px;
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;
        .primary {
          width: 100%;
          justify-content: space-between;

          button {
            margin: 0;
            width: 50%;
            min-width: 40%;
            &:first-child {
              margin: 0 30px 0 0 !important;
            }
            &:last-child {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  .chipsBlock {
    flex-grow: 1;
    padding: 0 20px;
    box-sizing: border-box;
    position: static;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    .fieldsChip {
      margin-right: 0;
      margin-bottom: 0;
      max-width: 100%;
    }
  }
}
