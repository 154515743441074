@import 'assets/styles/variables.scss';

div.transparentBackDrop {
  background-color: transparent;
}

.wrapper {
  display: flex;
  &.private_z_index {
    & > span {
      z-index: 9 !important;
    }
  }

  & > div {
    z-index: 7;
  }

  span.wrapper__button {
    width: 34px;
    height: 34px;
    z-index: 5;
    border-radius: 8px;
    background-color: #f6f6f6;

    &_opened,
    &_opened:hover {
      background-color: $main_color;

      img {
        filter: invert(100%);
      }
    }
  }
}

.popper {
  &__container {
    box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 30px 20px;
    margin-left: 20px;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;

    .primary {
      display: flex;

      button {
        width: 196px;
        min-width: 196px;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  row-gap: 10px;
  box-sizing: border-box;

  div.chips__chip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
    max-width: 100%;
    font-family: $monserrat;
    height: 34px;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

.header {
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    span.wrapper__button {
      width: 32px;
      height: 32px;

      &_opened,
      &_opened:hover {
        background-color: $filters_secondary_color;
      }
    }
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: auto;

    &__container {
      position: fixed;
      width: 100%;
      margin-left: 0;
      border-radius: 0 !important;
      transform: none !important;
      height: 100%;
      padding: 70px 20px 20px;
      overflow: auto;
      overscroll-behavior: none;
      display: flex;
      flex-direction: column;
    }

    .buttons {
      margin-top: auto;
      justify-content: space-between;
      align-items: flex-end;
      .primary {
        width: 100%;
        button {
          width: 50%;
          min-width: 40%;
        }
        button:first-child {
          margin: 0 30px 0 0 !important;
        }
        button:last-child {
          margin: 0 !important;
        }
      }
    }
  }

  .chips {
    padding: 0;
    margin-bottom: 20px;
    &.enableForMobile {
      padding: 0 0 0 20px;
      & > div {
        max-width: calc(100vw - 20px - 20px - 32px - 20px);
      }
    }
    width: 100%;
  }

  .header {
    position: fixed;
    padding: 0 20px 0 60px;
    top: 0;
    left: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: $monserrat;
      line-height: 38px;
      margin: 0;
    }
  }
}
