@import 'assets/styles/variables.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 40px;
  &__selector {
    display: flex;
    align-items: center;
    & > label {
      font-size: 14px;
      margin-right: 16px;
    }
  }
  &__container {
    display: flex;
    align-items: center;
  }
}

div.select {
  font-family: $monserrat;
  padding: 5px 10px;
  min-width: 20px;
  border-radius: 8px !important;

  & ~ fieldset {
    border: none;
    border-radius: 8px;
  }
}
li.select__root {
  font-family: $monserrat;
}
svg.select__icon {
  color: #000;
}
