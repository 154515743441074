@import 'assets/styles/variables.scss';

.dropzoneWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .dzu_layoutWrapper {
    position: relative;
    width: 40px;
    & > div {
      width: 100%;
      height: 100%;
      transition: none;
      border-radius: 0;
      margin-bottom: 0;
      background: transparent;
      & > label {
        justify-content: center;
        padding: 0;

        svg {
          height: 20px;
          width: 20px;
          margin-right: 0;
        }
      }
    }
  }
}

.dzu_dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15px;
  min-height: 15px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border-radius: 8px;
  margin-bottom: 0;
  background: transparent;
}

.dzu_input {
  display: none;
}

.dzu_previewFileWrapper,
.dzu_fileWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-top: 2px;
  box-sizing: border-box;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    display: block;
    font-size: 14px;
    margin-left: 10px;
    line-height: 21px;
  }
}

div.dzu_uploadSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px !important;
  margin-right: 2px;

  svg {
    width: 16px;
    height: 16px;
    color: #000;
  }
}

.dzu_fileLoading,
.deprecatedInpu {
  opacity: 0.5;
}

svg.dzu_deleteButton {
  width: 20px;
  height: 20px;
  background: #fff;
  cursor: pointer;
  margin-top: -2px;
}
