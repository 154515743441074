@import 'assets/styles/variables.scss';

$space: 50px;
$form_row_gap: 20px;
$form_block_gap: 30px;

.wrapper {
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: $space;
    .ticket {
      display: flex;
      flex-direction: column;
      row-gap: $form_block_gap;
      margin-bottom: 30px;

      &__assets {
        display: flex;
        flex-direction: column;
        row-gap: $form_row_gap;
        &_filter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 470px;
          height: $field_default_height;
        }

        &_field {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          width: calc(427px + 427px + 100px + 45px);
        }
      }

      .section {
        display: flex;
        flex-direction: column;
        row-gap: $form_row_gap;
        .container {
          display: flex;
          column-gap: 100px;
          &__block {
            width: 427px;
            display: flex;
            flex-direction: column;
            row-gap: $form_row_gap;
          }
        }
      }
    }
  }

  .footer {
    margin-top: auto;
  }
}

.otherRow {
  width: calc(427px + 110px + 15px);

  .otherField {
    display: flex;
    align-items: center;
    position: relative;

    & > span {
      position: absolute;
      bottom: -16px;
      font-size: 10px;
      font-family: $monserrat;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .form {
      .ticket {
        &__assets {
          width: 100%;

          &_filter {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            row-gap: 10px;
            height: auto;
          }

          &_field {
            width: 100%;
          }
        }

        .section {
          .container {
            width: 100%;
            flex-direction: column;
            row-gap: 20px;
            &__block {
              width: 100%;
            }
          }
        }
      }
    }

    .otherRow {
      width: 100%;
      .otherField {
        width: 100%;

        & > span {
          position: absolute;
          bottom: -16px;
          font-size: 10px;
          font-family: $monserrat;
        }
      }
    }

    .footer {
      & > button {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
