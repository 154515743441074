@import 'assets/styles/variables.scss';

button.create_request_button {
  height: 30px;
  margin: 0 0 0 25px;
}

.wrapper {
  font-family: $monserrat;
  padding: 60px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
}

@media screen and (max-width: $breakpoint-1180) {
  button.create_request_button {
    width: 100%;
    margin: 0 0 20px;
  }

  .wrapper {
    padding: 60px 20px 20px;
  }
}
