@import 'assets/styles/variables.scss';

.counting {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 220px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_label,
  &_count {
    font-size: 14px;
    font-family: $monserrat;
  }

  &_label {
    display: flex;
    align-items: center;
    column-gap: 15px;

    span.bold {
      font-weight: bold;
    }
  }

  &_count {
    font-weight: bold;
  }
}

.indicator {
  display: inline-block;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  cursor: default !important;
}

@media screen and (max-width: $breakpoint-1180) {
  .counting {
    width: 100%;
  }
}
