@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100%;
  box-sizing: border-box;
  font-family: $monserrat;
  row-gap: 30px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 20px;
      max-width: calc(100% - 25px - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__controls {
      width: 25px;
      &_btn {
        svg {
          color: black;
        }
      }
    }
  }
}

.content {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  row-gap: 25px;

  .accordion_content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    & > div {
      width: 100%;
      row-gap: 15px;
    }

    .fields_btn {
      height: 25px;
      width: 70px;
      align-self: flex-end;
      text-transform: capitalize;
    }
  }

  .counts {
    display: flex;
    flex-direction: column;
    font-family: $monserrat;
    font-size: 14px;
    row-gap: 10px;
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
