@import 'assets/styles/variables.scss';

$add_button_size: 33px;

.wrapper {
  width: 550px;
  display: flex;

  &.shorten {
    width: 420px;
  }

  .component {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .field {
      width: 420px;
      box-sizing: border-box;
      padding: 0;
      background: white;
      border: 1px solid #507a8e;
      border-radius: 8px;
      position: relative;
      &.invalid {
        border-color: $error_color;
      }

      &__tip {
        position: absolute;
        bottom: -16px;
        left: 0;
        font-size: 10px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 125px);
        &_text,
        &_error {
          font-family: $monserrat;
          white-space: nowrap;
        }
        &_error {
          color: $error_color;
        }
      }

      button.popup_indicator {
        padding-top: 3px !important;
        color: #507a8e;
      }
      .input_root {
        padding: 0;
        input {
          font-family: $monserrat;
          font-weight: 400;
          padding: 9px 16px;
          font-size: 12px;
          line-height: 14px;
          color: #000;
        }
        fieldset {
          border: none;
        }
      }
    }

    .add_button {
      height: $add_button_size;
      width: 110px;
      background-color: #ffd83c;
      padding: 6px;
      border-radius: 8px;
      margin-left: 15px;
      text-transform: initial;
      font: {
        weight: 500;
        size: 12px;
        family: $monserrat;
      }
      box-shadow: $shadow;
      &:hover {
        box-shadow: $shadow_hover;
        background-color: #ffd83c;
      }

      svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        color: black;
      }
      &.disabled {
        background-color: #e6e6e6;
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.field__option {
  display: flex;
  align-items: center;
  width: 100%;
  &_label {
    width: calc(100% - 25px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-family: $monserrat;
    margin-left: 16px;
  }
}
.popper {
  position: absolute;
  font-family: $monserrat;
  margin-top: 3px;
  min-width: 250px;
  width: auto;
  max-width: 40vw;
}
div.no_options {
  font-family: $monserrat;
  color: #000;
  cursor: default;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;

    &.shorten {
      width: 100%;
    }

    .component {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .field {
        width: calc(100% - 48px);
      }
      .add_button {
        width: $add_button_size;
        min-width: $add_button_size;
        height: $add_button_size;
        svg {
          font-size: 24px;
          margin-right: 0;
        }
      }

      &:not(:has(button.add_button)) {
        .field {
          width: 100%;
        }
      }
    }
  }
}
