@import 'assets/styles/variables.scss';

.tabs {
  margin-bottom: 20px;
  &__bar {
    margin: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .tabs {
    width: 100%;
    div.tabs__bar {
      border-radius: 0 0 0 0 !important;
      position: relative;
      left: -20px;
      width: 100vw;
      overflow: auto;
      margin-bottom: 0;

      & > button {
        min-width: 120px;
        height: 60px;
        width: auto;
        font-size: 14px;
        padding: 5px 16px;
        white-space: normal;
        line-height: 16px;
        &:first-child {
          border-radius: 0 0 0 0;
        }
        &:last-child {
          border-radius: 0 0 0 0;
        }
      }
    }
  }
}
