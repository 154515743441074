@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    row-gap: 20px;

    &__assets {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 850px;
    }

    &__location {
      width: 550px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .footer {
    margin-top: auto;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .form {
      &__assets {
        width: 100%;
      }

      &__location {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 10px;
      }
    }

    .footer {
      & > button {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
