@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  width: 250px;

  &.small {
    width: 120px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
    font-family: $monserrat;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
    &.small {
      width: 100%;
    }
    div.textInput {
      width: 100%;
    }
  }
}
