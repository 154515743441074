div.table {
  grid-area: availability;

  tr {
    &.unavailable {
      & > td {
        background-color: #eea6a2;
      }
      &:hover {
        & > td {
          background-color: #eea6a2;
        }
      }
    }
    &.available {
      & > td {
        background-color: #7ec37e;
      }
      &:hover {
        & > td {
          background-color: #7ec37e;
        }
      }
    }
    &.notSubmitted {
      & > td {
        background-color: #a8d5ff;
      }
      &:hover {
        & > td {
          background-color: #a8d5ff;
        }
      }
    }
  }

  th {
    white-space: nowrap;
    height: 40px;
    padding: 4px 12px;
    box-sizing: border-box;

    &:nth-child(1) {
      width: 20%;
      min-width: 130px;
    }
    &:nth-child(2) {
      width: 40%;
      min-width: 160px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      width: 20%;
      min-width: 110px;
      text-align: center;
    }
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    padding: 4px 12px;
    box-sizing: border-box;
    height: 40px;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    &.statusCell {
      width: 20%;
      min-width: 130px;
      white-space: normal;
      word-break: break-word;
      line-height: 15px;
      &.extended {
        padding-top: 12px;
        vertical-align: top;
        background-color: white !important;
      }
    }

    &.staffCell {
      width: 40%;
      min-width: 160px;
      word-break: break-word;
      line-height: 15px;
    }

    &.durationCell {
      width: 20%;
      min-width: 110px;
      text-align: center;
      line-height: 15px;
    }

    &.bold {
      font-weight: 700;
    }
  }
}
