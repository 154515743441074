@import 'assets/styles/variables.scss';

.dialog {
  width: 980px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-family: $monserrat;
  box-sizing: border-box;
  .form {
    display: flex;
    column-gap: 40px;
    row-gap: 20px;
    &__block {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      row-gap: 20px;
    }

    &__label {
      display: flex;
      align-items: center;
    }

    &__dateRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 15px;
    }

    &__date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      & > label {
        font-family: $monserrat;
        font-size: 14px;
      }
    }

    &__picker {
      width: 125px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    padding: 70px 20px 20px;

    .form {
      flex-wrap: wrap;
      row-gap: 20px;
      &__block {
        flex-basis: 100%;
      }
    }
  }
}
