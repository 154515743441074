@import 'assets/styles/variables.scss';

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  height: 35px;
}

div.table {
  border-radius: 8px;
  overflow-x: initial;
  margin-bottom: 15px;

  table {
    border-collapse: initial;
  }
  th {
    white-space: nowrap;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &:nth-child(1) {
      width: 20%;
      min-width: 250px;
    }
    &:nth-child(2) {
      width: 20%;
      min-width: 120px;
    }
    &:nth-child(3) {
      width: 15%;
      min-width: 170px;
    }
    &:nth-child(4) {
      width: 15%;
      min-width: 320px;
      white-space: normal;
      word-break: break-word;
    }
    &:nth-child(5) {
      width: 10%;
      min-width: 95px;
    }
  }
  td.empty {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  &__left {
    &_tip {
      font-style: italic;
      font-weight: 100;
      font-size: 11px;
      display: block;
      opacity: 0.5;
      padding-left: 5px;
    }
  }

  & > section {
    padding-top: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    width: 100vw;
    overflow-x: auto;
  }

  .footer {
    flex-direction: column;
    justify-content: normal;
    position: relative;
    padding: 0 7px;
    &__left {
      position: absolute;
      top: 5px;
    }
  }
}
