.tableRow {
  td {
    max-width: 0;
    white-space: normal;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.nameCell {
    width: 25%;
    min-width: 180px;
  }

  td.emailCell {
    width: 20%;
    min-width: 300px;
  }

  td.roleCell {
    width: 10%;
    min-width: 200px;
  }

  td.departmentCell {
    width: 45%;
    min-width: 250px;
  }

  td.removeCell {
    width: 60px;
    min-width: 60px;
    padding: 0;
    text-align: center;
    vertical-align: middle;

    .icon_button {
      svg {
        color: black;
        width: 25px;
        height: 25px;
      }
    }
  }
}
