@import 'assets/styles/variables.scss';

.buttonWrapper {
  width: 40px;
  display: flex;
  justify-content: center;
  .dotsButton {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    & > span {
      width: inherit;
      & > img {
        width: inherit;
        height: inherit;
      }
    }
    &:hover {
      background: transparent;
    }
  }
}

.mobilePopper {
  width: calc(100% - 40px) !important;
}

.mobileContainer {
  margin-top: 10px;
  padding: 5px 0;
  border-radius: 8px !important;
  width: 150px;

  ul {
    padding: 0;
    li {
      font-family: $monserrat;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      padding: 6px 10px;

      svg,
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: scale-down;
        margin-left: 0;
      }
    }
  }
}
