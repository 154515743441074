@import 'assets/styles/variables.scss';

.modal {
  width: 100%;
  padding: 70px 0 20px;
}

.details {
  display: flex;
  justify-content: space-between;
}

.general {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  flex-basis: 50%;
}

.counting {
  & > table {
    tr:last-child {
      td {
        font-weight: 700;
      }
    }
    th,
    td {
      border-bottom: none;
      font-family: $monserrat;
      text-align: center;
      min-width: 160px;
      box-sizing: border-box;
    }
    th {
      font-weight: 700;
      padding: 0 16px 8px;
      vertical-align: top;
    }
    td {
      padding: 8px 16px;
      &:first-child {
        border-right: 1px solid rgba(220, 220, 220, 1);
        text-align: right;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
  }
}

.controls {
  display: flex;
  column-gap: 20px;
  button.add_button,
  button.start_button {
    width: 150px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: $monserrat;
    box-shadow: $shadow;
    &:hover {
      box-shadow: $shadow_hover;
    }
  }
  button.add_button {
    background: $main_color;
    color: white;
  }
  button.start_button {
    background: $extra_color;
    color: black;
    &__disabled {
      background-color: #e0e0e0 !important;
      color: #bdbdbd !important;
    }
  }
}

button.actions {
  border-radius: 50%;
  background: #c9c9c9;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  color: white;
  padding: 0;
  margin-left: 20px;

  &:hover {
    background: #9a9a9a;
  }
}

.menu {
  width: 200px;
  & > ul {
    & > li {
      &:last-child {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    flex-direction: column;
    margin-bottom: 20px;
    row-gap: 20px;

    button.start_button,
    button.add_button {
      height: 40px;
      width: 100%;
    }
  }

  .details {
    flex-direction: column;
    row-gap: 30px;
  }

  .counting {
    & > table {
      max-width: 500px;
      td,
      th {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 80px;
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}
