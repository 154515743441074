@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;

  .header {
    margin: 0 0 50px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }

  .generalLabel {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px;
    font-family: $monserrat;
  }

  .detailsForm {
    display: flex;
    column-gap: 100px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .formBlock {
      flex-basis: 427px;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;
      }

      .formRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .datesWrapper {
          display: flex;
          justify-content: space-between;
          width: 250px;

          .dateBlock {
            display: flex;
            flex-direction: column;

            & > label {
              font-family: $monserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              margin-bottom: 10px;
            }
          }
        }

        & > label {
          font-size: 14px;
          font-family: $monserrat;
          font-weight: 400;
        }

        &.mapRow {
        }
      }

      .checkboxRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .checkboxWrapper {
          &.larger {
            width: 250px;
          }
          &.smaller {
            width: 165px;
          }
        }

        & > label {
          font-size: 14px;
          font-family: $monserrat;
          font-weight: 400;
          white-space: nowrap;
          margin-right: 40px;
        }
      }

      .alignTop {
        align-items: flex-start;
        label {
          line-height: 32px;
        }
      }
    }
  }

  .simpleBlock {
    margin-bottom: 50px;
    width: 427px;
  }

  .footer {
    margin: 0;
    button {
      margin-right: 25px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    padding: 70px 20px 20px;

    .header {
      margin: 0 -20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      padding: 10px 20px 0;
      background: #fff;
      box-sizing: border-box;
      z-index: 1;
      width: 100%;

      h1 {
        padding-left: 40px;
        line-height: 40px;
        margin: 0;
      }
    }

    .generalLabel {
      margin: 0 0 20px;
    }

    .detailsForm {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .formBlock {
        flex-basis: auto;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .formRow {
          flex-direction: column;
          align-items: flex-start;

          &.mapRow {
            width: 100%;
            align-items: flex-end;
          }

          & > label {
            margin-bottom: 10px;
          }

          .checkboxWrapper {
            width: 250px;
          }

          .datesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100% !important;

            .dateBlock:first-child {
              margin-bottom: 10px;
            }
          }
        }

        .checkboxRow {
          justify-content: flex-end !important;
          margin-bottom: 0 !important;

          .checkboxWrapper {
            &.larger,
            &.smaller {
              width: 29px;
            }
          }

          & > label {
            margin-right: 50px;
          }
        }

        .alignTop {
          label {
            line-height: normal;
          }
        }
      }
    }

    .simpleBlock {
      width: 100%;
      margin-bottom: 40px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
}
