@import 'assets/styles/variables.scss';

.wrapper {
  width: 1100px;
  height: 700px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $monserrat;
  &_container {
    .formRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 427px;
      margin-bottom: 20px;
      & > label {
        font-size: 14px;
        font-weight: 400;
        font-family: $monserrat;
      }
      .inputWrapper {
        position: relative;
        & > span {
          position: absolute;
          font-weight: 400;
          font-size: 10px;
          left: 0;
          bottom: -16px;
          color: $error_color;
          font-family: $monserrat;
        }
      }
    }
    &__table {
      border-radius: 8px;
      margin-bottom: 15px;
      table {
        border-collapse: initial;
      }
      th,
      td {
        height: 40px;
      }
      th {
        padding: 2px 8px;
        line-height: 20px;
      }
      td {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-text-size-adjust: 100%;
        padding: 2px 12px;
        box-sizing: border-box;
        &:not(:last-child) {
          border-right: 1px solid #dcdcdc;
        }
        &:nth-child(1),
        &:nth-child(2) {
          & > div {
            width: 100%;
            max-width: 100%;
            & > div {
              background: transparent;
              & > div {
                & > div {
                  padding: 0 30px 0 0 !important;
                  button {
                    margin-right: -15px !important;
                  }
                }
              }
            }
          }
        }

        &:nth-child(1) {
          width: 30%;
          min-width: 290px;
        }
        &:nth-child(2) {
          width: 30%;
          min-width: 260px;
        }
        &:nth-child(3) {
          width: 10%;
          min-width: 120px;
        }
        &:nth-child(4) {
          width: 15%;
          min-width: 100px;
        }
        &:nth-child(5) {
          width: 15%;
          min-width: 110px;
        }
        &:nth-child(6) {
          width: 60px;
          min-width: 60px;
          padding: 0;
          vertical-align: middle;
          text-align: center;
          button {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    min-height: 100vh;
    padding: 70px 20px 20px;
    &_container {
      .formRow {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        & > label {
          margin-bottom: 10px;
        }
        .inputWrapper {
          width: 100%;
        }
      }
      &__table {
        position: relative;
        left: -20px;
        width: 100vw !important;

        th {
          height: 40px;
          line-height: 15px;
        }

        td {
          &:nth-child(1) {
            min-width: 185px;
          }
          &:nth-child(2) {
            min-width: 175px;
          }
          &:nth-child(3) {
            min-width: 110px;
          }
          &:nth-child(4) {
            min-width: 100px;
          }
          &:nth-child(5) {
            min-width: 105px;
          }
          &:nth-child(6) {
            min-width: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-443) {
  .wrapper {
    padding: 100px 20px 20px;
  }

  .modal {
    width: 100%;

    &__footer {
      text-align: center;
    }
  }
}
