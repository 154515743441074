@import 'assets/styles/variables.scss';

$add_button_size: 33px;

.wrapper {
  width: 550px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  .field {
    background: white;
    border: 1px solid #507a8e;
    border-radius: 8px;
    width: 420px;
    box-sizing: border-box;
    position: relative;
    fieldset {
      border: none;
    }
    &__input {
      width: 100%;
    }
    &.invalid {
      border: 1px solid $error_color;
    }
    input {
      font-family: $monserrat;
      font-weight: 400;
      padding: 9px 16px;
      font-size: 12px;
      line-height: 14px;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
  }

  .tip {
    position: absolute;
    bottom: -16px;
    left: 0;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 125px);
    &_text,
    &_error {
      font-family: $monserrat;
      white-space: nowrap;
    }
    &_error {
      color: $error_color;
    }
  }

  .add_button {
    height: $add_button_size;
    width: 110px;
    background-color: #ffd83c;
    padding: 6px;
    border-radius: 8px;
    margin-left: 15px;
    text-transform: initial;
    font: {
      weight: 500;
      size: 12px;
      family: $monserrat;
    }
    box-shadow: $shadow;
    &:hover {
      box-shadow: $shadow_hover;
      background-color: #ffd83c;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      color: black;
    }
    &.disabled {
      background-color: #e6e6e6;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .expand {
    opacity: 1;
    color: #507a8e;
    cursor: pointer;

    &.opened {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    flex-wrap: wrap;
    .field {
      width: calc(100% - 48px);
    }
    .tip {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 48px);
      position: relative;
      bottom: -5px;
    }

    .add_button {
      width: $add_button_size;
      min-width: $add_button_size;
      height: $add_button_size;
      svg {
        font-size: 24px;
        margin-right: 0;
      }
    }
  }
}
