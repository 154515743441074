@import 'assets/styles/variables.scss';

.wrapper {
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 6;
    padding: 10px 20px;
    row-gap: 2px;
    height: 100px;
  }
}
