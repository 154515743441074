@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  width: 250px;
  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    &.error {
      color: $error_color;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & > span {
      position: relative;
      font-size: 10px;
      bottom: -5px;
    }
  }
}
