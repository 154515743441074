@import 'assets/styles/variables.scss';

.summaryCounters {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin: 0 30px 0 0;

  .blockRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  & + div {
    margin-top: -30px;
  }
}

.statusIndicator {
  position: relative;
  display: block;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  margin: 0 auto;
  margin-right: 15px;

  &.Operational {
    background: $regular_color;
    box-shadow: 0px 1px 4px rgba(0, 147, 0, 0.25);
  }
  &.Critical {
    background: $alert_color;
    box-shadow: 0px 1px 4px rgba(233, 45, 45, 0.25);
  }
  &.NonCritical {
    background: $warning_color;
    box-shadow: 0px 1px 4px rgba(236, 191, 34, 0.25);
  }
  &.BeyondRepair {
    background: #0c0c0c;
    box-shadow: 0px 1px 4px rgba(181, 152, 218, 0.25);
  }

  & svg {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 100%;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .summaryCounters {
    width: 100%;
    margin: 0;
  }
}
