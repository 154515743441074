@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1450) {
    overflow-x: auto;
  }

  tr.bol_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.idCell {
        width: 5%;
        min-width: 110px;
      }
      &.freightBillCell {
        width: 10%;
        min-width: 140px;
      }
      &.picklistCell {
        width: 15%;
        min-width: 240px;
      }
      &.originCell {
        width: 15%;
        min-width: 175px;
      }
      &.destinationCell {
        width: 15%;
        min-width: 140px;
      }
      &.jobNumberCell {
        width: 10%;
        min-width: 140px;
      }
      &.userCell {
        width: 15%;
        min-width: 140px;
      }
      &.dateCell {
        width: 15%;
        min-width: 190px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  tr.bol_table_row {
    & > td {
      min-width: 200px;
      &.idCell {
        min-width: 110px;
      }
      &.freightBillCell {
        min-width: 150px;
      }
    }
  }
}
