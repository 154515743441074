.wrapper {
  width: 900px;
  box-sizing: border-box;
  display: flex;
  column-gap: 60px;
  .block {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    row-gap: 20px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_dates {
        display: flex;
        align-items: center;
        column-gap: 5px;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      &:has(fieldset.radios) {
        justify-content: flex-end;
      }

      .radios {
        & > div {
          flex-direction: column;
          row-gap: 15px;
          & > label {
            flex-direction: row-reverse;
            margin: 0;
            column-gap: 20px;
          }
        }
      }
    }
  }
}
