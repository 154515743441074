@import 'assets/styles/variables.scss';

.popup {
  width: 465px;
  min-height: 450px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}
