@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;

  &:has(> div:nth-child(2)) {
    justify-content: space-between;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    flex-direction: row-reverse;
    column-gap: 20px;

    &:has(div:only-child) {
      justify-content: space-between;
    }
  }
}
