@import 'assets/styles/variables.scss';

.general {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  &__content {
    display: flex;
    column-gap: 100px;
    row-gap: 20px;
    &_block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 50%;
    }
  }

  .checkbox {
    display: inline;
    margin-left: 30px;
  }
}

.files {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  grid-column: 2;
  &__row {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    & > label {
      font-style: normal;
      font-family: $monserrat;
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      white-space: nowrap;
    }
    .videos {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 10px;
      max-height: calc(((54px + 10px) * 2));
      overflow-y: auto;

      &__item {
        position: relative;
        &:hover {
          &_icon {
            opacity: 1;
          }
        }
        & > video {
          height: 54px;
          width: 54px;
          object-fit: cover;
          border-radius: 8px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        }

        &_icon {
          opacity: 0;
          width: 54px;
          height: 54px;
          border-radius: 8px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          top: 0;
          cursor: pointer;
          background-color: hsla(0, 0%, 50.2%, 0.5);
          transition: opacity 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .data {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      max-height: calc(((17px + 10px) * 5));
      overflow-y: auto;

      &__item {
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__info {
    font-family: $monserrat;
    font-size: 14px;
    white-space: normal;
    word-break: break-word;
  }
}

.necessaryItems {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.remarks {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  div.table {
    border-radius: 8px;
    margin-bottom: 15px;
    table {
      border-collapse: initial;
    }

    th {
      white-space: nowrap;
    }
    th,
    td {
      height: 40px;

      &:nth-child(1) {
        width: 34%;
        min-width: 250px;
        white-space: normal;
        word-break: break-word;
      }
      &:nth-child(2) {
        width: 22%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 22%;
        min-width: 200px;
      }
      &:nth-child(4) {
        width: 22%;
        min-width: 250px;
      }
    }
    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
    }
    td.empty {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .general {
    &__content {
      flex-wrap: wrap;
      &_block {
        width: 100%;
      }
    }
  }

  .files {
    grid-column: 1;
    grid-row: 1;
    &__row {
      .videos {
        max-height: fit-content;
      }
      .data {
        width: 100%;
        max-height: fit-content;
      }
    }
  }

  .remarks {
    grid-column: 1;
    grid-row: 3;

    div.table {
      position: relative;
      left: -20px;
      width: 100vw;
    }
  }
}
