@import 'assets/styles/variables.scss';

.common,
.location {
  display: flex;
  &__label {
    font: {
      family: $monserrat;
      weight: 700;
      size: 14px;
    }
    margin: 0 0 20px;
    line-height: 17px;
  }
  &__block {
    width: 450px;
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      & > label {
        font: {
          family: $monserrat;
          style: normal;
          weight: normal;
          size: 14px;
        }
      }
    }
  }
}
.common {
  &__block {
    &:first-child {
      margin-right: 100px;
    }
  }
}

.delivery {
  width: 1000px;
  &_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .block {
      width: 450px;

      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .bold {
          font-weight: 700;
        }
        & > label {
          font: {
            family: $monserrat;
            style: normal;
            weight: normal;
            size: 14px;
          }
        }
      }
    }
  }
}

.vehicle {
  width: 100%;
  .radio {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    & > label {
      font: {
        family: $monserrat;
        style: normal;
        weight: 700;
        size: 14px;
      }
      line-height: 17px;
      color: #000000;
      margin-bottom: 10px;
    }
    &__inputs {
      display: flex;
      align-items: center;
      height: 35px;
    }
  }
}

$item-cell-width: 15%;
$qty-cell-width: 115px;
$remove-cell-width: 50px;
%table-cell-sizes {
  &:nth-child(1) {
    width: $item-cell-width;
    min-width: 200px;
  }
  &:nth-child(2) {
    width: calc(100% - #{$item-cell-width} - (#{$qty-cell-width} * 2) - #{$remove-cell-width});
    min-width: 500px;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: $qty-cell-width;
    min-width: $qty-cell-width;
    text-align: center;
  }
  &:nth-child(5) {
    width: $remove-cell-width;
    min-width: $remove-cell-width;
    text-align: center;
  }
}
.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  .table_wrapper {
    width: 100%;
    position: relative;
    div.table {
      border-radius: 8px;
      margin-bottom: 15px;
      table {
        border-collapse: initial;
      }
      th {
        padding: 0 12px;
        line-height: 17px;
        height: 40px;
        @extend %table-cell-sizes;
      }
      td {
        max-width: 0;
        overflow: hidden;
        height: 40px;
        text-overflow: ellipsis;
        -webkit-text-size-adjust: 100%;
        padding: 8px 12px;
        &.error {
          background-color: #f7e5e5 !important;
        }
        .error_message {
          color: $error_color;
          font-size: 10px;
          font-family: $monserrat;
        }
        &:not(:last-child) {
          border-right: 1px solid #dcdcdc;
        }
        @extend %table-cell-sizes;
        &:last-child {
          padding: 0;
          & > button {
            cursor: pointer;
            padding: 5px !important;
            svg {
              width: 20px;
              height: 20px;
              color: black;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          padding: 0;
        }
      }

      .input__description {
        background: transparent;
        box-sizing: border-box;
        & > div {
          padding: 12px 14px;
        }
      }
      .input__field {
        width: 100%;
        & > div {
          background: transparent;
        }
        input {
          text-align: center;
        }
      }
    }
  }
  span.table_error,
  span.button_error {
    font-family: $monserrat;
    font-size: 10px;
    color: $error_color;
  }
  span.table_error {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  span.button_error {
    text-align: center;
  }
}
.record {
  display: flex;
  width: 1000px;
  $record_label_width: 200px;
  &__textarea {
    width: calc(100% - #{$record_label_width});
  }
  & > label {
    font-family: $monserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: $record_label_width;
  }
}

.tableFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .total {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &__label {
      font: {
        family: $monserrat;
        size: 14px;
        weight: 700;
      }
      white-space: nowrap;
    }
    &__field {
      width: 100px;
    }
  }

  .addItemButton {
    width: 133px;
    height: 30px;
    background: #507a8e;
    border-radius: 8px;
    font: {
      family: $monserrat;
      size: 12px;
      weight: 500;
    }
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    &:hover {
      background: #4a7085;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .common,
  .location {
    flex-direction: column;
    width: 100%;
    &__label {
      display: none;
    }
    &__block {
      width: 100%;
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
      &_row {
        flex-direction: column;
        justify-content: normal;
        align-items: flex-start;
        & > label {
          margin-bottom: 10px;
        }
      }
    }
  }

  .record {
    flex-direction: column;
    width: 100%;
    &__textarea {
      width: 100%;
    }
    & > label {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .delivery {
    width: 100%;
    &_container {
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .block {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }

        &__row {
          flex-direction: column;
          justify-content: normal;
          align-items: flex-start;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          & > label {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .vehicle {
    width: 100%;
    .radio {
      &__inputs {
        flex-wrap: wrap;
        height: auto;
        & > div {
          margin-top: 20px;
        }
      }
    }
  }

  .tableFooter {
    align-items: flex-start;
    .total {
      flex-direction: column-reverse;
      align-items: flex-end;
      row-gap: 10px;
    }

    .addItemButton {
      width: 90px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
