@import 'assets/styles/variables.scss';

.details {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 100px;
    &_row {
      width: 50%;
    }

    &:has(div.checkboxRow) {
      .details__block_row {
        width: calc(50% - 50px);
      }
    }

    &:has(div:only-child) {
      &:not(:has(div.checkboxRow)) {
        .details__block_row {
          width: 100%;
        }
      }
    }
  }

  .checkboxRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    margin-bottom: 10px;

    & > label {
      font-weight: bold;
      white-space: nowrap;
      margin: 0;
      font-size: 14px;
      font-family: $monserrat;
      color: #000000;
      font-style: normal;
    }
  }
}

.title {
  font-family: $monserrat;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: $breakpoint-1180) {
  .details {
    &__block {
      flex-direction: column;
      row-gap: 20px;
      &_row {
        width: 100%;
      }

      &:has(div.checkboxRow) {
        .details__block_row {
          width: 100%;
        }
      }
    }
  }
}
