@import 'assets/styles/variables.scss';

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: $monserrat;
  padding: 20px 60px 20px;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 110px 20px 20px;
  }
}
