@import 'assets/styles/variables.scss';

.looseItems {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .table {
    border-radius: 8px;

    .footnote {
      font-size: 12px;
      position: relative;
      top: -8px;
    }

    @media screen and (max-width: $breakpoint-1180) {
      position: relative;
      left: -20px;
      width: 100vw !important;
    }

    table {
      border-collapse: initial;
    }

    th {
      padding: 8 16px;
      height: 40px;
      white-space: nowrap;
    }
    td {
      vertical-align: top;
      white-space: normal;
      word-break: break-all;
      max-width: 0;
      height: 40px;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid $table_border_color;
      }

      &.looseItemCell,
      &.requiredQtyCell {
        background-color: white !important;
      }

      &.invalid {
        background-color: $table_error_cell_background !important;
      }

      &.looseItemCell {
        width: 30%;
        min-width: 250px;
        white-space: normal;
        word-break: break-word;
      }

      &.originCell,
      &.availableQtyCell,
      &.selectedQtyCell,
      &.baaQtyCell {
        vertical-align: middle;
      }

      &.originCell {
        width: 30%;
        min-width: 250px;
        padding: 0;
      }

      &.requiredQtyCell,
      &.availableQtyCell {
        width: 8%;
        min-width: 130px;
      }

      &.selectedQtyCell {
        width: 8%;
        min-width: 140px;
        padding: 0;
      }

      &.baaQtyCell {
        padding: 0 10px 0 0;
        width: 16%;
        min-width: 180px;

        .content {
          display: flex;
          justify-content: space-between;
          column-gap: 10px;
          align-items: center;
          .controls {
            display: flex;
            column-gap: 10px;

            & > button {
              width: 20px;
              height: 20px;
              svg {
                width: 100%;
                height: 100%;
                color: black;
              }
            }
          }
        }
      }

      .errorContainer {
        padding: 0 16px 8px 16px;
      }
      .block_with_error {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
      }

      .location_field {
        width: 100%;
        & > div {
          width: 100%;
          max-width: 100%;
          & > div {
            width: 100%;
            background-color: transparent;
          }
        }
      }
      .quantity_field {
        width: 100%;
        min-width: 100px;
        & > div {
          background-color: transparent !important;
        }
      }
    }
  }
}
