@import 'assets/styles/variables.scss';

.header {
  margin-bottom: 30px;
  box-sizing: border-box;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: left;
    margin: 0;
    font-family: $monserrat;
  }
}

button.add {
  width: 56px;
  min-width: 56px;
  height: 40px;
  background: #507a8e;
  border-radius: 8px;
  color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    background: #50758e;
  }
  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  label {
    display: none;
  }
}

.hintLabel {
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
  opacity: 0.5;
  display: block;
  margin-bottom: 15px;
}

div.controls {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 427px;
  button {
    width: 50%;
    min-width: 40%;
    &:first-child {
      margin: 0 30px 0 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.warning {
  background-color: #ffefd0 !important;
}
.error {
  background-color: #f7e5e5 !important;
}
.errorBlock {
  & > span {
    white-space: normal;
    font-size: 9px;
    font-family: $monserrat;
    color: #ff0000;
    display: block;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 1;
    background: #fff;
    padding: 12px 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__title {
      padding-left: 40px;
      margin: 0;
      line-height: 34px;
    }
    button {
      top: 8px;
    }
  }

  div.controls {
    width: 100%;
  }

  .hintLabel {
    position: relative;
    left: -20px;
  }

  button.add {
    width: 100%;
    min-width: 100%;
    height: 40px;
    label {
      display: inline;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 10px;
    }
  }
}
