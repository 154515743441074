@import 'assets/styles/variables.scss';

$button_width: 20px;

.row {
  font-family: $monserrat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  width: calc(100% - 20px - 20px);
  min-height: 25px;
  &__field {
    width: calc(100% - 100px - 20px);

    &_label {
      font-size: 14px;
      white-space: normal;
      word-break: break-word;
    }
    &_input {
      position: relative;
      .textInput {
        background: #f7f7f7;
        border-radius: 8px;
        width: 100%;
        margin: 0;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        height: 30px;
        left: -7px;
        letter-spacing: 0.1px;
        & > div {
          height: 30px;
        }

        &.error {
          border-color: $error_color !important;
        }

        fieldset {
          border: none;
        }
        input {
          font-weight: 400;
          padding: 6px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          color: #000;
          font-family: $monserrat;

          &::placeholder {
            opacity: 1 !important;
            color: lightgray !important;
          }
        }
      }

      & > span {
        position: absolute;
        font-weight: 400;
        font-size: 10px;
        left: -7px;
        bottom: -14px;
        color: $error_color;
        line-height: normal;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    position: relative;

    &.reduced {
      width: 60px;
    }

    &_button {
      width: $button_width;
      height: 20px;

      &.edit {
        color: #b4b4b4;
        &:hover {
          color: #7e7e7e;
        }
      }
      &.remove {
        color: #b4b4b4;
        &:hover {
          color: #7e7e7e;
        }
      }
      &.check {
        color: #afcaa5;
        &:hover {
          color: #8cc577;
        }
      }
      &.cancel {
        color: #d79c9c;
        &:hover {
          color: #d77777;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .row {
    &__field {
      &_label {
        position: relative;
        left: 7px;
      }
      &_input {
        .textInput {
          left: 0;
        }
        & > span {
          left: 0;
        }
      }
    }
  }
}
