@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 20px;

  .details {
    display: flex;
    column-gap: 150px;
    &__block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      font-family: $monserrat;
      font-size: 14px;
      &_label {
        display: flex;
        align-items: center;
        & > div {
          height: 14px;
        }
      }

      &_input {
        width: 250px;
      }

      &_row,
      &_checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 33px;
      }
      &_row {
        width: 427px;
        & > label {
          max-width: calc(100% - 250px - 10px);
          white-space: normal;
          word-break: break-word;
        }
      }
      &_checkbox {
        width: 200px;
      }
    }
  }
}

div.tooltip {
  width: 156px;
}
