@import 'assets/styles/variables.scss';

.select_option {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 350px;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 25px);
    &:only-child {
      width: 100%;
    }
  }
  .project_plate {
    width: 35px;
    height: 20px;
    background-color: rgb(217, 217, 217);
    border-radius: 8px;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    font-weight: 600;
  }
}

.popup {
  width: fit-content;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow-x: hidden;

  .form {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;
    width: 427px;
    margin-top: auto;

    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .footer {
      width: 100%;
    }
  }
}
