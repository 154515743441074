.wrapper {
  width: 900px;
  box-sizing: border-box;
  display: flex;
  column-gap: 60px;
  .block {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    row-gap: 20px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 33.6px;
      position: relative;

      &_label {
        display: flex;
        align-items: center;
      }

      &_dates {
        display: flex;
        align-items: center;
        column-gap: 5px;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      &.checkbox {
        width: 297px;
      }

      .separator {
        position: absolute;
        border-bottom: 1px solid rgba(220, 220, 220, 1);
        height: 0;
        width: 100%;
        top: -10px;
      }
    }
  }
}
