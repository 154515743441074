@import 'assets/styles/variables.scss';

.dropzoneWrapper {
  width: 150px;

  .layoutWrapper {
    position: relative;
    & > div {
      width: 100%;
      height: 100%;
      transition: none;
      border-radius: 0;
      margin-bottom: 0;
      background: transparent;
      & > label {
        justify-content: center;
        padding: 0;

        svg {
          height: 20px;
          width: 20px;
          margin-right: 0;
        }
      }
    }
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $monserrat;
    font-size: 14px;
    width: 145px;
    &:hover {
      text-decoration: underline;
    }
  }
}
