@import 'assets/styles/variables.scss';

.wrapper {
  .field {
    position: relative;
    &__input {
      width: 100%;
      background: #f7f7f7;
      border-radius: 8px;
      margin: 0;
      border: 1px solid transparent;
      &.invalid {
        border-color: $error_color;
      }

      fieldset {
        border: none;
      }
      input {
        font-family: $monserrat;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;
        padding: 8px 16px;
        &::placeholder {
          opacity: 1;
          color: #000;
        }
      }
    }

    &__error,
    &__tip {
      position: absolute;
      left: 5px;
      bottom: -16px;
      font-size: 10px;
    }

    &__error {
      color: $error_color;
    }
    &__tip {
      font-style: italic;
      color: black;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .field {
      &__error,
      &__tip {
        position: relative;
        left: 5px;
        bottom: 0;
      }
    }
  }
}
