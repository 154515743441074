.sortHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;

  & > svg {
    opacity: 0.2;
    transition: all 0.15s ease-in-out;
  }

  &:hover,
  &.sorted {
    & > svg {
      opacity: 1;
    }
  }
  &.ascend {
    & > svg {
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}
