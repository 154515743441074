.notes {
  margin-bottom: 50px;
  &__block {
    & > span {
      font-size: 14px;
      margin-bottom: 20px;
      white-space: normal;
      word-break: break-word;
    }
  }
}
