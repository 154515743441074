.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
