@import 'assets/styles/variables.scss';

button.main_button {
  width: 210px;
  height: 30px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}

.searchBlock {
  position: relative;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }

  &__hint {
    position: absolute;
    bottom: -13px;
    right: 0;
    font-size: 12px;
  }
}

.accordion__icon {
  padding: 12px;
}

div.accordion {
  margin-bottom: 0 !important;

  $accordion_button_width: 75px;
  &__summary {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr $accordion_button_width;
    align-items: center;
    column-gap: 20px;
    padding-right: 5px;

    @media screen and (max-width: $breakpoint-1180) {
      column-gap: 10px;
    }
  }

  &__title {
    font-weight: 700;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    font-size: 16px;

    @media screen and (max-width: $breakpoint-1180) {
      font-size: 14px;
    }
  }

  button.accordion__button {
    width: $accordion_button_width;
    height: 25px;
  }

  &__details {
    width: 100%;
    overflow-x: auto;

    @media screen and (max-width: $breakpoint-1180) {
      display: grid;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 85px;
    align-items: center;
    column-gap: 20px;
    height: 40px;
    padding: 8px 16px;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    width: 100%;

    &:not(:first-child) {
      border-top-color: #dcdcdc;
    }

    &:nth-of-type(even) {
      background-color: #f7f7f7;
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  &__quantity {
    font-size: 14px;
    text-align: right;
  }

  &__empty {
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }
}
