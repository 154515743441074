@import 'assets/styles/variables.scss';

.popup {
  width: 480px;
  padding: 15px;
  box-sizing: border-box;
  font-family: $monserrat;
  .header {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    padding-bottom: 5px;

    & > h2 {
      margin: 0 auto;
    }

    &__close {
      width: 25px;
      height: 25px;
      svg {
        color: black;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .scanner {
      width: 90%;
      height: 90%;
      position: relative;
      section {
        box-sizing: border-box;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .frame {
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        border: 50px solid rgba(0, 0, 0, 0.3);
        box-shadow: rgba(255, 0, 0, 0.5) 0 0 0 5px inset;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    & > p {
      text-align: center;
      font-family: $monserrat;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 0;

    .header {
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 60px;
    }

    .content {
      padding: 30px 0 0;
      box-sizing: border-box;
      height: calc(100% - 60px);
      justify-content: center;

      .scanner {
        width: 390px;
        height: 390px;
      }
    }
  }
}
