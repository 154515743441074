@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  width: 250px;
  box-sizing: border-box;
  &.full {
    width: 100%;
  }

  &.drCode {
    display: flex;

    div.textInput {
      input {
        padding: 8px 115px 8px 16px;
      }
    }

    div.indicator {
      display: flex;
      align-items: center;
      column-gap: 5px;
      position: absolute;
      height: 34px;
      top: 0;
      right: 10px;

      & > span {
        font-size: 12px;
        z-index: 1;

        &.success {
          color: #7ec37e;
        }
        &.error {
          color: #da9898;
        }
      }
    }
  }

  div.textInput {
    background: #f7f7f7;
    border-radius: 8px;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    box-sizing: border-box;
    height: $field_default_height;

    &.error {
      border-color: $error_color;
    }

    fieldset {
      border: none;
      height: 34px;
      top: 0;
    }
    input {
      font-family: $monserrat;
      font-weight: 400;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;
      height: $field_default_height;
      box-sizing: border-box;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
  }

  .numberType {
    input {
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
    white-space: nowrap;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
    div.textInput {
      width: 100%;
    }
  }
}
