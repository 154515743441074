@import 'assets/styles/variables.scss';

$icon_size: 40px;

.card {
  height: 160px;
  border-radius: 8px;
  background-color: $card_background_color;
  box-shadow: $shadow;
  padding: 16px;
  column-gap: 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  user-select: none;
  box-sizing: border-box;
  position: relative;
  transition: all 0.5s;

  &:hover {
    background-color: $extra_color;
    cursor: pointer;
    transform: translateY(-10px);

    .card__controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__controls {
    display: none;
    position: absolute;
    right: 12px;
    top: 12px;

    button.card__controls_btn {
      width: 25px;
      height: 25px;
      border-radius: 50%;

      &:hover {
        background-color: $card_controls_hover_color;
      }

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: $card_text_color;
        }
      }
    }
  }

  &__preview {
    width: $icon_size;
    min-width: $icon_size;
    height: 100%;

    &_icon {
      width: $icon_size;
      height: $icon_size;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    &_title {
      font-weight: 700;
      font-size: 20px;
      font-family: $monserrat;
      color: $card_text_color;
      height: $icon_size;
      display: flex;
      align-items: center;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 15px;
      font-family: $monserrat;
      color: $card_text_color;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .card {
    &:hover {
      transform: translateY(0);
      background-color: $card_background_color;
    }
    &__controls {
      display: flex;
      right: 6px;
      top: 6px;
    }
  }
}
