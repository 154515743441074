@import 'assets/styles/variables.scss';

.wrapper {
  width: 60px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: $sidebar_scrollbar_track_color;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $sidebar_scrollbar_thumb_color;
    border-radius: 8px;
  }

  .paper {
    background: $sidebar_background_color;
    overflow-x: hidden;
    top: 60px;
    height: calc(100vh - 60px);

    &.opened {
      width: 300px;
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
    &.closed {
      overflow: hidden;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
    &.desktopClosed {
      width: 60px;
    }
    &.mobileClosed {
      width: 0;
      border: none;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 0;
    position: fixed;
    z-index: 1005;

    .paper {
      top: 0;
      height: 100%;
    }

    .toggle {
      padding: 0;
      min-width: 60px;
      width: 60px;
      height: 60px;
      & svg {
        color: black;
      }
    }
  }
}
