@import 'assets/styles/variables.scss';

.popupWrapper {
  width: 550px;
  padding: 40px 80px;
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  & > button {
    width: 160px !important;
    min-width: 160px !important;
    height: 40px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popupWrapper {
    width: 100%;
    padding: 70px 20px 20px;
  }

  .footer {
    width: 100%;
    & > button {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}
