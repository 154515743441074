@import 'assets/styles/variables.scss';

.header {
  position: relative;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  align-items: center;

  button.header__logo {
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
  }

  &__quick_buttons {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
  }

  &__title {
    color: red;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__options {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-left: auto;
  }

  a.header__version {
    font-family: $monserrat;
    letter-spacing: 3px;
    font-size: 12px;
    padding: 0 5px 0;
    color: $main_color;
  }

  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > svg {
      width: 30px;
      height: 30px;
      color: #acafc3;
    }
  }
}
