@import 'assets/styles/variables.scss';

.emptyTable {
  background-color: #f7f7f7 !important;
  padding: 25px 0 !important;
  font-size: 18px !important;
  text-align: center !important;
}

.notesWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .separator {
    border-bottom: 1px solid #dcdcdc;
    height: 0;
    position: relative;
    left: -8px;
    width: calc(100% + (8px * 2));
  }
}
.expandableBlock {
  white-space: break-spaces;
  word-break: break-word;
  min-height: 20px;
}
.commonBlock {
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkboxCell {
  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.requestEntityBlock {
  max-width: 100%;
}
.entityNameBlock {
  max-width: 100%;
}
.quantityBlock {
  max-width: 100%;
  text-align: center;
}
.transportationCostBlock {
  max-width: 100%;
  text-align: center;
}
.transportationTimelineBlock {
  max-width: 100%;
  text-align: center;
}
.purchasingCostBlock {
  max-width: 100%;
  text-align: center;
}
.purchasingTimelineBlock {
  max-width: 100%;
  text-align: center;
}
.transportationQTYBlock {
  max-width: 100%;
  text-align: center;
  font-weight: 700;
}
.purchasingQTYBlock {
  max-width: 100%;
  text-align: center;
  font-weight: 700;
}

.etaCell,
.statusCell {
  .pointer {
    cursor: pointer;
  }

  .etaBlock,
  .statusBlock {
    width: 100% !important;
    max-width: 100%;
    min-height: 20px;

    .helpers {
      display: flex;
      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;
        min-width: 20px;
        &:first-child {
          margin-right: 5px;
        }
        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }
    .alert {
      color: #fff;
      background: $alert_color;
    }
    .success {
      color: #fff;
      background: $regular_color;
    }
  }
}

.etaCell {
  max-width: 75px;
  .etaBlock {
    text-align: center;
    .textInput {
      width: 100%;
      & > div {
        width: 100%;
        border: 1px solid #999;
        padding: 2px !important;
        font-size: 14px;
        border-radius: 8px;
        height: 34.625px;
      }
      & > svg {
        right: 5px;
      }

      fieldset {
        border: none;
      }
      input,
      textarea {
        font-family: $monserrat;
        font-weight: 400;
        padding: 8px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;

        &::placeholder {
          opacity: 1;
          color: #000;
        }
      }
      input {
        padding: 3px 5px;
      }
    }
  }
}

.statusCell {
  max-width: 150px;
  .statusBlock {
    & > div:first-child {
      width: 100%;
      & > div {
        width: 100% !important;
        border: 1px solid #999;
        font-size: 14px;
        border-radius: 8px;
        & > div {
          width: 100% !important;
        }
      }
      & > svg {
        right: 5px;
      }
    }
  }
}

.fileBlock,
.picBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
