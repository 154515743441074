@import 'assets/styles/variables.scss';

.statusIndicator {
  display: block;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  margin-right: 0;
  cursor: default !important;
  &.leftSpace {
    margin-left: 5px;
  }

  &.Operational {
    background: $regular_color;
    box-shadow: 0 1px 4px rgba(0, 147, 0, 0.25);
  }
  &.Critical {
    background: $alert_color;
    box-shadow: 0 1px 4px rgba(233, 45, 45, 0.25);
  }
  &.NonCritical {
    background: $warning_color;
    box-shadow: 0 1px 4px rgba(236, 191, 34, 0.25);
  }
  &.BeyondRepair {
    background: #0c0c0c;
    box-shadow: 0 1px 4px rgba(181, 152, 218, 0.25);
  }
  &.Total {
    background: transparent;
    box-shadow: transparent;
  }
}
