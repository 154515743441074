@import 'assets/styles/variables.scss';

td.nameCell,
td.timeCell,
td.actionCell,
td.messageCell {
  max-width: 0;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  -webkit-text-size-adjust: 100%;
  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }
}

td.nameCell {
  width: 20%;
  min-width: 200px;
}
td.timeCell {
  width: 20%;
  min-width: 200px;
}
td.actionCell {
  width: 20%;
  min-width: 180px;
}
td.messageCell {
  width: 40%;
  min-width: 300px;
  white-space: normal;
  word-break: break-word;
}
