@import 'assets/styles/variables.scss';

.info {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  &.disableFlex {
    display: block;
    .info__span {
      margin-left: 0;
    }
    .info__label {
      margin-right: 10px;
    }
  }
  &.alignCenter {
    align-items: center;
  }
  &__label,
  &__span {
    font-style: normal;
    font-size: 14px;
    color: #000000;
    font-family: $monserrat;
  }
  &__label {
    font-weight: bold;
    white-space: nowrap;
  }
  &__span {
    margin-left: 10px;
    white-space: normal;
    word-break: break-all;
  }
}
