@import 'assets/styles/variables.scss';

.wrapper {
  position: relative;
  width: 250px;

  .inputLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding: 0 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    svg {
      height: 17px;
      width: 17px;
      margin-right: 6px;
    }
  }

  .deprecatedInput {
    cursor: not-allowed;
    opacity: 0.5;

    & ~ p {
      margin: 0;
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 5px;
      bottom: -14px;
      color: $error_color;
    }
  }
}

.previews {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 5px;
  user-select: none;

  &.topSpace {
    margin-top: 20px;
  }
}

.fileWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    display: block;
    font-size: 14px;
    margin-left: 5px;
  }

  button.deleteButton {
    width: 14px;
    height: 14px;
    svg {
      width: 19px;
      height: 19px;
      color: black;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
  }
}
