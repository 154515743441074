@import 'assets/styles/variables.scss';

.specification {
  margin-bottom: 25px;
  & > label {
    font-size: 14px;
    margin-bottom: 20px;
    font-family: $monserrat;
    display: block;
    font-weight: bold;
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    column-gap: 10px;
    row-gap: 10px;
  }
  &__licenses {
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 220px;
        & > label {
          font-size: 14px;
          font-family: $monserrat;
          margin-right: 20px;
        }
        & > span {
          font-weight: 700;
          font-size: 14px;
          font-family: $monserrat;
        }
      }
    }
  }
}

div.chip {
  background-color: #7aa5ba;
  border-radius: 8px;
  max-width: 100%;
  span.chip_label {
    color: white;
    padding: 6px 16px;
    text-transform: initial;
    font-weight: normal;
    font-family: $monserrat;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .specification {
    &__licenses {
      &_row {
        & > div:first-child {
          max-width: calc(100% - 100px - 5px);
        }
        .date {
          width: auto;
          & > span {
            font-weight: normal;
          }
        }
      }
    }
  }
}
