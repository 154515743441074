@import 'assets/styles/variables.scss';

div.wrapper {
  border-radius: 8px;
  overflow-x: initial;

  &.horizontalScroll {
    overflow-x: auto;
  }

  & > table {
    border-collapse: initial;

    tr {
      &:hover {
        & > td {
          background-color: $hovered-row-color !important;
        }
      }

      &:first-child {
        th {
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 8px;
          }
          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.wrapper {
    position: relative;
    left: -20px;
    width: 100vw;
    overflow-x: auto;

    & > table {
      tr {
        &:hover {
          &:nth-of-type(even) td {
            background-color: #f7f7f7;
          }
          &:nth-of-type(odd) td {
            background-color: #fff;
          }
        }
      }
    }
  }
}
