@import 'assets/styles/variables.scss';

.filterWrapper {
  display: flex;

  span.filterButton {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background-color: #f6f6f6;
    color: black;

    &__opened,
    &__opened:hover {
      background-color: $main_color;
      color: white;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;
  padding: 30px 16px 20px;
  width: 900px;
  box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;

  .formColumn,
  .formCell {
    display: flex;
  }

  .formColumn {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .formCell {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-basis: 47%;

    label {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
    }
  }

  .selectInput {
    height: 34.6px;
    padding: 8px 36px 6px 16px !important;
  }

  li.selectInput__menu {
    font-family: $monserrat !important;
    background: none !important;
  }

  svg.selectInput__icon {
    right: 10px;
    color: #000;
  }

  .buttonWrapper {
    display: flex;
    margin-top: 35px;
    justify-content: flex-end;

    button {
      width: 196px;
      min-width: 196px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}

.chipsBlock {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 0 20px;
  row-gap: 10px;
  box-sizing: border-box;

  .fieldsChip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
    max-width: 100%;
    font-family: $monserrat;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .filterWrapper {
    width: 100%;
  }

  .chipsBlock {
    padding: 0;
    width: 100%;
  }

  span.filterButton {
    width: 100% !important;
    height: 40px !important;
    background-color: $extra_color !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #000000;

    &__opened,
    &__opened:hover {
      background-color: $extra_color;
      color: #000000 !important;
    }
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: auto;
  }

  .filterContainer {
    display: flex;
    position: fixed;
    width: 100%;
    margin-left: 0;
    border-radius: 0 !important;
    transform: none !important;
    height: 100%;
    padding: 70px 20px 20px;
    overflow: auto;
    overscroll-behavior: none;

    .formColumn {
      justify-content: space-between;
      width: 100%;
    }

    .formCell {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      flex-basis: 100%;

      label {
        margin: 0 0 10px 0;
      }

      .inputWrapper {
        width: 100%;
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 0;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
  .headerWrapper {
    position: fixed;
    padding-top: 10px;
    top: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: calc(100vw - 40px);

    h1 {
      font-family: $monserrat;
      padding-left: 40px;
      line-height: 38px;
      margin: 0;
    }
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      display: flex;
      border: none;
      background: none;
      cursor: pointer;

      & > svg,
      & > img {
        margin-right: -14px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
