@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  tr.project_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.nameCell {
        width: 20%;
        min-width: 200px;
      }
      &.numberCell {
        width: 20%;
        min-width: 200px;
      }
      &.contractNumberCell {
        width: 20%;
        min-width: 200px;
      }
      &.contractDateCell {
        width: 20%;
        min-width: 200px;
      }
      &.descriptionCell {
        width: 20%;
        min-width: 200px;
      }
    }
  }
}
