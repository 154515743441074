@import 'assets/styles/variables.scss';

.header_button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  margin: 0 0 0 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  padding: 5px;
  height: 30px;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  & > svg,
  & > img {
    margin-right: 10px;
    color: black;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header_button {
    background: #f6f6f6;
    border-radius: 8px;
    height: 30px;
    width: 30px;
    justify-content: center;

    & > svg,
    & > img {
      margin-right: 0;
    }
  }
}
