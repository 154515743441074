@import 'assets/styles/variables.scss';

$cell-colors: (
  short: rgba(193, 222, 139, 0.5),
  medium: rgba(248, 211, 153, 0.5),
  longest: rgba(255, 173, 175, 0.5)
);

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  tr.history_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      @each $status, $color in $cell-colors {
        &.#{$status} {
          background-color: $color;
        }
      }

      &.teamMemberCell {
        width: 20%;
        min-width: 180px;
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > a {
            max-width: calc(100% - 30px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &.dateInCell {
        width: 10%;
        min-width: 120px;
      }
      &.dateOutCell {
        width: 10%;
        min-width: 120px;
      }
      &.projectCell {
        width: 30%;
        min-width: 250px;
      }
      &.roadDaysCell {
        width: 10%;
        min-width: 125px;
        text-align: center;
      }
      &.roadWeeksCell {
        width: 10%;
        min-width: 125px;
        text-align: center;
      }
      &.timeScheduleCell {
        width: 10%;
        min-width: 125px;
        text-align: center;
      }
    }
  }
}
