@import 'assets/styles/variables.scss';

.wrapper {
  min-height: 100%;
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header {
  padding-bottom: 25px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }
  button {
    display: none;
  }
}

.footer {
  margin: 60px 0 0;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    font-family: $monserrat;
    padding: 80px 20px 20px;
    min-height: 100vh;
    height: auto;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 20px 0;
    background: #fff;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: none;
    margin: 0;

    h1 {
      margin: 0 !important;
      padding-left: 40px;
      line-height: 40px;
    }

    button {
      display: block;
    }
  }

  .footer {
    margin: 60px 0 0;
    button {
      width: 100%;
      min-width: 100%;
    }
  }
}
