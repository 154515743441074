@import 'assets/styles/variables.scss';

tr.user_table_row {
  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.nameCell {
    min-width: 250px;
    width: 20%;
  }

  td.emailCell {
    min-width: 300px;
    width: 30%;
  }

  td.roleCell {
    width: 20%;
    min-width: 250px;
  }

  td.blockCell {
    width: 20%;
    min-width: 150px;
    & > div {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 15px;
        cursor: pointer;
        text-decoration: underline;
      }
      .locked {
        color: #c93030;
      }
      .unlocked {
        color: #000;
      }
      &.disabled {
        opacity: 0.2;
        & > span {
          cursor: not-allowed;
        }
      }
    }
  }

  td.isNewCell {
    width: 10%;
    min-width: 125px;
    text-align: center;
  }
}
