@import 'assets/styles/variables.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $main_color;
  min-height: 51px;
  box-sizing: border-box;
  padding-bottom: 10px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    font-family: $monserrat;
    margin: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid transparent;
    min-height: auto;
    padding-bottom: 0;

    h1 {
      padding-left: 40px;
      line-height: 40px;
    }
  }
}
