@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

tr.general_report_row {
  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.idCell {
    width: 5%;
    min-width: 90px;

    .clickable_id {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  td.createdAtCell {
    width: 30%;
    min-width: 190px;
  }
  td.submittedByCell {
    width: 20%;
    min-width: 180px;
  }
  td.templateCell {
    width: 10%;
    min-width: 130px;
  }
  td.noteCell {
    width: 30%;
    min-width: 180px;
    white-space: normal;
    word-break: break-word;
  }
  td.controlsCell {
    width: 125px;
    min-width: 125px;
    padding: 0;

    .controls {
      padding: 5px 16px;
      display: flex;
      column-gap: 10px;
      text-align: center;
      justify-content: center;
      .icon_button {
        img,
        svg {
          color: black;
        }
        img {
          width: 18px;
          height: 18px;
        }
        svg {
          width: 23px;
          height: 23px;
        }
      }
      .editingSpan {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  .actions {
    width: 100%;
  }
}
