@import 'assets/styles/variables.scss';

div.customEmail {
  background-color: white !important;
  border: 1px solid $main_color !important;
  color: $main_color !important;

  & > svg {
    color: $main_color !important;
  }
}

.dialog {
  width: 625px;
  min-height: 350px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow-y: auto;

  &.shorten {
    width: 500px;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 25px;
    margin-bottom: 80px;

    & > p {
      color: #7e7e7e;
      font: {
        family: $monserrat;
        size: 14px;
        style: italic;
      }
    }

    &__checkbox,
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > label {
        font: {
          family: $monserrat;
          size: 14px;
        }
      }
    }

    &__row {
      width: 420px;
      &_input {
        width: 250px;
      }
    }

    &__checkbox {
      width: 320px;
    }

    &__email {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      & > label {
        margin-bottom: 10px;
        font: {
          family: $monserrat;
          size: 14px;
        }
      }

      &_input {
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin-top: auto;

    & > button {
      min-width: 160px !important;
      width: 160px !important;
      height: 40px;
      margin: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    &.shorten {
      width: 100%;
    }

    .form {
      &__row {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        & > label {
          margin-bottom: 10px;
        }
        &_input {
          width: 100%;
        }
      }
      &__checkbox {
        align-self: flex-end;
      }
    }

    .footer {
      & > button {
        width: 46% !important;
        min-width: 46% !important;
      }
    }
  }
}
