@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:has(div:only-child) {
    justify-content: flex-end;
  }

  &__extra {
    display: flex;
    align-items: center;

    button.controls__extra_load {
      width: 150px;
      height: 30px;
      border-radius: 8px;
      background: $extra_color;
      font-family: $monserrat;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: #000000;
      box-shadow: $shadow;
      &:hover {
        background: $extra_color;
        box-shadow: $shadow_hover;
      }
    }
    .disabled {
      background: #e0e0e0 !important;
      color: #bdbdbd !important;
    }
  }
}
