@import 'assets/styles/variables.scss';

.separator {
  margin: 0 0 30px;
}

.wrapper {
  font-family: $monserrat;
  padding: 100px 60px 40px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  .headersContainer {
    .headersBlock {
      &:not(:last-child) {
        margin-bottom: 70px;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
      }
    }
  }
  .fieldsBlock {
    margin-bottom: 40px;
  }

  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    label {
      width: 180px;
      margin: 0;
      font-size: 14px;
    }
    .special {
      width: 420px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    font-family: $monserrat;
    padding: 60px 20px 20px;
    min-height: 100vh;

    .headersContainer {
      width: 100%;
      .headersBlock {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        &__header {
          margin-bottom: 20px;
          position: relative;
        }
      }
    }
    .form {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      label {
        width: 100%;
        margin: 0 0 10px;
      }
      .special {
        width: 100%;
      }
    }
  }
}
