@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 40px;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      white-space: normal;
      word-break: break-word;
      margin: 0;
      text-align: center;

      &.reducedWidth {
        max-width: 340px;
      }
    }
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    column-gap: 20px;

    & > button {
      width: 160px !important;
      min-width: 100px !important;
      &:last-child {
        margin-left: 0;
      }
    }
  }
}
