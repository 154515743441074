@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

.controls {
  display: flex;
  column-gap: 20px;
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }
}
