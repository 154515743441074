@import 'assets/styles/variables.scss';

.checkbox {
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.small {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &.red {
    color: #ba7a7a !important;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
