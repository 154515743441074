@import 'assets/styles/variables.scss';

.selector {
  display: flex;
  align-items: center;

  & > label {
    font-size: 14px;
    font-family: $monserrat;
    margin-right: 16px;
  }
}

div.input {
  font-family: $monserrat;
  padding: 5px 10px;
  min-width: 20px;
  height: 20px;
  line-height: 22px;

  &:focus {
    background-color: transparent;
  }

  & ~ fieldset {
    border: none;
    border-radius: 8px;
  }
}
li.item__root {
  font-family: $monserrat;
}
svg.item__icon {
  color: #000;
}
