@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
  button.addAssetsBtn {
    height: 30px;
    width: 140px;
  }
  .btn {
    height: 30px;
    width: 140px;
    svg,
    img {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    button.addAssetsBtn {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}
