@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

tr.table_row {
  & > td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    &.nameCell {
      width: 16%;
      min-width: 150px;
    }
    &.locationCell {
      width: 10%;
      min-width: 150px;
    }
    &.sublocationCell {
      width: 10%;
      min-width: 150px;
    }
    &.projectCell {
      width: 7%;
      min-width: 180px;
    }
    &.statusCell {
      width: 13%;
      min-width: 120px;
    }
    &.pocNameCell {
      width: 12%;
      min-width: 150px;
    }
    &.userCell {
      width: 16%;
      min-width: 130px;
    }
    &.dateCell {
      width: 16%;
      min-width: 160px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  tr.table_row {
    & > td {
      &.nameCell {
        min-width: 200px;
      }
      &.locationCell {
        min-width: 190px;
      }
      &.sublocationCell {
        min-width: 110px;
      }
      &.projectCell {
        min-width: 170px;
      }
      &.statusCell {
        min-width: 180px;
      }
      &.pocNameCell {
        min-width: 200px;
      }
      &.userCell {
        min-width: 200px;
      }
      &.dateCell {
        min-width: 200px;
      }
    }
  }
}
