@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 40px;
  .license {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    & > div {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
