@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 18px 20px 18px;
  margin-top: 40px;
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    .version {
      display: flex;
      justify-content: center;
      &__label {
        color: $sidebar_search_field_main_color;
        font-family: $monserrat;
        font-size: 14px;
      }
    }
  }
}
