@import 'assets/styles/variables.scss';

.info {
  text-align: center;
  margin-top: 20px;
  & > span {
    font-size: 24px;
    font-family: $monserrat;
  }
}
