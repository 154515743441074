@import 'assets/styles/variables.scss';

div.select {
  position: absolute;
  bottom: -16px;

  &:before {
    display: none;
  }

  & > svg {
    display: none;
  }
  div.select__root {
    visibility: hidden;
    position: absolute;
  }
}

.select__menu {
  &_option {
    width: calc(100% - 25px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    & > span {
      font-size: 14px;
      font-family: $monserrat;
      margin-left: 16px;
    }
  }
  &_empty {
    font-family: $monserrat;
    cursor: default;
    font-size: 14px;
  }
}
