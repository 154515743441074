button.back {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #f6f6f6;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  &:hover {
    background: #f6f6f6;
  }

  svg {
    color: black;
  }
}
