.asset {
  display: flex;
  align-items: center;
  column-gap: 8px;
  overflow-x: hidden;
  &__select {
    width: 20px;
    min-width: 20px;
    display: flex;
  }

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.assetStatus {
  display: flex;
  justify-content: center;
  &__indicator {
    position: relative;
    width: fit-content;
    & svg {
      position: absolute;
      top: -2px;
      right: -6px;
      width: 13px;
      height: 13px;
      background: #fff;
      border-radius: 100%;
    }
  }
}

.assetShortNote {
  overflow: hidden;
  text-overflow: ellipsis;
}
