@import 'assets/styles/variables.scss';

.mapContainer {
  display: flex;
  flex-direction: row;
  height: 100%;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .legend {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 1;

      @media screen and (max-width: $breakpoint-1180) {
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .headerWrapper {
    position: fixed;
    padding-top: 40px;
    top: 60px;
    background: #fff;
    width: 100%;
    max-width: 1160px;
    box-sizing: border-box;
    z-index: 1;
  }

  .pageHeader {
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }

  .helperBlock {
    display: flex;
    justify-content: flex-end;
  }

  .showMoreButton,
  .showMoreButton:hover,
  .showMoreButton:active {
    font-family: $monserrat;
    color: #fff;
    z-index: 1;
    width: 165px;
    height: 40px;
    border-radius: 8px;
    font-size: 12px;
    background: $main_color;
    box-sizing: border-box;
    position: relative;
    top: 30px;
    left: 15px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .mapContainer {
    height: 100vh;
  }

  .helperBlock {
    display: flex;
    justify-content: center !important;
  }

  .headerWrapper {
    position: fixed;
    padding-top: 10px !important;
    top: 0 !important;
    background: #fff;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    z-index: 2;

    h1 {
      padding-left: 60px;
      line-height: 38px;
    }
  }
}
