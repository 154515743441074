@import 'assets/styles/variables.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .mainBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    & > img {
      width: 350px;
      margin-bottom: 30px;
    }

    & > label {
      font-family: $monserrat;
      font-size: 36px;
      color: #546D79;
      font-weight: 400;
      margin-bottom: 20px;
    }

    & > span {
      color: #000;
      font-family: $monserrat;
      font-weight: 400;
      font-size: 18px;
    }
  }
}
