.tableRow {
  td {
    max-width: 0;
    white-space: normal;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.nameCell {
    width: 20%;
    min-width: 300px;
  }

  td.emailCell {
    width: 20%;
    min-width: 300px;
  }

  td.roleCell {
    width: 10%;
    min-width: 200px;
  }

  td.baaRecipientCell {
    width: 10%;
    min-width: 200px;
  }

  td.locationCell {
    width: 40%;
    min-width: 200px;
  }

  td.editCell {
    width: 90px;
    min-width: 90px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      .icon_button {
        svg {
          color: black;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
