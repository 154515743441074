@import 'assets/styles/variables.scss';

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupContainer {
  border-radius: 8px !important;
}

.popupTitle {
  padding: 20px 20px 0 16px !important;

  h2 {
    font-family: $monserrat;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: normal;
    word-break: break-word;
  }

  .infoIcon {
    & > svg {
      font-size: 40px;
      margin-right: 13px;
    }

    & > img {
      margin: 5px 10px 0 0;
    }

    &.success {
      color: $success_color;
    }

    &.alert {
      color: $alert_color;
    }
  }
}

.popupButton {
  justify-content: center !important;
  align-items: center !important;
  margin: 16px 0;
}
