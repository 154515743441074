@import 'assets/styles/variables.scss';

.header {
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $main_color;
    padding-bottom: 10px;
    margin: 0 0 40px;
    &_controls {
      display: flex;
      align-items: center;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  &.breakWord {
    h1 {
      word-break: break-word;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    &__block {
      border-bottom: none;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      z-index: 2;
      margin: 0;
      padding: 10px 20px 10px;
      height: 60px;
      display: flex;
      justify-content: flex-end;
      &_controls {
        height: 100%;
      }
    }
    &__title {
      margin: 0 0 20px 0;
      white-space: normal;
      word-break: break-all;
    }
  }
}
