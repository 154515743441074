.table {
  th,
  td {
    height: 60px;
    &:first-child {
      width: 180px;
      min-width: 180px;
    }

    &:not(:first-child) {
      text-align: center;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    &:not(:first-child) {
      width: 240px;
      min-width: 200px;
    }

    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }
}
