@import 'assets/styles/variables.scss';

.button {
  position: relative;
  & svg {
    position: absolute;
    top: -20px;
    right: 2px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 100%;
  }
}

div.tooltip {
  padding: 7px 10px;
  background: $main_color;
  border-radius: 8px;
  line-height: 10px;
  margin-left: 10px;
  white-space: nowrap;
  & > span {
    font-weight: 500;
    font-size: 12px;
    color: white;
  }
}
