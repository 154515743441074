@import 'assets/styles/variables.scss';

.menu {
  width: 200px;
  & > ul {
    & > li {
      &:last-child {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.pageContainer {
  font-family: $monserrat;
  padding: 60px 50px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding-bottom: 10px;
    margin: 0 0 40px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .start_button,
    .add_button {
      width: 150px;
      height: 30px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      font-family: $monserrat;
      box-shadow: $shadow;
      &:hover {
        box-shadow: $shadow_hover;
      }
    }

    .start_button {
      background: $extra_color;
      color: black;
      &__disabled {
        background-color: #e0e0e0 !important;
        color: #bdbdbd !important;
      }
    }
    .add_button {
      background: $main_color;
      color: white;
    }
  }

  button.actions {
    border-radius: 50%;
    background: #c9c9c9;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    color: white;
    padding: 0;

    &:hover {
      background: #9a9a9a;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .formWrapper {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .tableContainer {
    border-radius: 8px;
    margin-bottom: 20px;

    table {
      border-collapse: initial;
      height: fit-content;
    }

    tr {
      height: 100%;
    }

    th {
      padding: 8px 10px !important;
      white-space: nowrap;

      &.centered {
        text-align: center;
      }
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
    }

    .checkboxCell {
      width: 45px;
      min-width: 45px;
      padding: 0;
      vertical-align: middle;
      text-align: center;
    }
    .idCell {
      width: 10%;
      min-width: 110px;
      padding: 8px 10px;
      white-space: break-spaces;
      word-break: break-word;

      .pencil {
        border: none;
        background: none;
        cursor: pointer;
        font-family: $monserrat;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        outline: none;
        svg {
          color: black;
          width: 20px;
          height: 20px;
        }
      }
    }
    .notesCell {
      width: 20%;
      min-width: 145px;
      padding: 0;
    }
    .locationCell {
      width: 15%;
      min-width: 150px;
      padding: 0;
    }
    .sublocationCell {
      width: 15%;
      min-width: 150px;
      padding: 0;
    }
    .statusCell {
      width: 15%;
      min-width: 200px;
      padding: 0;
    }
    .freightBillCell {
      width: 5%;
      min-width: 100px;
      padding: 8px 10px !important;
    }
    .truckCell {
      width: 15%;
      min-width: 175px;
      padding: 0;
    }
    .lastCell {
      width: 45px;
      min-width: 45px;
      padding: 0;
      .imageBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .emptyResponseTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }
  }

  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &__rihghtMargin {
      margin-right: 20px;
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .notesBlock,
  .locationBlock,
  .truckBlock,
  .sublocationBlock,
  .statusBlock {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 16px;
    .helpers {
      display: flex;

      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;

        &:first-child {
          margin-right: 5px;
        }

        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }
    .alert {
      color: #fff;
      background: $alert_color;
    }
    .success {
      color: #fff;
      background: $regular_color;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .cursor_default {
    cursor: default;
  }

  .notesBlock {
    white-space: break-spaces;
    word-break: break-word;
    & > div {
      display: flex;

      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;

        &:first-child {
          margin-right: 5px;
        }

        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }

    .textInput {
      background: #f7f7f7;

      fieldset {
        border: none;
      }
      input,
      textarea {
        font-family: $monserrat;
        font-weight: 400;
        padding: 8px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;

        &::placeholder {
          opacity: 1;
          color: #000;
        }
      }
      input {
        padding: 3px 5px;
      }
    }

    & > div:first-child {
      & > div {
        width: auto !important;
        border: 1px solid #999;
        padding: 2px !important;
        font-size: 14px;
        border-radius: 8px;
        height: 34.625px;
      }
      & > svg {
        right: 5px;
      }
    }
  }
  .locationBlock,
  .sublocationBlock {
    white-space: break-spaces;
    word-break: break-word;
    height: 100%;
    box-sizing: border-box;
    & > div {
      width: 100%;
    }
  }
  .truckBlock {
    & > div {
      & > div {
        & > div {
          width: 100%;
        }
      }
    }
  }
  .statusBlock {
    & > div:first-child {
      width: 100%;
      & > div {
        width: 100% !important;
        font-size: 14px;
        border-radius: 8px;
        & > div {
          width: 100% !important;
          border-color: #999;
          line-height: 20px;
        }
      }
      & > svg {
        right: 5px;
      }
    }
  }

  .tableHelpers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .delete_button,
    .create_bol_button {
      background: $main_color;
      color: white;
      width: 150px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      font-family: $monserrat;
      box-shadow: $shadow;
      &:hover {
        box-shadow: $shadow_hover;
      }
    }

    &__controls {
      display: flex;
      .create_bol_button {
        width: 200px;
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;
    height: 100vh;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 20px 0;
      white-space: normal;
      word-break: break-all;
    }

    .headerHelpers {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
    }

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      z-index: 1;
      margin: 0;

      &__controls {
        display: flex;
        position: fixed;
        right: 20px;
        top: 20px;
      }
    }

    .controls {
      flex-direction: column;
      margin-bottom: 20px;
      row-gap: 20px;

      .start_button,
      .add_button {
        height: 40px;
        width: 100%;
      }
    }

    .formWrapper {
      .formRow {
        & > label {
          font-weight: normal;
          min-width: 150px;
          max-width: 150px;
        }
        & > span {
          font-weight: bold;
          margin-left: 0;
          font-size: 12px;
        }
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      overflow-y: hidden;

      .checkboxCell {
        min-width: 50px;
      }
      .idCell {
        min-width: 150px;
      }
      .notesCell {
        min-width: 180px;
      }
      .locationCell {
        min-width: 180px;
      }
      .sublocationCell {
        min-width: 180px;
      }
      .statusCell {
        min-width: 200px;
      }
      .truckCell {
        min-width: 180px;
      }
      .lastCell {
        min-width: 50px;
      }
    }

    .tableHelpers {
      flex-direction: column;
      row-gap: 10px;

      &__controls {
        flex-direction: column;
        row-gap: 10px;
        width: 100%;

        .delete_button,
        .create_bol_button {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}
