@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 40px;
  .details {
    display: flex;
    column-gap: 30px;
    &__column {
      flex-basis: 48%;
      box-sizing: border-box;
      & > div {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        & > label {
          max-width: 50%;
          white-space: normal;
          word-wrap: break-word;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .details {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
