@import 'assets/styles/variables.scss';

.menu {
  width: 346px;
  border-radius: 8px;
  margin-top: 10px;

  ul {
    padding: 0;
    li {
      font-family: $monserrat;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      padding: 6px 10px;

      svg,
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: scale-down;
        margin-left: 3px;
      }
    }
  }
}
