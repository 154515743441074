@import 'assets/styles/variables.scss';

.wrapper {
  .general {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 40px;

    &__row {
      flex-basis: 48%;
      &_checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        & > label {
          font-style: normal;
          font-size: 14px;
          color: #000000;
          font-family: $monserrat;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .general {
      flex-wrap: wrap;
      row-gap: 20px;
      &__row {
        flex-basis: 100%;
      }
      .last {
        order: 1;
      }
    }
  }
}
