@import 'assets/styles/variables.scss';

.templateWrapper {
  width: 100%;
  padding: 15px 0;
  border-radius: 8px;
  box-sizing: border-box;
  background: #fff;

  &.active {
    padding: 15px 15px 15px 25px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  }

  &.inactive {
    border: 1px dotted #ccc;
    border-radius: 0;
    outline: 2px solid #fff;
  }

  &.lineTemplate {
    .inactiveTemplate__controls {
      top: -13px;
    }
    .dragIcon {
      margin-top: -10px;
    }
  }

  .activeTemplate {
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      & > div {
        width: 300px;
        margin-right: -300px;
      }

      svg {
        cursor: pointer;
        height: 17px;
        width: 17px;
      }

      .dragIcon {
        cursor: grab;
      }
    }

    &__body {
      .ticketBlock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        position: relative;
        & > div {
          margin-top: 0;
        }
        label {
          font-size: 14px;
          margin: 0 15px 0 50px;
          white-space: nowrap;
        }
        .validationError {
          width: 245px;
          bottom: 0;
        }
      }

      .checkboxWrapper {
        margin-right: 10px;
      }

      .textInput {
        & > div {
          padding: 5px !important;
          textarea {
            padding: 2px;
            line-height: 18px;
          }
        }
      }

      .uploadWrapper {
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &__image {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
          .imageWrapper {
            position: relative;
            height: fit-content;
            & > img {
              width: 100%;
              height: 100%;
              max-height: 250px;
              object-fit: contain;
            }
            &_clear {
              position: absolute;
              right: -10px;
              top: -10px;
              border-radius: 50%;
              background-color: white;
              border: 2px solid black;
              width: 23px;
              height: 23px;
              color: black;
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      .inputWrapper {
        position: relative;
        & > div {
          & > div {
            padding: 10px 10px 25px !important;
          }
        }
        &__counter {
          position: absolute;
          bottom: 8px;
          right: 8px;
          font-size: 10px;
          font-weight: 500;
        }
      }

      .constructor {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        &__separate {
          border-right: 1px solid #e0e0e0;
        }
        &__block {
          width: 48%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &_header {
            font-weight: 700;
            font-size: 18px;
            font-family: $monserrat;
            margin-bottom: 20px;
          }
          &_body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: calc(55px * 5);
            overflow-y: auto;
            margin-bottom: 20px;
          }
          &_footer {
            & > button {
              margin-right: 20px;
            }
            & > span {
              font-family: $monserrat;
              font-size: 18px;
            }
          }
        }

        &__field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px dotted transparent;
          border-radius: 8px;
          padding: 8px 10px 8px 0;
          background: #fff;
          box-sizing: border-box;
          cursor: default;
          &:hover {
            border-color: #ccc;
          }
          &_drag {
            width: 35px;
            height: 29px;
            cursor: grab;
            display: flex;
            align-items: center;
            justify-content: center;
            & > svg {
              width: 15px;
              height: 15px;
              padding: 0 10px;
            }
          }
          &_input {
            width: 430px;
          }
          &_delete {
            svg {
              width: 23px;
              height: 23px;
              color: black;
            }

            .disabled {
              opacity: 0.3;
              pointer-events: none;
            }
          }
        }
      }
    }

    .signature {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 20px;
        margin-bottom: 20px;
        width: calc(360px + 25px + 20px);
        &_field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 20px;
          & > div {
            width: 360px;
          }
        }
        &_delete {
          width: 25px;
          height: 25px;
          color: black;
        }
      }
      &__footer {
        & > button {
          margin-right: 20px;
        }
        & > span {
          font-family: $monserrat;
          font-size: 18px;
        }
      }
    }

    &__controls {
      border-top: 1px solid $main_color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      padding-top: 15px;

      & > div {
        display: flex;
        align-items: center;
        & > div {
          margin-right: 20px;
        }
      }

      & > button {
        margin: 0;
        height: 30px;
      }

      &.hidden {
        display: none;
      }
    }

    .optionsBlock {
      max-height: calc(52.5px * 5);
      overflow-y: auto;
    }

    .validationError {
      position: absolute;
      color: $error_color;
      line-height: 0;
      margin: 0;
      font-size: 10px;
      margin-bottom: -5px;
    }
  }

  .inactiveTemplate {
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
    &__controls {
      display: block;
      position: absolute;
      opacity: 0;
      top: -2px;
      right: 0;
      background: #fff;
      transition: opacity 0.1s linear;
      border-radius: 8px;
      padding: 0 15px;
      box-sizing: border-box;

      button {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        outline: none;
        background: none;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
        cursor: pointer;
      }

      button:not(:last-child) {
        margin-right: 20px;
      }

      svg {
        opacity: 0.6;
        transition: opacity 0.1s linear;
        &:hover {
          opacity: 1;
        }
      }
    }

    &__body {
      h1 {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0;

        &.templateTitle {
          margin-bottom: 20px;
        }
      }
      b {
        span {
          margin-right: 15px;
        }
      }
      section > p {
        margin: 20px 0 -5px 10px;
      }
      .textInput {
        margin-top: 15px;
        width: 100%;
      }
      .inactiveOptions {
        margin-top: 10px;
        padding-left: 25px;
        p {
          display: flex;
          align-items: center;
          padding-top: 10px;
          svg {
            height: 20px;
            width: 20px;
            margin-right: 30px;
          }
        }
      }
      .inactiveSignature {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &__field {
          & > label {
            font-size: 16px;
            font-family: $monserrat;
            white-space: normal;
            word-break: break-word;
            display: block;
          }
          & > div {
            margin-top: 10px;
            width: 360px;
          }
        }
      }
      .inactiveCustomTable {
        margin-top: 20px;
        &__table {
          border-radius: 8px;
          overflow-x: auto;

          table {
            border-spacing: 10px;
            border-collapse: separate;
          }

          th,
          td {
            border: none;
            background-color: white !important;
            height: 40px;
            white-space: normal;
            word-break: break-word;
            text-align: center;
            max-width: 0;
            min-width: 150px;
            &:first-child {
              width: 150px;
            }
          }

          th {
            padding: 0;
          }

          td {
            padding: 0;
            &:nth-child(1) {
              width: 150px;
            }
            & > div {
              margin-top: 0;
              height: 100%;
              & > div {
                height: 100%;
                & > div {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      hr {
        border-top: 1px solid $main_color;
        border-color: $main_color;
        background-color: $main_color;
        color: $main_color;
        border-bottom: none;
      }
      .uploadWrapper {
        &:has(label) {
          .uploadWrapper__image {
            margin-bottom: 15px;
          }
        }
        &__image {
          display: flex;
          justify-content: center;
          align-items: center;
          & > img {
            width: 100%;
            height: 100%;
            max-height: 250px;
            object-fit: contain;
          }
        }
        & > label {
          display: block;
          margin: 0;
          font-size: 14px;
          white-space: pre-line;
        }
      }
      .textareaWrapper {
        display: flex;
        flex-direction: column;

        b {
          margin-bottom: 10px;
          display: flex;
          white-space: pre-line;
        }

        .multiTextInput {
          background: #f7f7f7;
          border-radius: 8px;
          position: relative;
          width: 100%;
          margin: 0;

          fieldset {
            border: none;
          }

          & > span {
            position: absolute;
            bottom: 8px;
            right: 8px;
            font-size: 10px;
            font-weight: 500;
          }
        }
        .textTemplateInput {
          & > div {
            & > div {
              padding: 5px !important;
              textarea {
                height: 14px;
                overflow: hidden;
              }
            }
          }
        }
      }
      .templateSubtext {
        display: flex;
        margin: 10px 0;
      }
      .formCell {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 15px;
        width: 427px;
        line-height: 34px;
        label {
          margin: 0;
          font-size: 14px;
        }
        .textInput {
          width: 250px !important;
          margin: 0;
        }
        input {
          min-height: 22px;
        }
      }
    }

    .dragIcon {
      opacity: 0;
      position: absolute;
      width: 99%;
      display: flex;
      justify-content: center;
      transition: opacity 0.1s linear;

      svg {
        height: 17px;
        width: 17px;
        padding: 2px 15px;
        cursor: grab;
        background: #fff;
      }
    }

    .hideControls {
      display: none;
    }
  }

  .selectOptions {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    &__option {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: default;
      padding: 8px 0;
      background: #fff;
      border: 1px dotted transparent;
      border-radius: 8px;
      &:hover {
        border-color: #ccc;
      }

      .dragIcon {
        padding: 0 10px;
        box-sizing: border-box;
        cursor: grab;
        width: 35px;
        height: 29px;
      }

      .selectIcon {
        color: $main_color;
        margin: 0 10px 0 5px;
      }

      .ticketBlock {
        margin-top: 0;
        label {
          font-size: 14px;
          margin: 0 15px;
          white-space: nowrap;
        }
      }

      .deleteIcon {
        margin: 0 10px 0 15px;
        cursor: pointer;
      }
    }

    &__addButton {
      margin-top: 5px;
      button {
        height: 32px;
        min-width: 32px;
        width: 32px;
        font-size: 28px;
        margin: 0 20px 0 0;
      }
      & > span {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  &:hover {
    .inactiveTemplate {
      &__controls,
      .dragIcon {
        opacity: 1;
      }
    }
  }

  .textInput {
    background: #f7f7f7;
    border-radius: 8px;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;

    & > div {
      & > div {
        padding: 10px 10px 25px !important;
      }
    }

    &.error {
      border-color: $error_color;
    }

    fieldset {
      border: none;
    }
    input,
    textarea {
      font-family: $monserrat;
      font-weight: 400;
      padding: 0 0 8px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;

      &::placeholder {
        opacity: 1;
        color: #ccc;
      }
    }
    input {
      padding: 4px;
    }
  }

  .textTemplateInput {
    & > div {
      padding: 10px !important;
      textarea {
        height: 14px;
        overflow: hidden;
      }
    }
  }

  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}
