@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
  }
  & > div {
    & > div {
      transition: none;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
    & > div {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
  }
}
