@import 'assets/styles/variables.scss';

.wrapper {
  .form {
    margin-bottom: 60px;

    &__row,
    &__selector {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > label {
        font-size: 14px;
        font-family: $monserrat;
      }
    }

    .fields {
      margin-left: 177px;
    }

    &__row {
      width: 427px;
      margin-bottom: 20px;
      &:has(label[id='photo_label']) {
        align-items: flex-start;
        & > label {
          line-height: 33px;
        }
      }
    }
    &__selector {
      width: 727px;
      margin-bottom: 30px;

      &.shorten {
        width: 597px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      width: 427px;

      & > label {
        margin: 10px 0 25px;
        font-size: 14px;
      }
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-family: $monserrat;
    margin: 0 0 25px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .form {
      margin-bottom: 0;

      .fields {
        margin-left: 0;
      }

      &__row,
      &__selector {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        &.shorten {
          width: 100%;
        }

        & > label {
          margin-bottom: 14px;
        }
      }
      &__description {
        margin-top: 10px;
        width: 100%;

        label {
          margin: 0 0 14px;
        }
      }
    }
  }
}
