@import 'assets/styles/variables.scss';

.wrapper {
  width: 550px;
  min-height: 30px;
  padding: 40px 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .form {
    margin-bottom: 40px;
    &__input {
      display: flex;
      flex-direction: column;
      & > label {
        font-size: 14px;
        font-family: $monserrat;
        margin-bottom: 10px;
      }
      &_field {
        width: 100%;
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: auto;
    row-gap: 20px;
    button {
      margin: 0;
      width: 180px;
      min-width: 180px;
      &:last-child {
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    h2 {
      margin: 0 0 20px;
    }

    .footer {
      column-gap: 20px;
      & > button {
        width: 46% !important;
        min-width: 46% !important;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
