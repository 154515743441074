@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 30px 60px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .header {
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0 0 50px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px;
  }

  .formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    width: 427px;

    & > label {
      margin: 0;
      font-size: 14px;
    }
  }

  .formTextarea {
    width: 427px;
    margin-bottom: 40px;
  }

  .footer {
    margin: auto 0 0;
    button {
      margin-right: 25px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    font-family: $monserrat;
    padding: 80px 20px 20px;
    height: 100vh;

    .header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 10px 20px 0;
      background: #fff;
      z-index: 5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      h1 {
        margin: 0 !important;
        padding-left: 40px;
        line-height: 40px;
      }
    }

    .formRow {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      & > label {
        margin: 0 0 10px;
      }
    }

    .formTextarea {
      width: 100%;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
}
