@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px;
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
    &__controls {
      display: flex;
      align-items: center;
    }
  }

  .footer {
    margin: 40px 0 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
    column-gap: 25px;

    & > button {
      margin: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    position: relative;
    .header {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      padding: 10px 20px 10px;
      background: #fff;
      box-sizing: border-box;
      z-index: 1;
      margin: 0;

      h1 {
        padding-left: 40px;
        line-height: 40px;
        margin: 0;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 0 0;
      & > button {
        width: 50%;
        min-width: 40%;

        &:only-child {
          width: 100%;
        }
      }
    }
  }
}
