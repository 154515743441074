@import 'assets/styles/variables.scss';

.dialog {
  width: 800px;
  height: 800px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .content {
    .form {
      width: 370px;
      margin-bottom: 20px;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & > label {
          font: {
            family: $monserrat;
            style: normal;
            weight: normal;
            size: 14px;
          }
        }
      }
    }

    .signatureWrapper {
      margin-bottom: 15px;
      height: 425px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 160px !important;
      min-width: 160px !important;
      height: 40px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;
    .content {
      width: 100%;
      .form {
        width: 100%;
        &__item {
          flex-direction: column;
          justify-content: normal;
          align-items: flex-start;

          & > label {
            margin-bottom: 10px;
          }
        }
      }

      .signatureWrapper {
        margin-bottom: 40px;
      }
    }

    .footer {
      & > button {
        width: 49% !important;
        min-width: 49% !important;
      }
    }
  }
}
