@import 'assets/styles/variables.scss';

button.addColumnButton {
  width: 150px;
  height: 30px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}
