@import 'assets/styles/variables.scss';

.modal {
  width: 460px;
  font-family: $monserrat;
  color: black;
  font-size: 18px;

  @media screen and (max-width: $breakpoint-443) {
    width: 100%;
  }

  &__header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &__content {
    word-break: break-word;
    text-align: center;
    margin-top: 30px;
  }
}
