@import 'assets/styles/variables.scss';

.entities {
  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    row-gap: 20px;

    &.separate {
      border-bottom: 1px solid #507a8e;
      padding-bottom: 35px;
    }

    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 427px;
      & > label {
        font-size: 14px;
      }
      &.wider {
        width: 597px;
      }
      .special {
        width: 420px;
      }
      &.larger {
        width: 722px;
      }
      .inputs {
        display: flex;
        justify-content: space-between;
        position: relative;
        &__text {
          width: 420px;
        }
        .tip {
          position: absolute;
          bottom: -16px;
          font: {
            size: 10px;
            family: $monserrat;
          }
        }
      }
    }
  }
}

.field {
  display: grid;
  grid-template-columns: 160px 1fr 2fr;
  align-items: flex-start;
  column-gap: 20px;
  width: 810px;
  margin-bottom: 10px;

  &__chip {
    max-width: 100%;
  }

  &__quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;

    & > label {
      font-size: 14px;
      font-family: $monserrat;
      margin-right: 10px;
      white-space: nowrap;
    }
    .small {
      width: 60px;
      & > span {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__resources {
    display: flex;
    flex-direction: column;
    width: 350px;
    row-gap: 20px;
    .images,
    .files {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      & > label {
        line-height: 32px;
        font-size: 14px;
        font-family: $monserrat;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .entities {
    width: 100%;

    &__block {
      width: 100%;

      &_row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        & > label {
          margin-bottom: 14px;
        }
        &.wider {
          width: 100%;
        }
        &.larger {
          width: 100%;
        }
        .special {
          width: 100%;
        }

        .inputs {
          width: 100%;
          &__text {
            width: 100%;
          }
        }
      }
    }
  }

  .field {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    row-gap: 20px;

    &__chip {
      max-width: calc(100% - 100px - 20px);
    }

    &__quantity {
      width: auto;
      & > label {
        display: none;
      }
      .small {
        width: 100px;
      }
    }

    &__resources {
      width: 100%;
      .images,
      .files {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        & > label {
          line-height: normal;
          margin-bottom: 10px;
        }
      }
    }
  }
}
