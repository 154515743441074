@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 60px 50px 40px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);
  height: auto;
}

.subtitle {
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 20px;
}

.description {
  margin-bottom: 40px;
  & > label {
    word-break: break-word;
    font-size: 14px;
    white-space: pre-line;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    font-family: $monserrat;
    padding: 70px 20px 20px;
    min-height: 100vh;
  }

  .footer {
    button {
      width: 100%;
    }
  }
}
