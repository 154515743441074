@import 'assets/styles/variables.scss';

.popup {
  width: 480px;
  height: 515px;
  padding: 15px;
  box-sizing: border-box;
  font-family: $monserrat;

  .header {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    padding-bottom: 5px;

    &__close {
      width: 25px;
      height: 25px;
      svg {
        color: black;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 35px);
    text-align: center;

    & > canvas {
      border: 8px solid #79a5ba;
      border-radius: 20px;
    }

    & > button {
      position: absolute;
      z-index: 1000;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);

      transition: color 0.01s; // safari bug fix
      span {
        transition: color 0.01s; // safari bug fix
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 0;

    .header {
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 60px;
    }

    .content {
      height: calc(100% - 60px);
      padding: 30px 0 0;
      box-sizing: border-box;

      & > canvas {
        margin: 0 auto;
        width: calc(100% - 60px) !important;
        height: auto !important;
        max-width: 390px;
        max-height: 390px;
        box-sizing: border-box;
      }

      & > button {
        bottom: 25px;
      }
    }
  }
}
