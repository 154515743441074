@import 'assets/styles/variables.scss';

.formLabel {
  font-size: 14px;
  font-weight: 400;
  font-family: $monserrat;

  &.bold {
    font-weight: bold;
  }
}
