@import 'assets/styles/variables.scss';

.wrapper {
  @media screen and (max-width: $breakpoint-1180) {
    padding-top: 65px;
  }
}

.header {
  @media screen and (max-width: $breakpoint-1180) {
    height: auto;
  }
}
