@import 'assets/styles/variables.scss';

.sidebar_btn {
  border: 0.5px solid #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  cursor: pointer;
  background-color: $sidebar_background_color;

  &:hover {
    background-color: #ffd83c;
    border-color: transparent;
    color: #000000;

    & svg > path {
      fill: #000000;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
    & > svg {
      width: 100%;
      height: 100%;
      & > path {
        fill: #fff;
      }
    }
  }

  &__label {
    font-family: $monserrat;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
}
