@import 'assets/styles/variables.scss';

.errorBlock {
  & > span {
    white-space: normal;
    font-size: 12px;
    font-family: $monserrat;
    color: #ff0000;
    display: block;
    word-break: break-word;
  }
}
