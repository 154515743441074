@import 'assets/styles/variables.scss';

.header {
  margin: 0 0 -10px;
  max-width: calc(100% - 400px);
  .title {
    font-weight: 700;
    font-size: 24px;
    font-family: $monserrat;
    line-height: 30px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    font-family: $monserrat;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  & > div:first-child {
    margin-bottom: 0;
  }

  &__buttons {
    display: flex;
    column-gap: 20px;
    position: relative;
  }

  button.navigation__btn {
    width: auto;
    & > span:first-child {
      padding: 0 10px;
    }
  }

  label.navigation__hint {
    position: absolute;
    right: 0;
    bottom: -20px;
    color: $filters_secondary_color;
    font-weight: 500;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    margin: 0 0 30px;
    max-width: 100%;
  }

  .navigation {
    &__buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    button.navigation__btn {
      width: 100%;
      & > span:first-child {
        padding: 0;
      }
    }
  }
}
