@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

tr.team_member_row {
  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid $table_border_color;
    }
  }

  td.teamMemberCell {
    width: 20%;
    min-width: 200px;
  }
  td.numberCell {
    width: 10%;
    min-width: 140px;
  }
  td.departmentCell {
    width: 20%;
    min-width: 270px;
    white-space: normal;
    word-break: break-all;
  }
  td.readOnlyDepCell {
    width: 20%;
    min-width: 250px;
    white-space: normal;
    word-break: break-all;
  }
  td.capacityCell {
    width: 10%;
    min-width: 150px;
  }
  td.emailCell {
    width: 20%;
    min-width: 250px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.main_button {
    width: 100%;
  }

  .actions {
    width: 100%;
  }
}
