@import 'assets/styles/variables.scss';

.popup {
  width: 465px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .title {
    & > h2 {
      max-width: 100%;
    }
  }

  .content {
    margin-bottom: 60px;
    .form {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_name {
          width: 250px;
          font-size: 14px;
        }

        &.details {
          align-items: flex-start;
        }

        &.checkbox {
          width: 275px;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .content {
      .form {
        &__item {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 10px;
          height: auto;

          &_name {
            width: 100%;
          }

          &.checkbox {
            width: 100%;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            column-gap: 50px;
          }

          &.details {
            flex-direction: row;
            column-gap: 20px;
          }
        }
      }
    }
  }
}
