@import 'assets/styles/variables.scss';

.header {
  h1 {
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 70px;
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 600px;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 30px;
  user-select: none;
}
div.chip {
  font-family: $monserrat;
  background-color: #7aa5ba;
  border-radius: 8px;
  color: white;
  cursor: grab;

  & > span {
    padding: 6px 16px;
    text-overflow: initial;
  }

  & > svg {
    color: #fff;
    height: 16px;
  }
}

button.add_button,
button.delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  color: black;
  cursor: pointer;
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  label {
    font-family: $monserrat;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #000000;
    margin-left: 7px;
    cursor: pointer;
  }
}
button.add_button {
  background: $extra_color;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0 rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
  margin-top: 40px;
  &:hover {
    background: $extra_color;
    box-shadow: 0 2px 4px -1px rgb(0, 0, 0, 0.2), 0px 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
button.delete_button {
  width: 130px;
  height: 30px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: auto 0 0;
  button {
    &:first-child {
      margin-right: 25px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    border-bottom: none;
    position: fixed;
    height: 60px;
    padding: 10px 20px 0;
    top: 0;
    left: 0;
    background: #fff;
    width: 100vw;
    box-sizing: border-box;
    z-index: 1;
    margin: 0;
    h1 {
      padding-left: 40px;
      line-height: 40px;
      margin: 0;
    }
  }

  .chips {
    width: 100%;
  }
  div.chip {
    max-width: 100%;
  }

  button.delete_button {
    position: absolute;
    right: 0;
    top: -5px;
    width: 25px;
    min-width: 25px;
    label {
      display: none;
    }
  }

  .footer {
    justify-content: center;
    margin: auto 0 0;
    button {
      width: 50%;
      min-width: 40%;
    }
    button:first-child {
      margin: 0 30px 0 0 !important;
    }
    button:last-child {
      margin: 0 !important;
    }
  }
}
