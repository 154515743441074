@import "assets/styles/variables.scss";

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupRoot {
  z-index: 1301 !important;
}

.popupContainer {
  border-radius: 8px !important;
}

.popupTitle h2,
.popupText p {
  font-family: $monserrat;
  text-align: center;
}

.popupTitle {
  padding: 40px 40px 0 !important;
  h2 {
    font-weight: 500;
    font-size: 24px;
    padding-bottom: 16px;
    max-width: 340px;
    line-height: 34px;
    white-space: normal;
    word-break: break-word;
  }
}

.popupText {
  padding: 0 24px !important;
  p {
    color: #000;
    font-weight: 400;
  }
}

.popupControls {
  justify-content: center !important;
  align-items: center !important;
  margin: 16px 0 30px;
}

@media screen and (max-width: $breakpoint-1180) {
  .popupContainer {
    width: 100vw !important;
    margin: 0 !important;
    border-radius: 0 !important;

    .popupTitle {
      h2 {
        font-size: 20px;
      }
    }
  }

  .popupControls {
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 10px 30px;

    button {
      min-width: 100px;
    }
  }
}
