@import 'assets/styles/variables.scss';

.map_wrapper {
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100vw;
    height: 100vh;
  }

  .map {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    outline: none;

    // to hide leaflet link from right bottom corner
    div[class='leaflet-control-attribution leaflet-control'] {
      display: none;
    }

    // to control z-index for zoom controls
    div[class='leaflet-bottom leaflet-right'] {
      z-index: 15;
    }

    // to place zoom-controls
    div[class='leaflet-control-zoom leaflet-bar leaflet-control'] {
      margin-bottom: 30px;
      margin-right: 30px;
      @media screen and (max-width: $breakpoint-1180) {
        margin-bottom: 80px;
        margin-right: 15px;
        z-index: 18;
      }
    }
    div[class^='leaflet-pane'] {
      z-index: 0;
    }
  }
}

.legend {
  display: flex;
  column-gap: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: $shadow;
  padding: 10px;
  height: 40px;
  box-sizing: border-box;
  cursor: default;
  &__item {
    display: flex;
    align-items: center;
    column-gap: 5px;

    &_label {
      font-family: $monserrat;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
    &_icon {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sidebar {
  box-sizing: border-box;
  box-shadow: -1px 1px 2px rgba(60, 64, 67, 0.1), -2px 2px 6px 2px rgba(60, 64, 67, 0.05);
  z-index: 2;
  overflow-y: auto;
}

div.popup {
  margin-bottom: 0;
  div[class='leaflet-popup-content-wrapper'] {
    width: fit-content !important;
    border-radius: 8px;
    box-sizing: border-box;
    & > div {
      margin: 0;
    }
  }

  div[class='leaflet-popup-tip-container'] {
    display: none;
  }

  div[class^='leaflet-top'] {
    z-index: 0;
  }
  div[class^='leaflet-bottom'] {
    z-index: 0;
  }

  a[class='leaflet-popup-close-button'] {
    display: none;
  }
}
