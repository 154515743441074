@import 'assets/styles/variables.scss';

.wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    & > label {
      font-weight: 600;
      font-size: 20px;
      font-family: $monserrat;
    }

    &__controls {
      display: flex;
      column-gap: 20px;
    }
  }

  .table {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    th,
    td {
      @extend %table-cell-sizes;
    }

    th {
      white-space: nowrap;
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc !important;
      }
      &.edited {
        background-color: rgba(135, 150, 176, 0.45) !important;
      }
    }

    td.empty {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }
  }
}

%table-cell-sizes {
  &:nth-child(1) {
    width: 30%;
    min-width: 150px;
  }
  &:nth-child(2) {
    width: 15%;
    min-width: 150px;
  }
  &:nth-child(3) {
    width: 20%;
    min-width: 250px;
  }
  &:nth-child(4) {
    width: 15%;
    min-width: 140px;
  }
  &:nth-child(5) {
    width: 10%;
    min-width: 100px;
    text-align: center;
  }
  &:nth-child(6) {
    width: 10%;
    min-width: 100px;
    text-align: center;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .header {
      align-items: flex-start;
      flex-direction: column;
      justify-content: normal;
      margin-bottom: 20px;

      & > label {
        margin-bottom: 20px;
      }

      &__controls {
        flex-direction: column-reverse;
        row-gap: 20px;
        width: 100%;
        & > button {
          width: 100%;
        }
      }
    }

    .table {
      position: relative;
      left: -20px;
      width: 100vw;
    }
  }
}
