@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 40px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding: 0 0 10px;
    margin: 0 0 40px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 25px;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
    }

    &__controls {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: black;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    .descriptionBlock {
      font-size: 14px;
      margin: 0 0 30px;
      white-space: pre-line;
      word-break: break-all;
    }
    .filesBlock {
      h1 {
        margin: 0 0 15px;
        font-weight: 600;
        font-size: 18px;
      }
      .filesWrapper {
        overflow-y: auto;
        max-height: calc(20px * 5);
        width: 350px;
        margin: 0 0 30px;
        .fileName {
          cursor: pointer;
          font-size: 14px;
          margin: 0 0 10px;
        }
      }
    }

    .toggle {
      margin-bottom: 20px;

      button {
        min-width: 125px;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      z-index: 1;
      margin: 0;

      button {
        background: #f6f6f6;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
      }

      &__controls {
        position: fixed;
        right: 20px;
        top: 20px;

        svg {
          margin-right: 0;
        }
      }
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 20px 0 30px;
      white-space: normal;
      word-break: break-all;
    }

    .contentWrapper {
      .descriptionBlock {
        word-break: break-all;
      }
      .filesBlock {
        .filesWrapper {
          overflow-y: scroll;
          width: 100%;
        }
      }
      .tableContainer {
        position: relative;
        left: -20px;
        width: 100vw;
        .firstRow {
          min-width: 260px;
          box-sizing: border-box;
        }
        .lastRow {
          width: 100px !important;
          min-width: 100px !important;
          box-sizing: border-box;
        }
      }
    }
  }
}

@mixin table {
  .tableContainer {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    td {
      -webkit-text-size-adjust: 100%;
    }

    .firstRow {
      box-sizing: border-box;
    }

    .lastRow {
      min-width: 195px !important;
      width: 195px !important;
      box-sizing: border-box;
    }

    td:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    .emptyTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }
  }
}
