@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 60px 50px 40px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    font-family: $monserrat;
    padding: 60px 20px 20px;

    & > div[role='group'] {
      width: 100vw;
      position: relative;
      left: -20px;
    }
  }
}
