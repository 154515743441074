.tableWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .loose_item_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &.invalid {
        background-color: #f7e5e5 !important;
      }
      &.warning {
        background-color: #ffefd0 !important;
      }

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.nameCell {
        width: 30%;
        min-width: 290px;
        padding: 0 16px;
      }
      &.locationCell {
        width: 30%;
        min-width: 260px;
        padding: 0 2px 0 2px;
        &:not(:has(div)) {
          padding: 8px 16px;
        }
      }
      &.qtyCell {
        width: 10%;
        min-width: 120px;
        padding: 0 16px;
        cursor: default;
      }
      &.addQtyCell {
        width: 15%;
        min-width: 100px;
        padding: 0 16px;
      }
      &.baaCell {
        width: 15%;
        min-width: 110px;
        padding: 0 16px;
      }
      &.removeCell {
        width: 60px;
        min-width: 60px;
        padding: 0;

        button.removeButton {
          display: block;
          width: 35px;
          height: 35px;
          margin: 0 auto;
        }
      }
    }
  }
}

.select_input_style {
  & > div {
    max-width: 100%;
    width: 100%;
    & > div {
      max-width: 100%;
      width: 100%;
      background-color: transparent;
    }
  }
}
.quantity_input_style {
  width: 100%;
  & > div {
    background-color: transparent !important;
    & > div {
      & > input {
        padding: 0 !important;
      }
    }
  }
}
