@import 'assets/styles/variables.scss';

.inputField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 427px;

  & > label {
    margin: 0;
    font-size: 14px;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
}

.subtext {
  display: block;
  margin: 0;
  font-size: 14px;
  white-space: pre-line;
}

.textarea {
}

@media screen and (max-width: $breakpoint-1180) {
  .inputField {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & > label {
      margin-bottom: 10px;
    }
  }
  .title {
    margin-bottom: 20px;
  }
}
