$legend_height: 20px;

.charts {
  display: flex;
  justify-content: center;
  height: fit-content;
  &__block {
    width: 325px;
    height: calc(250px + $legend_height);
  }
}
