@import 'assets/styles/variables.scss';

label.radio {
  font-family: $monserrat;
  margin-left: 0;
  margin-right: 20px;
}

span.label {
  font: {
    family: $monserrat;
    size: 14px;
  }
  margin-left: 3px;
}

@media screen and (max-width: $breakpoint-1180) {
  .fieldset {
    width: 100%;
    & > div {
      row-gap: 20px;
      column-gap: 20px;
    }
  }

  label.radio {
    margin-right: 0;
    flex: 1;
    white-space: nowrap;

    // to overwrite styles from index.css (display: none)
    & > span {
      &:first-child {
        display: inline-flex !important;
      }
    }
  }
}
