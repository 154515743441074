@import 'assets/styles/variables.scss';

div.spinnerFullWindow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

  svg {
    color: $main_color;
  }
}
