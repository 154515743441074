@import 'assets/styles/variables.scss';

.filter {
  display: flex;
  z-index: 10;

  span.filter__button {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background-color: #f6f6f6;

    &_opened,
    &_opened:hover {
      background-color: $main_color;

      img {
        filter: invert(100%);
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;
  row-gap: 35px;
  padding: 30px 16px 20px;
  width: 900px;
}

.form {
  display: flex;
  justify-content: space-between;
  column-gap: 45px;
  &__block {
    display: flex;
    flex-direction: column;

    row-gap: 15px;
    &:first-child {
      flex-basis: 46%;
    }
    &:last-child {
      flex-basis: 54%;
    }
    &_input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      & > label {
        font-size: 14px;
        font-family: $monserrat;
        margin: 0;
        white-space: nowrap;
      }
    }
    &_label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: $monserrat;
      margin: 0;
      white-space: nowrap;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__controls {
    display: flex;
    button {
      width: 200px;
      min-width: 200px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
