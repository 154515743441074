@import 'assets/styles/variables.scss';

.footer {
  margin: auto 0 0;
  width: 180px;
  position: relative;

  & > span {
    position: absolute;
    bottom: -18px;
    left: 0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #e92d2d;
    font-family: $monserrat;
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .footer {
    width: 100%;

    & > button {
      width: 100%;
    }

    & > span {
      bottom: 50px;
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}
