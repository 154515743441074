@import 'assets/styles/variables.scss';

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .buttons {
    display: flex;
    column-gap: 20px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .actions {
    width: 100%;
    margin-top: auto;
    .buttons {
      width: 100%;
      & > button {
        width: 50%;
        min-width: 40%;
      }
    }
  }
}
