@import "assets/styles/variables.scss";

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;

  .pageLimitContainer {
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-size: 14px;

    span {
      margin-right: 16px;
    }
  }

  .paginationContainer {
    display: flex;
    align-items: center;

    .pageButton,
    .ellipsisButton {
      font-family: $monserrat;
      font-size: 12px;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      color: $main_color;

      &__number {
        color: #5e5e5e;
        margin: 0 8px;
      }

      &.active {
        color: #fff;
        background-color: $main_color;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
      }
    }

    .pageDivider {
      height: 8px;
      border-right: 1px solid #dcdcdc;
    }

    .ellipsisButton {
      cursor: default;
      margin: 0 8px;

      &:hover {
        background: none;
      }
    }
  }
}

div.selectInput {
  font-family: $monserrat;
  padding: 5px 10px;
  min-width: 20px;
  border-radius: 8px !important;

  & ~ fieldset {
    border: none;
    border-radius: 8px;
  }
}

li.selectInput__menu {
  font-family: $monserrat;
}

svg.selectInput__icon {
  color: #000;
}

.fixedPosition {
  position: fixed;
  padding: 5px 10px;
  border-radius: 8px;
  bottom: 10px;
  right: 25px;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .pageLimitContainer {
    margin-right: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .paginationWrapper {
    flex-direction: column;
    align-items: flex-end;

    .pageLimitContainer {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .paginationContainer {
      width: 100%;

      .pageButton,
      .ellipsisButton {
        width: 45px;
        height: 45px;

        &__number {
          margin: 0 auto;
        }
      }

      .ellipsisButton {
        margin: 0 auto;
      }
    }
  }
}
