@import 'assets/styles/variables.scss';

.picker {
}

@media screen and (max-width: $breakpoint-1180) {
  .picker {
    width: 100% !important;
  }
}
