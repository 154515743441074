.csvButton {
  width: 185px;
}

.statistics {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  &__dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &_info {
      display: flex;
      column-gap: 40px;
    }
  }
}
