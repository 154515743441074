@import 'assets/styles/variables.scss';

.popperStyles {
  min-width: 250px;
  max-width: 600px;
  width: auto !important;
  z-index: 1301;
}

div.autocomplete {
  width: 100%;
  max-width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.invalid {
    & > div {
      border-color: $error_color;
    }
  }

  & > div {
    border: 1px solid transparent;
    border-radius: 8px;
    background: #f7f7f7;
    min-height: $field_default_height;
    box-sizing: border-box;
  }

  div.inputRoot {
    padding: 0 44px 0 8px !important;
    font-family: $monserrat;

    span,
    input {
      font-family: $monserrat;
      color: #000;
    }

    input {
      padding: 8px 6px !important;
      font-size: 14px;
      min-height: $field_default_height;
      box-sizing: border-box;
      z-index: 1;
      &:focus {
        min-width: 80%;
      }
    }

    fieldset {
      border: none;
    }

    & > div[class='MuiAutocomplete-endAdornment'] {
      & > button:first-child {
        z-index: 2;
      }
    }
  }

  div.tag {
    font-family: $monserrat;
    border-radius: 8px;
    max-width: 150px;
    background: #7aa5ba;
    border: none;
    padding-right: 2px;
    max-height: 25px;

    span {
      color: #fff;
    }

    & > svg {
      color: #fff !important;
      width: 16px;
      height: 16px;
    }
  }

  button.popupIndicator {
    margin-right: 0 !important;
    padding-top: 3px !important;
  }
}

div.noOptions {
  font-family: $monserrat !important;
  color: #000;
  cursor: default;
}

div.tagWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  position: relative;

  & > span {
    font-size: 12px;
    width: 50px;
    white-space: nowrap;
    position: absolute;
    right: -55px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popperStyles {
    max-width: calc(100vw - 40px);
    width: auto !important;
  }
}
