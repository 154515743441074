@import 'assets/styles/variables.scss';

.buttonWrapper {
  width: 40px;
  display: flex;
  justify-content: center;
  .dotsButton {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    & > span {
      width: inherit;
      & > img {
        width: inherit;
        height: inherit;
      }
    }
    &:hover {
      background: transparent;
    }
  }
}

.mobilePopper {
  width: calc(100% - 40px) !important;
}

.mobileContainer {
  padding: 10px;
  border-radius: 8px !important;
  width: auto;
  min-width: calc((54px * 3) + 20px + 20px);
  max-width: calc((54px * 3) + 20px + 20px + 20px);
  max-height: calc((54px * 3) + 20px + 20px + 10px);
  overflow: auto;
  box-sizing: border-box;
}

.images {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;

  &__item {
    position: relative;
    &:hover {
      &_icon {
        opacity: 1;
      }
    }
    & > img {
      height: 54px;
      width: 54px;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }

    &_icon {
      opacity: 0;
      width: 54px;
      height: 54px;
      border-radius: 8px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      top: 0;
      cursor: pointer;
      background-color: hsla(0, 0%, 50.2%, 0.5);
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
}
