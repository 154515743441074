@import 'assets/styles/variables.scss';

.selectInput {
  max-width: 250px;
  width: 250px;
  height: 100%;
  position: relative;

  & > div {
    background: #f7f7f7;
    border: 1px solid transparent;
    border-radius: 8px;
    fieldset {
      border: none;
    }
  }
  &.invalid {
    & > div {
      border-color: $error_color;
    }
  }
  &.tableView {
    & > div {
      background: none;
    }
  }
  &.specialView {
    height: 34.625px !important;
    & > div {
      border: 1px solid #999 !important;
      font-size: 14px !important;
    }
  }

  .clear {
    position: absolute;
    top: 5px;
    right: 40px;
    width: 25px;
    height: 25px;
    svg {
      color: black;
      width: 20px;
      height: 20px;
    }
  }
}

.single_option {
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkbox_option {
  display: flex;
  width: 100%;

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inputRoot {
  padding: 0 44px 0 8px !important;
  font-family: $monserrat;

  &.clearable_input {
    padding: 0 65px 0 8px !important;
  }

  span,
  input {
    font-family: $monserrat;
    color: #000;
  }

  input {
    padding: 8px 6px !important;
    font-size: 14px;
    height: $field_default_height;
    box-sizing: border-box;
    min-width: 15px !important;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.popper {
  position: absolute !important;
  font-family: $monserrat;
  margin-top: 3px;
  min-width: 250px;
  width: auto !important;
  max-width: 600px;

  ul {
    max-height: 288px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    li {
      white-space: nowrap;
      font-family: $monserrat;
      overflow: hidden;
    }
  }
}

button.popupIndicator {
  margin-right: 0 !important;
  padding-top: 3px !important;
}

div.tag {
  font-family: $monserrat;
  border-radius: 8px;
  max-width: 130px;
  background: #7aa5ba;
  border: none;
  padding-right: 2px;
  max-height: 25px;

  span {
    color: #fff;
  }

  svg {
    color: #fff;
    width: 16px;
    height: 16px;
  }
}

div.noOptions {
  font-family: $monserrat;
  color: #000;
  cursor: default;
}

li.selectInput__menu {
  font-family: $monserrat;
  background: none !important;
}

svg.selectInput__icon {
  right: 10px;
  color: #000;
}

span.checkbox {
  margin-right: 20px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .selectInput {
    width: 100%;
    max-width: 100%;
  }

  .popper {
    max-width: calc(100vw - 40px);
  }

  li.selectInput__menu {
    word-break: break-word !important;
    white-space: normal !important;
  }
}
