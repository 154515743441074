@import 'assets/styles/variables.scss';

button.page_button,
button.ellipsis_button {
  font-family: $monserrat;
  font-size: 12px;
  width: 36px;
  height: 36px;
  border-radius: 8px;

  &.active {
    color: #fff;
    background-color: $main_color;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }
}

button.page_button {
  color: #5e5e5e;
  margin: 0 8px;
}

button.ellipsis_button {
  cursor: default;
  margin: 0 8px;
  pointer-events: none;
  color: $main_color;

  &:hover {
    background: none;
  }
}

div.page_divider {
  height: 8px;
  border-right: 1px solid #dcdcdc;
}

button.ellipsis_button + div.page_divider + button.page_button + div.page_divider {
  display: none;
}
