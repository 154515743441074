@import 'assets/styles/variables.scss';

%cell-sizes {
  &:nth-child(1) {
    width: 10%;
    min-width: 110px;
  }
  &:nth-child(2) {
    width: 10%;
    min-width: 100px;
  }
  &:nth-child(3) {
    width: 20%;
    min-width: 180px;
  }
  &:nth-child(4) {
    width: 10%;
    min-width: 80px;
  }
  &:nth-child(5) {
    width: 20%;
    min-width: 200px;
  }
  &:nth-child(6) {
    width: 15%;
    min-width: 160px;
  }
  &:nth-child(7) {
    width: 15%;
    min-width: 195px;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
  margin-bottom: 10px;
}

div.table {
  border-radius: 8px;
  overflow-x: initial;
  margin-bottom: 15px;
  table {
    border-collapse: initial;
  }

  th {
    white-space: nowrap;
  }
  th,
  td {
    height: 40px;
    @extend %cell-sizes;
  }
  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &:nth-child(3),
    &:nth-child(5) {
      white-space: normal;
      word-break: break-word;
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  td.empty {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  & > section {
    padding-top: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    width: 100vw;
    overflow-x: auto;
  }

  .footer {
    justify-content: flex-end;
    padding: 0 20px 0;
  }
}
