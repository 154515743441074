@import 'assets/styles/variables.scss';

.dotsButton {
  border-radius: 50% !important;
  background: #c9c9c9 !important;
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  color: white !important;
  padding: 0 !important;
  margin-left: 20px !important;
}

.mobileContainer {
  margin-top: 10px;
  padding: 15px 0;
  border-radius: 8px !important;

  ul {
    padding: 0;
    li {
      font-family: $monserrat;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      padding: 6px 10px;

      svg,
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: scale-down;
        margin-left: 3px;
      }
    }
  }
}
