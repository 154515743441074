@import 'assets/styles/variables.scss';

.option {
  display: flex;
  align-items: center;
  width: 100%;
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    width: 100%;
    & > span {
      font-size: 14px;
      font-family: $monserrat;
      margin-left: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 25px - 15px);
    }
    button.option__controls_edit {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 10px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
      color: #aebbc1;
      border-radius: 8px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .option {
    width: 100%;
    &__controls {
      max-width: calc(100% - 25px);
    }
  }
}
