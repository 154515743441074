@import 'assets/styles/variables.scss';

.layoutWrapper {
  position: relative;
  width: 250px;

  .inputLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding: 0 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    svg {
      height: 17px;
      width: 17px;
      margin-right: 6px;
    }
  }

  .deprecatedInput {
    cursor: not-allowed;
    opacity: 0.5;

    & ~ p {
      margin: 0;
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 5px;
      bottom: -14px;
      color: $error_color;
    }
  }
}

div.previewWrapper {
  position: relative;
  display: inline-block;
  width: calc((250px - 20px) / 3);

  .previewImage {
    height: 54px;
    width: 54px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }

  .previewImageLoading {
    opacity: 0.5;
  }

  div.previewImageSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 54px !important;
    height: 54px !important;

    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  column-gap: 10px;
  row-gap: 10px;
  user-select: none;

  &.topSpace {
    margin-top: 20px;
  }
}
.container__image {
  position: relative;
  display: flex;
  width: calc((100% - 20px) / 3);
  box-sizing: border-box;
  cursor: grab;
  touch-action: none;
  &_preview {
    height: 54px;
    width: 54px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
  &_controls {
    display: flex;
    flex-direction: column;
    width: calc(100% - 54px);
    button.edit_button,
    button.delete_button {
      border-radius: 8px;
      cursor: pointer;
      flex-basis: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: black;
        width: 100%;
        height: 100%;
      }
    }

    button.delete_button {
      & > span {
        width: 12px;
        height: 12px;
      }
    }
    button.edit_button {
      & > span {
        width: 10px;
        height: 10px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .layoutWrapper {
    width: 100%;
    .container {
      column-gap: 50px;
      row-gap: 30px;
      &__image {
        width: 135px;
        column-gap: 5px;
        &_preview {
          width: 100px;
          height: 100px;
        }
        &_controls {
          width: calc(100% - 100px);
          justify-content: space-between;

          button.edit_button,
          button.delete_button {
            flex-basis: 30px;
            height: 30px;
          }

          button.edit_button {
            & > span {
              width: 25px;
              height: 25px;
            }
          }
          button.delete_button {
            & > span {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  div.previewWrapper {
    width: 135px;
    .previewImage {
      height: 100px;
      width: 100px;
    }

    div.previewImageSpinner {
      width: 100px !important;
      height: 100px !important;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
