@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 30px 60px 20px;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
}

.header {
}

.flexible {
  display: flex;
  justify-content: space-between;
  width: 1000px;
}

.footer {
  margin: 80px 0 0;
  button {
    &:first-child {
      margin-right: 50px;
    }
  }
}

$mobile-padding-top: 80px;
@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: $mobile-padding-top 20px 20px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .header {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 5;
    padding: 10px 20px 0;
    background: #fff;
    h1 {
      font-weight: 700;
      font-size: 24px;
      padding-left: 40px;
      line-height: 40px;
      margin: 0;
    }
  }

  .flexible {
    flex-direction: column;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin: auto 0 0;
    button {
      width: 50%;
      min-width: 40%;
    }
    button:first-child {
      margin: 0 30px 0 0 !important;
    }
    button:last-child {
      margin: 0 !important;
    }
  }
}
