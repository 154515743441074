@import 'assets/styles/variables.scss';

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: relative;
  top: -40px;
  &__icon {
    & > svg {
      fill: $error_color;
    }
  }

  &__message {
    text-align: center;
    text-wrap: balance;
  }
}
