@import 'assets/styles/variables.scss';

%table-cell-sizes {
  &:nth-child(1) {
    width: 10%;
    min-width: 110px;
  }
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    width: 15%;
    min-width: 180px;
  }
  &:nth-child(5) {
    width: 45%;
    min-width: 200px;
  }
}

.form {
  width: 100%;
  margin-bottom: 40px;

  div.table {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    th {
      padding: 0 12px;
      line-height: 17px;
      height: 40px;
      @extend %table-cell-sizes;
    }

    td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      padding: 8px 12px;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      @extend %table-cell-sizes;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        padding: 0;
      }
    }

    .inputs {
      &_select {
        width: 100%;
        & > div {
          width: 100%;
          & > div {
            width: 100%;
            & > div {
              width: 100%;
              background-color: transparent;
            }
          }
        }
      }
      &_note {
        background: transparent;
        box-sizing: border-box;
        & > div {
          padding: 12px 14px;
        }
      }
      &_location {
        width: 100%;
        & > div {
          width: 100%;
          max-width: 100%;
          & > div {
            width: 100%;
            background-color: transparent;
          }
        }
      }
    }
  }
}
