@import 'assets/styles/variables.scss';

.users {
  display: flex;
  align-items: center;
  position: fixed;
  top: 85px;
  align-self: flex-end;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  &__circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: linear-gradient(135deg, #6a85b6, #bac8e0);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    margin-right: -7px;
    border: 2px solid white;
    font-family: $monserrat;
    cursor: default;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
	.users {
		top: 65px;
	}
}