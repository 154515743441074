@import 'assets/styles/variables.scss';

div.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.popupContainer {
  width: 550px;
  padding: 40px 80px;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.popupTitle h2,
.popupText p {
  font-family: $monserrat;
}

.popupTitle {
  padding: 0 !important;

  .headerTitle {
    margin-bottom: 30px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      max-width: 340px;
      line-height: 34px;
      text-align: left;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.popupText {
  padding: 0 !important;
  margin-left: 5px;
  overflow: visible !important;
  p {
    color: #000;
    font-weight: 400;
    margin: 0;
    line-height: 40px;
  }
}

.popupControls {
  margin: 20px -10px 0;
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  .addTableBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > button {
      height: 30px;
      width: 120px;
      min-width: 120px;
    }
  }

  .buttonsBlock {
    display: flex;
    margin-top: 30px;

    button {
      width: 180px;
      min-width: 180px;
    }
  }
}

.formColumn,
.formCell {
  display: flex;
}

.formColumn {
  flex-direction: column;
}

.formCell {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  label {
    max-width: 60%;
    position: relative;
    span {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
      line-height: 32px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .inputWrapper {
    position: relative;
    display: flex;

    & > span {
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 0;
      bottom: -14px;
      color: $error_color;
    }
  }

  input {
    width: 0;
    height: auto;
  }
}

.alignTop {
  align-items: flex-start;
  margin-bottom: 0;
  label {
    line-height: 32px;
  }
}

.checkbox {
  margin: 0 10px 0 0 !important;
  color: $main_color !important;
  position: static !important;
  width: 26px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
    width: 26px;
  }
  & span:last-child {
    display: none;
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.formControl {
  width: 100%;
}

.multiselect {
  width: 100%;
}

.sortControls {
  white-space: nowrap;
  .upText,
  .downText {
    font-family: $monserrat;
    margin-left: 22px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
  }
  .downText {
    margin-right: 15px;
  }

  .upButton,
  .downButton,
  .deleteButton {
    cursor: pointer;
    margin-left: 25px;
    opacity: 0.8;

    svg {
      font-size: 30px;
    }
  }

  .upButton {
    svg {
      transform: rotate(180deg);
    }
  }
  .downButton {
    margin-right: 5px;
  }

  .deleteButton {
    margin: 0 -30px 0 15px;
    opacity: 0.4;
    width: 30px;
    display: inline-block;
  }

  .disabled {
    cursor: default;
    color: #ccc;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popupContainer {
    position: fixed !important;
    margin: 0 !important;
    width: 100% !important;
    padding: 0 20px 20px;
    height: 100% !important;
    box-sizing: border-box;
    border-radius: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

  .newBlock {
    padding: 40px 0;
  }

  .popupTitle {
    position: fixed;
    padding-top: 10px;
    top: 0;
    background: #fff;
    height: 60px;
    box-sizing: border-box;
    z-index: 1;
    width: calc(100vw - 40px);
    display: flex;
    justify-content: flex-start;

    & > h2 {
      width: 100%;
    }

    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h2 {
        padding-left: 40px;
        line-height: 60px;
        margin: 0;
      }
    }
  }

  .popupText {
    margin-left: 0;
  }

  .formControl {
    width: 100%;
  }

  .formCell {
    margin-bottom: 30px;
    label {
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100%;
      span {
        max-width: 160px;
        word-break: break-all;
        white-space: normal;
        line-height: normal !important;
      }
    }
  }

  .upButton {
    margin-left: 0 !important;
  }

  .popupControls {
    & > button {
      width: 100% !important;
    }
  }

  .popupControls {
    display: flex;
    justify-content: space-between;
    margin: 0;
    button {
      margin: 0 !important;
      width: 50% !important;
      min-width: 40% !important;
    }
    button:first-child {
      margin-right: 30px !important;
    }

    .addTableBlock {
      flex-direction: column;

      button {
        width: 100% !important;
        margin: 15px 0 0 !important;
      }
    }
  }

  .sortControls {
    .downText {
      margin-right: 45px;
    }
    .deleteButton {
      margin: 0 0 0 15px;
    }
  }
}
