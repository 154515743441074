@import 'assets/styles/variables.scss';

.project_plate {
  width: 38px;
  height: 18px;
  line-height: 12px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 8px;
  font-size: 8px;
  font-family: $monserrat;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-weight: 500;
  min-width: 38px;
}

.assignee {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  &_field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      font-size: 14px;
      font-family: $monserrat;
      font-weight: 400;
      color: #000;
    }
    &:first-child {
      width: 600px;
    }
    &:last-child {
      width: calc(100% - 780px);
      min-width: 360px;
    }
  }

  .assignee_field_classes {
    width: 350px;
    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
}
.files {
  display: flex;
  flex-direction: column;
  margin: 25px 0 25px;
  &_field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 500px;

    & > label {
      line-height: 32px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
  }
}
.creator {
  display: flex;
  flex-direction: column;
  &_field {
    width: 620px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 20px;
    & > span {
      font-size: 14px;
      font-family: $monserrat;
      font-weight: 400;
      color: #000;
      width: 255px;
      min-width: 255px;
    }
    & > b {
      font-size: 12px;
    }
  }
}

.section_label {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px;
  white-space: pre-line;
  word-break: break-word;
}

.subtitle {
  font-size: 14px;
  font-family: $monserrat;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}

.title {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.tag_wrapper {
  position: absolute;
  right: 60px;
  top: 40px;
  z-index: 1;
  .save_tag {
    position: fixed;
    width: 60px;
    height: 25px;
    background: #ffffff;
    border: 0.5px solid #dcdcdc;
    border-radius: 8px;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    & > span {
      font-family: $monserrat;
      font-weight: 700;
      font-size: 12px;
      color: #8a8a8a;
    }
  }
}

button.download_button {
  width: 150px;
  height: 30px;
  border-radius: 6px;
  background-color: $main_color;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  font-family: $monserrat;
  line-height: 15px;
  box-shadow: $shadow;
  &:hover {
    box-shadow: $shadow_hover;
    background-color: $main_color;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    color: white;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .assignee {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    &_field {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      min-width: 100%;
      &:first-child {
        width: 100%;
        margin-bottom: 20px;
      }
      &:last-child {
        min-width: 100%;
        width: 100%;
      }
      & > span {
        margin-bottom: 10px;
      }
    }

    .assignee_field_classes {
      width: 100%;
    }
  }
  .files {
    &_field {
      flex-direction: column;
      width: 100%;
      & > label {
        margin-bottom: 10px;
      }
    }
  }
  .creator {
    width: 100%;
    &_field {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      & > span {
        margin-bottom: 10px;
      }
    }
  }
  .tag_wrapper {
    right: 70px;
    top: 2px;
  }
}
