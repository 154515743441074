@import 'assets/styles/variables.scss';

.aboveTable {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }
  .tableControls {
    display: flex;
    justify-content: flex-end;

    & button {
      min-width: 25px;
      height: 22px;
      padding: 0;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 15px;
      }
      &.menu {
        border-radius: 100%;
        background: #000;
        color: #fff;
        &:hover {
          background: #000;
        }
      }
    }
  }
}

.tooltip {
  &__content {
    width: 250px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
    border-radius: 8px;
    & button {
      padding: 5px 0;
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      margin-right: 0 !important;
      svg,
      img {
        margin-right: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
