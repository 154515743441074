.rowName {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none !important;
  cursor: default;
  &.underline {
    text-decoration: underline !important;
    cursor: pointer;
  }
  &.bold {
    font-weight: bold;
  }
}
