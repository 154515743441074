@import 'assets/styles/variables.scss';

body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInput-underline:before {
  content: '' !important;
  border: none !important;
}

.MuiInput-underline:after {
  content: '' !important;
  border: none !important;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  font-weight: 400;
  font-size: 10px;
  left: 0;
  bottom: -16px;
  color: #e92d2d;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0 !important;
}

div[role='tooltip'] {
  z-index: 1002;
}

button {
  transition: color 0.01s !important; // safari bug fix
  span {
    transition: color 0.01s !important; // safari bug fix
  }
}

svg.MuiSvgIcon-root {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: $breakpoint-1180) {
  .MuiRadio-root {
    display: none !important;
  }

  .MuiDialog-root {
    z-index: 20 !important;
  }
}
