@import 'assets/styles/variables.scss';

div.card {
  width: 325px;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(33, 33, 33, 0.35);
  cursor: pointer;
  border: {
    radius: 10px;
    left-width: 10px;
    left-style: solid;
  }

  &.error {
    border-left-color: #e17c7c;
  }
  &.success {
    border-left-color: #7ec37e;
  }
  &.warning {
    border-left-color: #f9ba3291;
  }

  div.snackbar {
    padding: 16px 22px;
    line-height: 14px;

    & > span {
      font-family: $monserrat;
      font-size: 12px;
      color: #212121;
      white-space: pre-line;
    }
  }
}
