@import 'assets/styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .flexed {
    display: flex;
    align-items: flex-start;
    column-gap: 100px;
    & > div.form__block_row {
      width: 427px;
    }

    &:has(div.form__block_checkbox) {
      column-gap: 227px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 10px;
    &_email,
    &_row,
    &_checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > label {
        font-family: $monserrat;
        font-size: 14px;
      }
    }
    &_row {
      width: 427px;
      &.specialWidth {
        width: 600px;
      }
    }
    &_email {
      width: 427px;
      & > b {
        width: 250px;
        white-space: nowrap;
      }
    }
    &_checkbox {
      width: 300px;
    }
  }
}

.toggleBar {
  border-radius: 8px 8px 0 0;
  height: 35px;
  width: fit-content;

  & > button {
    font-family: $monserrat;
    font-size: 18px;
    font-weight: 600;
    flex-grow: 1;
    padding: 5px 15px;
    border: none;
    color: #000;
    background: #f7f7f7;
    text-transform: none;

    &:first-child {
      border-radius: 8px 0 0 0;
    }
    &:last-child {
      border-radius: 0 8px 0 0;
    }
    &:only-child {
      border-radius: 8px 8px 0 0;
    }
  }

  button.selected,
  button.selected:hover {
    color: #fff;
    background: $main_color;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    margin-bottom: 40px;
    & > div[role='group'] {
      width: 100vw;
      position: relative;
      left: -20px;
    }

    .flexed {
      flex-direction: column;
      row-gap: 20px;
      & > div.form__block_row {
        width: 100%;
      }
    }

    &__block {
      &_row {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        & > label {
          margin-bottom: 10px;
        }
        &.specialWidth {
          width: 100%;
        }
      }
      &_email {
        flex-direction: row;
        align-items: baseline;
        width: 100%;
        max-width: 427px;
        column-gap: 40px;
        & > b {
          padding: 0;
          font-size: 12px;
          white-space: normal;
          word-break: break-word;
        }
        & > label {
          margin-bottom: 0;
          white-space: nowrap;
        }
      }
      &_checkbox {
        justify-content: flex-end;
        width: 100%;
        column-gap: 40px;
      }
    }
  }

  .toggleBar {
    width: 100%;
    & > button {
      font-size: 14px;
      flex-grow: 1;
    }
  }
}
