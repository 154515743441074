@import 'assets/styles/variables.scss';

.title {
  font: {
    family: $monserrat;
    style: normal;
    weight: 700;
    size: 24px;
  }
  line-height: 29px;
  margin: 40px 0 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #507a8e;
  user-select: none;
}

.accordion {
  & > div {
    box-shadow: none;
  }
  div.accordion__summary {
    padding: 0;
    min-height: auto;
    margin-bottom: 20px;
    border-bottom: 1px solid $main_color;
    & > div {
      margin: 0;
    }
    h2 {
      font: {
        family: $monserrat;
        style: normal;
        weight: 700;
        size: 24px;
      }
      width: 100%;
      line-height: 29px;
      margin: 0;
      padding-bottom: 15px;
    }
  }
  div.accordion__details {
    padding: 0;
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  padding: 5px;
  height: 30px;
  border-radius: 8px;

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .accordion {
    div.accordion__summary {
      & > div {
        align-items: center;
      }
      h2 {
        font-size: 20px;
        line-height: normal;
        padding-bottom: 5px;
        width: auto;
        box-sizing: border-box;
      }
      h2 + svg {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        color: #e92d2d;
      }
    }
    div.accordion__details {
      margin-bottom: 30px;
    }
  }
}
