@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  tr.assemble_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.assembleCell {
        width: 15%;
        min-width: 180px;
      }
      &.configurationCell {
        width: 15%;
        min-width: 160px;
      }
      &.locationCell {
        width: 15%;
        min-width: 160px;
      }
      &.statusCell {
        width: 10%;
        min-width: 140px;
      }
      &.createdAtCell {
        width: 15%;
        min-width: 195px;
      }
      &.createdByCell {
        width: 15%;
        min-width: 150px;
      }
    }
  }
}
