@import 'assets/styles/variables.scss';

.fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    & > label {
      font-weight: 500;
      font-size: 14px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      white-space: nowrap;
    }
    &_input {
      position: relative;
      .clear {
        position: absolute;
        right: -25px;
        top: 9px;
        .clearIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .fields {
    &__row {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & > label {
        margin-bottom: 10px;
        max-width: 100%;
      }

      &_input {
        width: calc(100% - 50px);

        .clear {
          right: -45px;

          .clearIcon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
