@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 50px;
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 20px;
  }
  .toggle {
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .toggle {
      display: flex;
      flex-wrap: wrap;
    }
    .createTicketButton {
      padding: 0 20px;
      margin-bottom: 10px;
      & > button {
        width: 100%;
      }
    }
  }
}
