@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 40px;
  .general {
    display: flex;
    column-gap: 30px;
    &__block {
      flex-basis: 48%;
      & > div {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &_checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        & > label {
          font-style: normal;
          font-size: 14px;
          color: #000000;
          font-family: $monserrat;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
  }

  .status__indicator {
    position: relative;
    margin-left: 5px;
    svg {
      top: -20px;
      right: -7px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .general {
      flex-direction: column;
      row-gap: 20px;
      &__block {
        flex-basis: 100%;
      }
    }
  }
}
