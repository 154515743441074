@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: space-between;

  .header {
    &__block {
      display: flex;
      justify-content: space-between;
      margin: 0 0 30px;

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .footer {
    margin: 20px 0 0;
    button:first-child {
      margin: 0 30px 0 0;
    }
    button:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 70px 20px 20px;
    min-height: 100vh;
    height: auto;
    .header {
      &__block {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 10px;
        background: #fff;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: none;
        margin: 0;

        h1 {
          margin: 0 !important;
          padding-left: 40px;
          line-height: 40px;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 0;

      button {
        width: 50%;
        min-width: 40%;
        &:first-child {
          margin: 0 30px 0 0 !important;
        }
        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }
}
