.charts {
  grid-area: pie;
  display: flex;
  justify-content: center;
  height: fit-content;
  &__item {
    width: 250px;
    height: 300px;
  }
}
