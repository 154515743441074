@import "assets/styles/variables.scss";

.host {
  display: flex;
  flex-direction: row;
  min-width: 1280px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .block {
    z-index: 1;
    justify-self: end;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    width: 0;
    padding: 20px 0;
    transform: translateX(-100vw);
    transition: all 0.3s ease-in-out;

    .logo {
      width: 150px;
      padding-bottom: 110px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.2s;
    }

    .formBlock {
      width: 400px;
      border-bottom: 1px solid #d5d8de;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.2s;

      & > label {
        font-weight: 700;
        font-size: 24px;
        font-family: $monserrat;
        color: #000;
        padding-bottom: 10px;
        display: block;
      }

      .formRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        & > label {
          font-family: $monserrat;
          font-weight: 400;
          font-size: 14px;
        }

        .inputWrapper {
          position: relative;

          & > span {
            position: absolute;
            font-weight: 400;
            font-size: 10px;
            left: 0;
            bottom: -16px;
            color: $error_color;
          }
        }
      }

      .subText {
        font-weight: 400;
        font-size: 10px;
        font-family: $monserrat;
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .controls {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }

  .splashBlock {
    width: 100%;
    background: #111 url("../../assets/images/login/splash.jpg") no-repeat;
    background-size: auto 130%;
    background-position: center;
    transition: background-image 0.1s ease-in-out;

    .splashLogoBlock {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/images/login/splashLogo.svg") no-repeat center center;
      transition: transform 0.3s ease-in-out, opacity 0.1s ease-in-out;
      z-index: 0;
    }
  }
}

.visible {
  .block {
    width: 50%;
    max-width: 600px;
    padding: 20px 95px;
    transform: translateX(0);

    .logo {
      padding-top: 60px;
      padding-bottom: 170px;
      opacity: 1;
    }

    .formBlock {
      opacity: 1;
    }
  }
  .splashBlock {
    width: 100%;

    .splashLogoBlock {
      transform: translateX(-500px);
      opacity: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .host {
    min-width: 100vw;

    .block {
      padding: 10px 40px;
      position: absolute;
      z-index: 5;

      .logo {
        padding-bottom: 100px;
        transition-delay: 0.3s;
      }

      .formBlock {
        width: auto;
        transition-delay: 0.3s;
      }

      .controls {
        button {
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .visible {
    .block {
      padding: 40px;
      width: 100vw;
      transform: translateX(0);
    }
    .splashBlock {
      width: 0px;
    }
  }
}
