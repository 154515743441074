a.link {
  text-decoration: none;
  cursor: default;
  color: black;
  &.underline {
    text-decoration: underline;
    cursor: pointer;
  }

  &.bold {
    font-weight: bold;
  }
}
