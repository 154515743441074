@import 'assets/styles/variables.scss';

.tag {
  font-family: $monserrat;
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #7aa5ba;
  color: #fff;
  border-radius: 8px;
  box-sizing: content-box;
  padding: 1px 2px 1px 8px;
  outline: 0;
  overflow: hidden;
  font-size: 0.8125rem;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    width: 12px;
    height: 12px;
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
}
