@import 'assets/styles/variables.scss';

button.show_popup_button {
  font-family: $monserrat;
  background-color: #f7f7f7;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  height: 40px;
  border-radius: 8px;
  box-shadow: $shadow;
  margin: 10px 0;

  &:hover {
    background-color: #f7f7f7;
  }
}

button.add_new_button {
  height: 34px;
  width: 110px;
  background-color: #ffd83c;
  padding: 6px;
  border-radius: 8px;
  margin-left: 15px;
  text-transform: initial;
  font: {
    weight: 500;
    size: 12px;
    family: $monserrat;
  }
  box-shadow: $shadow;
  &:hover {
    box-shadow: $shadow_hover;
    background-color: #ffd83c;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    color: black;
  }
  &.disabled {
    background-color: #e6e6e6;
    opacity: 0.5;
    pointer-events: none;
  }
}

button.add {
  height: 30px;
  width: fit-content;
  background: $main_color;
  border-radius: 8px;
  color: white;
  box-shadow: $shadow;
  padding: 0 16px;
  &:hover {
    background: $main_color;
    box-shadow: $shadow_hover;
  }
  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  label {
    font-family: $monserrat;
    font-size: 10px;
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}

button.transparent_button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  padding: 5px;
  height: 30px;
  border-radius: 8px;
  color: black;

  img,
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

button.blue_button {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  font-family: $monserrat;
  cursor: pointer;
  border-radius: 8px;
  line-height: 15px;
  background: $main_color;
  color: white;
  box-shadow: $shadow;
  height: 40px;
  &:hover {
    background: $main_color;
    box-shadow: $shadow_hover;
  }

  & svg,
  & img {
    margin-right: 10px;
  }
}

button.styled_button {
  font-family: $monserrat;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  height: 40px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  color: black;
  box-shadow: $shadow;
  &:hover {
    background: $main_color;
    box-shadow: $shadow_hover;
  }

  & > span:first-child {
    column-gap: 10px;
  }

  &.yellow {
    background-color: #ffd83c;
  }
  &.gray {
    background-color: #f6f6f6;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.csvButton,
.importCsvButton {
  width: 185px;
}

@media screen and (max-width: $breakpoint-1180) {
  button.blue_button {
    &.fullOnMobile {
      width: 100%;
    }
  }

  button.add_new_button {
    width: 33px;
    min-width: 33px;
    height: 33px;
    svg {
      font-size: 24px;
      margin-right: 0;
    }
  }

  button.add {
    width: 100%;
  }
}
