@import 'assets/styles/variables.scss';

.wrapper {
  width: 1100px;
  height: 70vh;
  padding: 15px;
  box-sizing: border-box;
  font-family: $monserrat;

  .header {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    padding-bottom: 5px;

    &__close {
      width: 25px;
      height: 25px;

      svg {
        color: black;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 35px);

    & > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #000;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    height: 100%;

    .header {
      height: 60px;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px 0 60px;
    }

    .content {
      height: calc(100% - 60px);
    }
  }
}
