@import 'assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 250px;
  box-sizing: border-box;
  padding: 15px;

  font-family: $monserrat;
  font-size: 14px;

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .label,
  .value,
  .link {
    color: black !important;

    &.clickable {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .label {
    font-weight: bold;
    white-space: nowrap;
  }

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pair {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
