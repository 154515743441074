@import 'assets/styles/variables.scss';

.formWrapper {
  position: relative;
  margin-bottom: 30px;
  font-family: $monserrat;
  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &_field {
      font-size: 14px;
      letter-spacing: 0.1px;
      position: relative;
      height: 30px;
      flex-basis: calc(100% - 100px - 20px);
      line-height: 30px;
      &.hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__input {
        background: #f7f7f7;
        border-radius: 8px;
        width: 100%;
        margin: 0;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        height: 30px;
        left: -7px;
        letter-spacing: 0.1px;
        & > div {
          height: 30px;
        }

        &.error {
          border-color: $error_color !important;
        }

        fieldset {
          border: none;
        }
        input {
          font-weight: 400;
          padding: 6px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          color: #000;
          font-family: $monserrat;

          &::placeholder {
            opacity: 1 !important;
            color: lightgray !important;
          }
        }
      }
      & > span {
        position: absolute;
        font-weight: 400;
        font-size: 10px;
        left: 0;
        bottom: -14px;
        color: $error_color;
        line-height: normal;
      }
    }
    &_controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100px;
      position: relative;

      &__edit,
      &__remove,
      &__fill,
      &__check,
      &__cancel {
        width: 20px;
        height: 20px;
        &:hover {
          background-color: transparent;
        }
      }
      &__edit {
        color: #b4b4b4;
        &:hover {
          color: #7e7e7e;
        }
      }
      &__remove {
        color: #b4b4b4;
        &:hover {
          color: #7e7e7e;
        }
      }
      &__check {
        color: #afcaa5;
        &:hover {
          color: #8cc577;
        }
      }
      &__cancel {
        color: #d79c9c;
        &:hover {
          color: #d77777;
        }
      }
    }
  }
}
