@import 'assets/styles/variables.scss';

.accordion {
  & > div {
    box-shadow: none;
  }
  div.accordion__summary {
    padding: 0;
    min-height: auto;

    & > div:first-child {
      margin: 0;
      width: calc(100% - 25px);
    }
    & > div:last-child {
      margin-right: 0;
    }

    div.accordion__content {
      display: flex;
      justify-content: space-between;
      margin: 0;
      line-height: 14px;
      column-gap: 10px;
      width: 100%;

      & > h2 {
        font: {
          family: $monserrat;
          style: normal;
          weight: 600;
          size: 16px;
        }
        width: 100%;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > a {
        margin-right: 10px;
        font-family: $monserrat;
      }
    }
  }

  div.accordion__details {
    margin-top: 15px;
    padding: 0 35px 0 0;

    & > div {
      width: 100%;
    }
  }
}
