@import 'assets/styles/variables.scss';

.commonCell {
  box-sizing: border-box;
  height: 20px;
  padding: 0 8px !important;
}

.checkbox {
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.checkboxHeadCell {
  width: 40px;
  min-width: 40px;
}
.typeHeadCell {
  min-width: 105px;
}
.nameHeadCell {
  min-width: 120px;
}
.quantityHeadCell {
  min-width: 75px;
  text-align: center !important;
}

.transportationHeadCell {
  text-align: center !important;
}
.transportationCostSubHeadCell {
  min-width: 75px;
  text-align: center !important;
}
.transportationTimelineSubHeadCell {
  min-width: 75px;
  text-align: center !important;
}

.purchasingHeadCell {
  text-align: center !important;
}
.purchasingCostSubHeadCell {
  min-width: 75px;
  text-align: center !important;
}
.purchasingTimelineSubHeadCell {
  min-width: 82px;
  text-align: center !important;
}

.qtySecondHeadCell {
  text-align: center !important;
}
.transportationQTYSubHeadCell {
  min-width: 90px;
  text-align: center !important;
}
.purchasingQTYSubHeadCell {
  min-width: 90px;
  text-align: center !important;
}

.etaHeadCell {
  min-width: 75px;
  text-align: center !important;
}

.statusHeadCell {
  min-width: 150px;
}

.notesHeadCell {
  min-width: 250px;
}

.fileHeadCell,
.picHeadCell {
  min-width: 60px;
  text-align: center !important;
}

.editHeadCell {
  min-width: 50px;
}
