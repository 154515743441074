@import 'assets/styles/variables.scss';

.textPopperBlock {
  padding: 15px 25px;
  width: 200px;
  position: relative;
  .crossButton {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 10px;
    line-height: 5px;
  }
  .popperHeader {
    margin-bottom: 20px;
    & > span {
      font-weight: bold;
      font-size: 14px;
      white-space: normal;
      word-break: break-word;
    }
  }
  .popperContent {
    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      & > label {
        font-size: 12px;
      }
      .infoBlock {
        width: 115px;
        margin-left: 20px;
        white-space: normal;
        word-break: break-all;
        & > span {
          font-weight: 600;
          font-size: 12px;
          display: flex;
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

td.memberCell,
td.projectCell,
td.initialCell,
td.capacityCell {
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 0;
  min-width: 190px;
  width: 190px;
}

td.memberCell {
  height: 30px;
  min-width: 300px;
  width: 300px;
  &.orangeHighlight {
    background-color: #fef2bf !important;
  }
  div.memberBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px;
    width: 275px;

    .firstBlock {
      display: flex;
      align-items: center;
      overflow: hidden;

      & > a {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .secondBlock {
      display: flex;
      align-items: center;
      column-gap: 5px;

      .doNotDrive {
        font-family: $monserrat;
        font-size: 12px;
        font-weight: 600;
        color: black;
      }

      button.info_button {
        width: 15px;
        height: 15px;

        svg {
          width: 15px;
          height: 14px;
          color: black;
        }
      }
    }
  }
}

td.projectCell {
  background-color: #7aa5ba !important;
  overflow: initial;
  div.projectBlock {
    min-width: 275px;
    height: 30px;
    padding: 0 7px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > span {
      color: white;
      position: sticky;
      left: 7px;
    }
  }
}

td.employmentCell {
  padding: 0;
  div.employmentBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 0;
    height: 30px;
    cursor: pointer;
    position: relative;
    & > span {
      letter-spacing: 0.01px;
      font-size: 11px;
      line-height: 0;
    }
  }
}

.described {
  &:after {
    content: ' ';
    border-top: solid 8px #ffd83b;
    border-left: solid 8px transparent;
    border-top-right-radius: 3px;
    border-right: solid 8px #ffd83b;
    border-bottom: solid 8px transparent;
    position: absolute;
    top: -1px;
    right: -1px;
  }
}

.contractSCABlock {
  background-color: $project_csca_color !important;
}

.notCurrentProject {
  color: #8a8a8a;
}

td.initialCell {
  z-index: 5;
  padding: 0 7px;
  color: #000;
  font-weight: bold;
  background-color: #e4edf1 !important;
}
td.totalCell {
  padding: 0 !important;
  background-color: #e4edf1 !important;
  .totalBlock {
    padding: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    & > span {
      color: #000;
      font-weight: bold;
    }
  }
}

td.capacityCell {
  z-index: 5;
  padding: 0 7px;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.biggerTopBorder {
  border-top: 3px solid #dcdcdc;
}

.countBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  .underline {
    text-decoration: underline;
  }
}
.pointer {
  cursor: pointer;
}
.popperStyle[x-out-of-boundaries] {
  opacity: 0;
  pointer-events: none;
}
.noPadding {
  padding: 0 !important;
}
