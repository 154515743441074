@import 'assets/styles/variables.scss';

.mainContainer {
  margin-bottom: 50px;

  &__item {
    font-family: $monserrat;
    font-size: 14px;
    color: #000 !important;

    b {
      font-family: $monserrat;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
