@import 'assets/styles/variables.scss';

.signatureLabel {
  display: block;
  font: {
    family: $monserrat;
    style: normal;
    weight: 400;
    size: 14px;
  }
  line-height: 17px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
.signature {
  width: 100%;
  height: calc(100% - 27px);
  position: relative;
  border: 1px solid #8a8a8a;
  border-radius: 8px;

  .pad {
    width: 100%;
    height: 100%;
  }
  .eraser {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px !important;
    background-color: white;
    &:hover {
      background-color: #e7e7e7;
    }
  }
}
