.section {
  display: grid;
  grid-template-areas:
    'levels bar'
    'availability pie';
  grid-auto-rows: 1fr auto;
  grid-template-columns: 1fr 550px;
  column-gap: 20px;
  row-gap: 20px;
}
