@import 'assets/styles/variables.scss';

.search {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  align-items: center;
  column-gap: 20px;
  box-sizing: border-box;
  padding: 0 18px;
  background-color: $sidebar_search_bar_background_color;
  position: sticky;
  top: 0;
  z-index: 1;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    button.search__icon_toggle {
      width: 25px;
      height: 25px;
      border-radius: 0;

      & svg {
        width: 100%;
        height: 100%;
        fill: $sidebar_search_field_main_color;
      }
    }
  }

  &__field {
    &_input {
      background: $sidebar_background_color;
      border-radius: 8px;
      width: 175px;
      height: 30px;

      input {
        font-family: $monserrat;
        font-weight: 400;
        padding: 8px 5px 8px 10px;
        font-size: 12px;
        line-height: 14px;

        &::placeholder {
          opacity: 1;
          color: $sidebar_search_field_main_color;
        }
      }

      .clear {
        width: 17px;
        height: 17px;
        margin-right: 0 !important;
        & svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;

    button.search__controls_btn {
      width: 18px;
      height: 18px;
      border-radius: 0;

      & svg {
        width: 18px;
        height: 18px;
        fill: $sidebar_search_field_main_color;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .search {
    .environment {
      position: absolute;
      bottom: -7px;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;

      &__title {
        font-size: 10px;
        font-family: $monserrat;
        font-weight: 600;
        color: red;
        background: white;
        padding: 0 10px;
        border-radius: 3px;
        border: 1px solid red;
        height: 15px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }
}
