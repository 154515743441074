@import 'assets/styles/variables.scss';

.modal {
  width: 420px;
  font-family: $monserrat;
  color: black;
  font-size: 18px;
  &__header {
    text-align: center;
    margin-bottom: 15px;
  }
  &__content {
    word-break: break-word;
    &_label {
      margin-bottom: 15px;
      & > b {
        display: block;
        text-align: center;
      }
    }
  }
  &__footer {
    text-align: center;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .modal {
    width: 100%;

    &__footer {
      text-align: center;
    }
  }
}
