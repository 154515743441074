@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 40px 60px;

  .title {
    font-size: 24px;
    font-family: $monserrat;
    font-weight: 700;
    margin: 0;
  }

  .panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 25px;
  }
}

.card_icon,
.reports_icon {
  width: 100%;
  height: 100%;
}

.card_icon {
  path {
    fill: $card_text_color;
  }
}

.reports_icon {
  path {
    fill: $card_text_color;

    &:nth-child(1),
    &:nth-child(2) {
      fill: transparent;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 60px 20px 20px;

    .title {
      margin: 0;
    }
  }

  .header {
    height: 60px;
  }
}
