@import 'assets/styles/variables.scss';

.dialog {
  width: 660px;
  min-height: 500px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow: auto;

  .title {
    & > h2 {
      max-width: 100%;
    }
  }

  .form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__row {
      width: 427px;
    }

    &__assets {
      width: 100%;

      &_field {
        width: 100%;
      }
    }

    &__qty {
      width: 340px;
    }
  }

  .inputs {
    &__qty {
      width: 80px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 200px !important;
      min-width: 200px !important;
      height: 40px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
