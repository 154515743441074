@import 'assets/styles/variables.scss';

div.chips {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  box-sizing: border-box;

  div.chips__item {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    max-width: 100%;
    height: 34px;

    & > span {
      padding: 6px 16px;
      font-family: $monserrat;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

.flexColumn {
  flex-direction: column;
}
