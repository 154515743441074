@import 'assets/styles/variables.scss';

.form {
  & > h2 {
    font-family: $monserrat;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 25px 0;
  }
  .general {
    margin-bottom: 30px;
    &__block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 100px;
      width: 960px;
      &_row,
      &_selector,
      &_checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > label {
          font-family: $monserrat;
          font-size: 14px;
        }
      }

      &_row {
        width: 427px;
        &:last-child {
          align-items: baseline;
        }
      }
      &_checkbox {
        width: 317px;
        height: fit-content;
      }
      &_selector {
        width: 877px;
        margin-bottom: 30px;
        & > div {
          width: 700px;
        }
      }
    }
  }
  .description {
    width: 427px;
    margin-bottom: 30px;
  }

  .subcategories {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__item {
      position: relative;
    }
    &__clear {
      position: absolute;
      right: -25px;
      top: 8px;
      svg {
        width: 15px;
        height: 15px;
        color: black;
      }
    }
    &__add {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-radius: 8px;
      background-color: #507a8e;
      box-shadow: $shadow;
      svg {
        color: white;
      }

      &:hover {
        background-color: #507a8e;
        box-shadow: $shadow_hover;
      }

      &:disabled {
        background: #ccc;
        pointer-events: none;
      }
    }
  }

  .table {
    box-sizing: border-box;
    width: 860px;

    th,
    td {
      padding: 5px 0;
      background-color: transparent !important;
      border: none;
      &:nth-child(1) {
        width: 70%;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 180px;
        text-align: center;
      }
      &:nth-child(3) {
        width: 15%;
        min-width: 220px;
        text-align: center;
      }
    }
  }
}

.is_numeric {
  display: flex;
  align-items: center;
  margin-left: 30px;
  & > label {
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    font-family: $monserrat;
    margin: 0 15px 0 0;
  }
}

div.redChip:focus {
  background-color: #ba7a7a;
}
div.blueChip:focus {
  background-color: #7aa5ba;
}

div.chip {
  background-color: #7aa5ba;
  border-radius: 8px;
  color: white;
  margin: 0;
  font-family: $monserrat;

  &.deleted {
    background-color: #ba7a7a;
  }

  .refreshIcon {
    width: 13px;
    height: 13px;
    margin-left: -1px;
    margin-right: 9px;
  }

  & > span {
    padding: 6px 16px;
  }

  & > svg {
    color: #fff;
    height: 16px;
  }
}

.modal {
  width: 420px;
  font-family: $monserrat;
  color: black;
  font-size: 18px;
  &__header {
    text-align: center;
    margin-bottom: 15px;
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    .link {
      font-weight: bold;
      font-family: $monserrat;
      font-size: 14px;
      font-style: italic;
      text-decoration: underline;
      color: black;
      cursor: pointer;
    }
  }
  &__footer {
    text-align: center;
  }
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  &_label {
    width: calc(100% - 25px - 15px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: $monserrat;
    margin-left: 16px;
  }
  button.option_edit {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    font-family: $monserrat;
    margin: 0 0 0 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    outline: none;
    color: #aebbc1;
    border-radius: 8px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    .general {
      &__block {
        grid-template-columns: 100%;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        &:first-child {
          margin-right: 0;
        }
        &_row,
        &_selector {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          & > label {
            margin-bottom: 10px;
          }
        }
        &_checkbox {
          justify-content: flex-end;
          width: 100%;
          order: 1;
          & > label {
            margin-right: 50px;
          }
        }
        &_selector {
          & > div {
            width: 100%;
          }
        }
      }
    }

    .subcategories {
      width: 100%;
      .subcategories__item {
        width: 100%;
        &:has(> button) {
          width: calc(100% - 20px);
        }
      }
    }

    .table {
      width: 100%;

      th {
        font-size: 10px;
      }

      th,
      td {
        &:nth-child(1) {
          max-width: calc(100vw - 80px - 85px - 40px);
        }
        &:nth-child(2) {
          width: 80px;
          min-width: 80px;
        }
        &:nth-child(3) {
          width: 85px;
          min-width: 85px;
          line-height: initial;
          padding-left: 5px;
        }
      }

      .chip {
        max-width: 100%;
      }
    }

    .description {
      width: 100%;
    }
  }

  .is_numeric {
    margin: 10px 0 0;
  }

  .modal {
    width: 100%;

    &__footer {
      text-align: center;
    }
  }
}
