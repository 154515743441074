@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 40px;
  .general {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    &__block {
      display: flex;
      flex-direction: column;
      flex-basis: 45%;
      row-gap: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .general {
      flex-direction: column;
      &__block {
        flex-basis: 100%;
      }
    }
  }
}
