@import 'assets/styles/variables.scss';

.footer {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin: auto 0 0;
  width: 427px;
  position: relative;

  & > button {
    width: 50%;
    min-width: 40%;
    margin: 0 !important;
  }

  & > span {
    position: absolute;
    bottom: -18px;
    right: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #e92d2d;
    font-family: $monserrat;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .footer {
    width: 100%;

    & > span {
      bottom: 50px;
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}
