@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 30px;
  display: flex;
  flex-direction: column;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding-bottom: 10px;
    margin: 0 0 20px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 25px;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
    }

    &__controls {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: black;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      z-index: 1;
      margin: 0;

      button {
        background: #f6f6f6;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
      }

      &__controls {
        position: fixed;
        right: 20px;
        top: 20px;

        svg {
          margin-right: 0;
        }
      }
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 15px 0 20px;
    }
  }
}
