.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;

  &__title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    overflow: hidden;
    &_title {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &__controls {
    display: flex;
    &_sort {
      width: 20px;
      height: 20px;
      & > span {
        & > svg {
          width: 20px;
          height: 20px;
          opacity: 0.2;
          transition: all 0.15s ease-in-out;
          color: black;
          &.sorted {
            opacity: 1;
          }
          &.ascend {
            opacity: 1;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
