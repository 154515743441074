@import 'assets/styles/variables.scss';

$right_block_width: 350px;

.wrapper {
  display: flex;
  column-gap: 15px;

  .left {
    width: calc(100% - $right_block_width);
    .general {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 20px;
      margin-bottom: 50px;
      &__block {
        flex-basis: 48%;
        div {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        &_checkbox {
          display: flex;
          align-items: center;
          column-gap: 40px;
          & > label {
            font-style: normal;
            font-size: 14px;
            color: #000000;
            font-family: $monserrat;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }
    }

    .address {
      display: flex;
      margin-bottom: 50px;
      font-size: 14px;
      column-gap: 20px;

      &__position {
        flex-basis: 48%;
        h3 {
          margin-bottom: 0;
        }
        p:nth-of-type(1) {
          margin-top: 20px;
        }

        span {
          display: block;
          margin: 15px 0 10px;
          font-weight: 700;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .stuff {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      &__block {
        &:not(:last-child) {
          margin-bottom: 50px;
        }
        & > span {
          cursor: pointer;
          width: 200px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .right {
    width: $right_block_width;
    .container {
      .editors {
        margin-bottom: 50px;
        &__info {
          font-size: 10px;
          font-style: italic;
          margin: 5px 0;
          padding: 0 10px;
        }
      }

      .files {
        &__block {
          overflow-y: auto;
          max-height: calc(28px * 5);
          &_file {
            font-size: 14px;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 5px;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    flex-direction: column-reverse;
    .left {
      width: 100%;
      .general {
        &__block {
          flex-basis: 100%;
        }
      }
      .address {
        flex-direction: column;
        row-gap: 20px;

        &__position {
          flex-basis: 100%;
        }
      }
      .stuff {
        &__block {
          & > span {
            text-decoration: underline;
          }
        }
      }
    }
    .right {
      width: 100%;
      margin-bottom: 40px;
      .container {
        .editors {
          margin-bottom: 25px;
        }
      }
    }
  }
}
