@import 'assets/styles/variables.scss';

.popup {
  width: 465px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .content {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &__field {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__template {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      button.downloadButton {
        width: fit-content;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}
