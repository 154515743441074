@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: space-between;

  .header {
    &__block {
      display: flex;
      justify-content: space-between;
      margin: 0 0 30px;

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .footer {
    margin: 0;
    button:first-child {
      margin: 0 30px 0 0;
    }
    button:last-child {
      margin: 0;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 20px 0;
  font-family: $monserrat;
}

.details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  column-gap: 100px;
  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 427px;
    &.reduceGap {
      row-gap: 12px;
    }
    &.separate {
      width: 100%;
      margin-bottom: 20px;
    }
    &_field,
    &_checkbox,
    &_project {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 34.5px;
      & > label {
        font-family: $monserrat;
        font-size: 14px;
      }
    }
    &_field {
      width: 427px;
      &.alignTop {
        align-items: flex-start;
        height: auto;
        & > label {
          line-height: 32px;
        }
      }
    }
    &_checkbox {
      width: 200px;
      &:last-child {
        margin-top: auto;
      }
      &.full {
        width: 427px;
      }
    }
    &_project {
      & > div {
        width: 240px;
        font-family: $monserrat;
        font-size: 14px;
      }
    }
  }
}

.sublocations {
  display: flex;
  column-gap: 100px;
  margin-bottom: 30px;
  &__block {
    .items {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      width: 427px;
      &__field {
        & > label {
          font-size: 14px;
          font-family: $monserrat;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
}

.checkboxWrapper {
  margin-bottom: 30px;
  &__checkbox {
    display: flex;
    align-items: center;
    & > label {
      font-size: 14px;
      font-family: $monserrat;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 70px 20px 20px;
    min-height: 100vh;
    height: auto;
    .header {
      &__block {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 10px;
        background: #fff;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: none;
        margin: 0;

        h1 {
          margin: 0 !important;
          padding-left: 40px;
          line-height: 40px;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;

      button {
        width: 50%;
        min-width: 40%;
        &:first-child {
          margin: 0 30px 0 0 !important;
        }
        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }

  .details {
    flex-direction: column;
    &__block {
      width: 100%;
      align-items: flex-end;
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &_field {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        width: 100%;
        & > label {
          margin-bottom: 10px;
        }
        &.map {
          width: 100%;
          align-items: flex-end;
        }
        &.alignTop {
          & > label {
            line-height: normal;
          }
        }
      }

      &_checkbox {
        width: auto;
        & > label {
          margin-right: 72px;
          text-align: end;
        }
        &.full {
          width: auto;
        }
      }

      &_project {
        width: 100%;
        align-items: baseline;
        height: 36px;
        & > label {
          width: 50%;
        }
        & > div {
          width: 50%;
        }
      }
      &.reduceGap {
        row-gap: 12px;
        align-items: flex-start;
      }
    }
  }

  .sublocations {
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;
    &__block {
      width: 100%;
      .items {
        width: 100%;
        row-gap: 15px;
      }
    }
  }

  .checkboxWrapper {
    margin-bottom: 20px;
    &__checkbox {
      justify-content: flex-start;
      flex-direction: row-reverse;
      & > label {
        margin-right: 72px;
        margin-left: 0;
      }
    }
  }
}
