@import 'assets/styles/variables.scss';

.tableControls {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: auto;

  @media screen and (max-width: $breakpoint-1180) {
    width: stretch;
  }
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  th {
    white-space: nowrap;

    &:last-child {
      width: 50px;
      min-width: 50px;
    }
  }

  tr.loose_item_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.looseItemCell {
        width: 30%;
        min-width: 250px;
        background-color: white !important;
        vertical-align: top;
      }
      &.customCell {
        min-width: 200px;
        background-color: white !important;
        vertical-align: top;
      }
      &.locationCell {
        width: 15%;
        min-width: 200px;
      }
      &.sublocationCell {
        width: 15%;
        min-width: 170px;
      }
      &.containedInAssetCell {
        width: 10%;
        min-width: 170px;
      }
      &.qtyCell {
        width: 10%;
        min-width: 125px;
      }
      &.baaCell {
        width: 10%;
        min-width: 125px;
      }
      &.statusCell {
        width: 10%;
        min-width: 160px;
        white-space: normal;
        word-break: break-word;
      }
      &.checkboxCell {
        width: 50px;
        min-width: 50px;
        padding: 0;
        text-align: center;
      }
    }
  }
}
