@import 'assets/styles/variables.scss';

.adding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  width: 100%;
  font-family: $monserrat;
  margin-bottom: 20px;
  &__field {
    width: calc(100% - 60px);
  }

  button.adding__button {
    width: 60px;
    min-width: 60px;
    height: 33.6px;
    background: $extra_color;
    border-radius: 8px;
    color: black;
    cursor: pointer;
    box-shadow: $shadow;
    font-family: $monserrat;
    font-size: 12px;

    &.disabled {
      background-color: #e6e6e6;
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      box-shadow: $shadow_hover;
    }
  }
}
