@import 'assets/styles/variables.scss';

.searchPanel {
  position: relative;
  white-space: nowrap;
}
.rightSpace {
  margin-right: 15px;
}

.searchInput {
  background: #f7f7f7;
  border-radius: 8px;
  width: 300px;

  fieldset {
    border: none;
    border-radius: 8px;
  }

  input {
    font-family: $monserrat;
    font-weight: 400;
    padding: 8px;
    font-size: 12px;
    line-height: 14px;

    &::placeholder {
      opacity: 1;
      color: #000;
    }
  }

  .clearIcon {
    width: 15px;
    height: 15px;
  }

  button {
    margin: 0 !important;
  }
}

.searchMessage {
  position: absolute;
  font-size: 14px;
  margin-left: 18px;
  bottom: -20px;
  right: 0;
}

@media screen and (max-width: $breakpoint-1180) {
  .searchPanel {
    width: 100%;
  }

  .searchInput {
    width: 100%;
    margin-bottom: 0;
  }
}
