@import 'assets/styles/variables.scss';

.dialog {
  width: 900px;
  min-height: 500px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow: auto;

  .title {
    & > h2 {
      max-width: 100%;
    }
  }

  .content {
    .form {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      &__item {
        display: flex;

        & > label {
          font: {
            family: $monserrat;
            style: normal;
            weight: normal;
            size: 14px;
          }
        }

        &.row {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 340px;
        }
        &.column {
          flex-direction: column;
          row-gap: 10px;
        }
      }
    }
  }

  .inputs {
    &__qty {
      width: 80px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 200px !important;
      min-width: 200px !important;
      height: 40px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
