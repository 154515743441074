@import 'assets/styles/variables.scss';

span.checkbox {
  margin-right: 20px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

li:has(div.render_option.selected) {
  background-color: rgba(0, 0, 0, 0.08);
}

div.render_option {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  & > label {
    max-width: calc(100% - 16px - 25px);
    font-family: $monserrat;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
