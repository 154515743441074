@import 'assets/styles/variables.scss';

.sidebarContainer {
  font-family: $monserrat;
  position: relative;
  min-width: 380px;
  padding: 15px 0 10px;
  box-sizing: border-box;
  background: #f6f6f6;

  &__wrapper {
    height: calc(100% - 46px);
    padding: 0 10px 50px 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  &__pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 20px;

    h1 {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      margin: 0;
    }

    .addAssetsButton {
      font: 12px $monserrat;
      padding: 5px 20px;
      border-radius: 8px;
      white-space: nowrap;
      background: #ffd83c;
      color: black;
      width: 150px;
      height: 34px;

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  &__locationBlock {
    border-radius: 8px;
    background: #fff;
    padding: 15px 10px;
    margin-top: 5px;

    h3 {
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      font-size: 14px;

      span {
        text-transform: none;
      }
    }

    p {
      margin: 0 0 0 10px;
      font-size: 14px;
      line-height: 24px;
    }

    &__assetsBlock {
      display: flex;
      flex-direction: column;

      .checkboxItem {
        span {
          font-family: $monserrat;
          font-size: 14px;
        }
      }

      .checkbox {
        margin: 5px 10px 5px 20px;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
      .checked {
        color: $main_color !important;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }

      .assetLabel {
        font-size: 14px;
        margin-left: 54px;
      }
    }

    &__summaryBlock {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      padding-left: 40px;

      b {
        font-size: 14px;
      }
    }
  }
}

button.sidebarContainer__loadMoreButton {
  font: 400 12px $monserrat;
  width: 130px;
  height: 34px;
  box-sizing: border-box;
  text-transform: none;
  text-decoration: underline;
  padding: 5px 20px;
  border-radius: 8px;
  position: fixed;
  bottom: 10px;
  right: 45px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: #fff;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .sidebarContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: auto;
    z-index: 1;
    padding-top: 0;

    &__pageHeader {
      background: #fff;
      min-height: 60px;
      width: 100%;
      padding: 0 20px 0 0;
      box-sizing: border-box;
      flex-wrap: wrap;
      margin-bottom: 5px;
      align-items: center;

      h1 {
        font-weight: 700;
        line-height: 60px;
        padding-left: 60px;
      }

      .addAssetsButton {
        flex-basis: 100%;
        margin: 0 20px 10px;
      }
    }

    &__wrapper {
      padding: 0 20px 50px;
      height: calc(100% - 111px);
    }

    &__locationBlock:not(:first-child) {
      margin-top: 10px;
    }
  }

  button.sidebarContainer__loadMoreButton {
    right: 20px;
  }
}
