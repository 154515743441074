@import 'assets/styles/variables.scss';

.wrapper {
  width: 100%;

  .editors {
    margin-bottom: 25px;
    &__info {
      font-size: 10px;
      font-style: italic;
      margin: 5px 0;
      padding: 0;
    }

    &__see_in_logs {
      text-align: end;
      & > a {
        color: $main_color !important;
        font-style: italic;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }

  .files {
    &__info {
      overflow-y: auto;
      max-height: calc(((17px + 10px) * 4) + 2px);
      &_item {
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.actions {
  z-index: 1;
  button.actions__control {
    display: flex;
    justify-content: space-between;
    font-family: $monserrat;
    color: #fff;
    background-color: $main_color;
    border-radius: 8px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 15px;
    transition: all 0.3ms ease-out;
    box-shadow: $shadow;
    &:hover {
      background-color: $main_color;
      box-shadow: $shadow_hover;
    }

    svg {
      transition: all 0.15s ease-in-out;
    }
  }

  .opened {
    transform: rotate(180deg);
  }
}
