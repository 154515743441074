@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  height: 34px;

  &Highlighted {
    background: #ff000012 !important;
  }

  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
  }
}
