@import 'assets/styles/variables.scss';

button.filter {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #f6f6f6;
  box-shadow: $shadow;

  &:hover {
    background-color: #f6f6f6;
    box-shadow: $shadow_hover;
  }

  &.active,
  &.active:hover {
    background-color: $main_color;

    img {
      filter: invert(100%);
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.filter {
    background-color: #f6f6f6;

    &.active,
    &.active:hover {
      background-color: $filters_secondary_color;
    }
  }
}
