@import 'assets/styles/variables.scss';

.statusIndicator {
  position: relative;
  display: block;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  margin: 0 5px 0 0;

  &.Critical {
    background: $alert_color;
    box-shadow: 0px 1px 4px rgba(233, 45, 45, 0.25);
  }
  &.Transparent {
    background: transparent;
  }
}

.paper {
  border-radius: 8px !important;
  margin-left: 10px;
  .container {
    padding: 10px;
    background: $main_color;
    border-radius: 8px;
    color: white;
    line-height: 10px;
    & > span {
      font-weight: 500;
      font-size: 12px;
    }
  }
}
