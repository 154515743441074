@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    flex-direction: row-reverse;
    column-gap: 20px;
  }
}
