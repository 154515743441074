@import 'assets/styles/variables.scss';

.general {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 25px;
  &_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    & > label {
      font-size: 14px;
      font-family: $monserrat;
      font-weight: 400;
      color: #000;
      width: 180px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .general {
    &_details {
      flex-direction: column;
      align-items: flex-start;

      b {
        word-break: break-word;
        line-height: 32px;
        font-size: 12px;
      }
    }
  }
}
