@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 20px 60px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $main_color;
  margin-bottom: 10px;
  padding-bottom: 8px;

  & > h2 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
  }

  &__controls {
    display: flex;
    align-items: center;
    column-gap: 30px;

    &_bar {
      border-radius: 8px;
      height: 30px;
      & > button {
        font-family: $monserrat;
        flex-grow: 1;
        padding: 10px 25px;
        color: #000;
        background: #f7f7f7;
        font-weight: normal;
        font-size: 14px;
        outline: #8a8a8a solid 1px;
        text-transform: uppercase;
        width: 140px;

        &:first-child {
          border-radius: 7px 0 0 7px;
        }
        &:last-child {
          border-radius: 0 7px 7px 0;
          border-right: none;
        }
      }

      button.selected,
      button.selected:hover {
        color: #fff;
        background: $main_color;
      }
    }
  }

  button.edit_button {
    width: 190px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 8px;
    line-height: 15px;
    margin: 0 0 0 10px;
    font-family: $monserrat;
    img {
      margin-right: 10px;
      color: black;
    }
  }
}
