@import 'assets/styles/variables.scss';

.popup {
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 70px 20px 20px;
  box-sizing: border-box;
  row-gap: 20px;

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .location {
    font-size: 18px;
  }
}
