@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;

  @media screen and (max-width: $breakpoint-1180) {
    flex-direction: column;
    align-items: normal;
    row-gap: 10px;
  }
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  tr.location_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.codeCell {
        width: 15%;
        min-width: 200px;
      }
      &.addressCell {
        width: 15%;
        min-width: 200px;
      }
      &.numberCell {
        width: 10%;
        min-width: 200px;
        white-space: normal;
        word-break: break-word;
      }
      &.descriptionCell {
        width: 30%;
        min-width: 400px;
      }
      &.typeCell {
        width: 10%;
        min-width: 160px;
      }
    }
  }
}
