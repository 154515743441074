@import 'assets/styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 60px;

  &__row,
  &__description {
    display: flex;
    width: 427px;
  }

  &__row {
    align-items: center;
    justify-content: space-between;
  }

  &__description {
    flex-direction: column;
    row-gap: 10px;
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
  }

  &__prefixes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 597px;

    &_field {
      width: 420px;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  width: calc(100% - 25px - 10px - 16px);
  font-family: $monserrat;
  font-size: 14px;

  &__prefix {
    font-weight: 700;
    min-width: 100px;
    max-width: 100px;
    color: #333333;
    white-space: normal;
    word-break: break-all;
  }

  &__description {
    margin-left: 15px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 100px - 15px);
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    &__row,
    &__prefixes {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 10px;
      width: 100%;
    }

    &__description {
      width: 100%;
    }

    &__checkbox {
      justify-content: flex-end;
      column-gap: 50px;
      width: 100%;
    }

    &__prefixes {
      &_field {
        width: 100%;
      }
    }
  }
}
