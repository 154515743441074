@import 'assets/styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  margin-bottom: 50px;

  .general {
    display: flex;
    column-gap: 100px;
    &__block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 427px;

      @media screen and (max-width: $breakpoint-1180) {
        width: 100%;
      }
    }
  }

  .tables {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .tabs {
      margin-bottom: 0;
      & > button {
        min-width: 125px;
        & > span {
          font-size: 14px;
        }
      }
    }
  }
}
