.table {
  width: min-content;
  tr {
    &:first-child {
      th {
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  th,
  td {
    &:first-child {
      width: 135px;
      min-width: 135px;
    }

    &:not(:first-child) {
      text-align: center;
    }
  }

  th {
    background-color: rgba(226, 238, 243, 1);
    white-space: nowrap;
  }

  td {
    &:first-child {
      width: 140px;
      min-width: 140px;
    }

    &:not(:first-child) {
      width: 240px;
      min-width: 200px;
    }

    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    div.severityCell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      div.indicator {
        border-radius: 12px;
        height: 12px;
        width: 12px;

        &.Operational {
          background: rgba(126, 195, 126, 1);
          box-shadow: 0 1px 4px rgba(0, 147, 0, 0.25);
        }
        &.Critical {
          background: rgba(218, 152, 152, 1);
          box-shadow: 0 1px 4px rgba(233, 45, 45, 0.25);
        }
        &.NonCritical {
          background: rgba(225, 205, 128, 1);
          box-shadow: 0 1px 4px rgba(236, 191, 34, 0.25);
        }
        &.BeyondRepair {
          background: rgba(12, 12, 12, 1);
          box-shadow: 0 1px 4px rgba(181, 152, 218, 0.25);
        }
      }
    }

    div.linkWrapper {
      & > a {
        padding: 2px 9px;
        min-width: 7px;
        border-radius: 17px;
      }

      &.Operational {
        & > a {
          background-color: rgba(126, 195, 126, 1);
        }
      }
      &.Critical {
        & > a {
          background-color: rgba(218, 152, 152, 1);
        }
      }
      &.NonCritical {
        & > a {
          background-color: rgba(225, 205, 128, 1);
        }
      }
      &.BeyondRepair {
        & > a {
          background-color: rgba(12, 12, 12, 1);
          color: white !important;
        }
      }
    }
  }
}
