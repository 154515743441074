@import 'assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  column-gap: 100px;
  row-gap: 30px;
  margin-bottom: 60px;

  .general {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .form {
      display: flex;
      column-gap: 100px;
      &__block {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 427px;
        &_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 34px;

          & > label {
            max-width: calc(427px - 250px - 20px);
            white-space: normal;
            word-break: break-word;
          }

          &.checkbox {
            width: 320px;
          }

          &.info {
            & > p {
              font-weight: 700;
              font-size: 14px;
              width: 250px;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.file {
            align-items: flex-start;
            height: auto;
            & > label {
              line-height: 34px;
            }
          }
        }

        &_column {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          & > p {
            font-size: 12px;
            font-family: $monserrat;
            margin: 0;
          }

          .searchField {
            width: 100%;
          }
        }
      }
    }

    .fields {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 100px;
      width: calc(427px * 2 + 100px);
      &__row {
        width: 427px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;
        &.checkbox {
          width: 320px;
        }

        & > label {
          max-width: calc(427px - 250px - 20px);
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }
}

.section_title {
  font-size: 20px;
  font-weight: bold;
  font-family: $monserrat;
}

@media screen and (max-width: $breakpoint-1180) {
  .content {
    .general {
      .form {
        flex-direction: column;
        row-gap: 20px;
        &__block {
          width: 100%;
          &_row {
            height: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 10px;

            & > label {
              max-width: 100%;
            }

            &.checkbox {
              width: 100%;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              & > label {
                margin: 0 40px 0 0;
              }
            }

            &.info {
              & > p {
                width: 100%;
              }
            }

            &.file {
              & > label {
                line-height: normal;
              }
            }
          }
        }
      }

      .fields {
        width: 100%;
        &__row {
          width: 100%;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto;
          row-gap: 10px;
          &.checkbox {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            & > label {
              margin: 0 40px 0 0;
            }
          }

          & > label {
            max-width: 100%;
          }
        }
      }
    }
  }
}
