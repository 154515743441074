@import 'assets/styles/variables.scss';

.dropzoneWrapper {
  width: 250px;
}

.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  min-height: 33px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border-radius: 8px;
  margin-bottom: 14px;
  background: #f7f7f7;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel,
.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  font-weight: 400;
  font-size: 12px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  svg {
    height: 17px;
    width: 17px;
    margin-right: 6px;
  }
}

.dzu-previewFileWrapper,
.dzu-fileWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-top: 2px;
  box-sizing: border-box;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    display: block;
    font-size: 14px;
    margin-left: 10px;
    line-height: 21px;
  }
}

div.dzu-uploadSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px !important;
  margin-right: 2px;

  svg {
    width: 16px;
    height: 16px;
    color: #000;
  }
}

.dzu-fileLoading,
.deprecatedInpu {
  opacity: 0.5;
}

.deprecatedInput {
  cursor: not-allowed;

  & ~ p {
    margin: 0;
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 5px;
    bottom: -14px;
    color: $error_color;
  }
}

svg.dzu-deleteButton {
  width: 20px;
  height: 20px;
  background: #fff;
  cursor: pointer;
  margin-top: -2px;
}

@media screen and (max-width: $breakpoint-1180) {
  .dropzoneWrapper {
    width: 100% !important;
  }

  .dzu-dropzone {
    width: 100% !important;
  }
}
