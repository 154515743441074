@import 'assets/styles/variables.scss';

.checkbox {
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}
