@import 'assets/styles/variables.scss';

$right_column_width: 345px;

.wrapper {
  display: flex;
  column-gap: 20px;
  margin-bottom: 30px;
  .info {
    width: calc(100% - $right_column_width);
  }
  .files {
    width: $right_column_width;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    flex-direction: column-reverse;
    .info,
    .files {
      width: 100%;
    }
    .files {
      margin-bottom: 20px;
    }
  }
}
