@import 'assets/styles/variables.scss';

.title {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.subtitle {
  display: flex;
  font-family: $monserrat;
}

.text_info,
.date_field,
.text_input_field {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 427px;
  height: 35px;
  label {
    margin: 0;
    font-size: 14px;
    min-width: 180px;
  }
  .text_details {
    width: auto;
  }
  .date_input {
    width: auto;
  }
}

.textarea_field {
  display: flex;
  flex-direction: column;
  position: relative;
  .textarea_title {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 15px;
  }
  .textarea_input {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .text_info,
  .text_input_field,
  .date_field {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .text_input_field,
  .date_field {
    height: auto;
    & > label {
      margin-bottom: 10px;
    }
    .date_input {
      width: 100%;
    }
  }
}
