@import 'assets/styles/variables.scss';

.dialog {
  width: 800px;

  max-height: 800px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .signature {
    margin-bottom: 15px;
    height: 425px;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 160px !important;
      min-width: 160px !important;
      height: 40px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .signature {
      margin-bottom: 40px;
    }

    .footer {
      & > button {
        width: 49% !important;
        min-width: 49% !important;
      }
    }
  }
}
