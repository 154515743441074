@import 'assets/styles/variables.scss';

.inputValueSpan {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputWrapper {
  border: 1px solid transparent;
  border-radius: 8px;
  background: #f7f7f7;
  min-height: $field_default_height;
  box-sizing: border-box;
  width: 250px;
  position: relative;

  &.invalid {
    border-color: $error_color;
  }

  &.clearable {
    & > div {
      & > div {
        padding: 6px 56px 6px 16px !important;
      }
    }
  }

  .clear {
    position: absolute;
    top: 9px;
    right: 35px;
    width: 16px;
    height: 16px;
    svg {
      color: black;
      width: 16px;
      height: 16px;
    }
  }
}

li.selectInput__menu {
  font-family: $monserrat;
  background: none !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

li.selectInput__emptyMenu {
  font-family: $monserrat;
  background: none !important;
  cursor: default;
}

svg.selectInput__icon {
  right: 10px;
  color: #000;
}

span.checkbox {
  margin-right: 20px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .selectInput {
    width: calc(100vw - 40px) !important;
  }
  li.selectInput__menu {
    word-break: break-word !important;
    white-space: normal !important;
    max-width: calc(100vw - 40px);
  }
}
