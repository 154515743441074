@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 60px 50px 40px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
}

.title {
  font: {
    family: $monserrat;
    weight: 700;
    size: 24px;
  }
  line-height: 29px;
  margin: 0 0 20px;
}

.flexible {
  display: flex;
  justify-content: space-between;
  width: 1000px;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 60px 20px 20px;
    height: 100vh;
  }

  .flexible {
    flex-direction: column;
    width: 100%;
  }
}
