@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  .pageHeader {
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0 0 10px;
    }
  }

  .mainInfoBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 15px;

    p {
      margin: 0 0 5px;
      font-size: 14px;
    }

    .controlButtons {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;

      button {
        margin: 0;
      }
      button:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .chipsBlock {
    flex-grow: 1;
    margin: 40px 0 -15px;
    padding: 0 0 5px 0;
    box-sizing: border-box;
    max-width: 700px;

    .fieldsChip {
      font-family: $monserrat;
      background-color: #7aa5ba;
      border-radius: 8px;
      color: white;
      margin-right: 10px;
      margin-bottom: 10px;

      & > span {
        padding: 6px 16px;
      }

      & > svg {
        color: #fff;
        height: 16px;
      }
    }
  }

  &.precondition {
    padding-top: 100px;

    .formCell {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
      width: 427px;

      label {
        margin: 0 0 10px;
        font-size: 14px;
      }

      &.rowCell {
        flex-direction: row;
        width: 600px;
        label {
          width: 180px;
          margin: 0;
          line-height: 32px;
        }
        .inputWrapper {
          & > div {
            & > div {
              width: 420px;
            }
          }
        }
      }
      &.select {
        .inputWrapper {
          & > div {
            & > div {
              & > div {
                width: 330px;
              }
            }
          }
        }
      }

      .dataLabel {
        width: 240px;
        line-height: 33px;
      }

      .inputWrapper {
        position: relative;

        & > span,
        & > p {
          position: absolute;
          font-weight: 400;
          font-size: 10px;
          left: 5px;
          bottom: -14px;
          color: $error_color;
        }

        & > p {
          margin: 0 0 -5px;
          color: #000;
        }
      }

      .dateInputWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 330px;

        .picker {
          width: 158px;
        }
      }
    }
  }

  &.dataView {
    max-width: 100%;
    padding: 20px 60px 40px;

    .headerWrapper {
      background: #fff;
      box-sizing: border-box;
      margin-bottom: 20px;
      z-index: 1;
    }

    .pageHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid $main_color;

      &__controls {
        display: flex;
        padding-bottom: 10px;

        button {
          margin-left: 30px;
        }

        .downloadButton {
          display: flex;
          align-items: center;
          border: none;
          background: none;
          cursor: pointer;
          font-family: $monserrat;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          outline: none;
          padding: 0;
          margin-right: 35px;
          img {
            margin-right: 10px;
          }
        }
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        margin: 0;
        padding-bottom: 10px;
      }

      .mainButton {
        background: #ffd83c;
        font-family: $monserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        height: 40px;
        width: 200px;
      }
    }

    .pageTopBlock {
      p {
        font-size: 14px;
        margin-bottom: 20px;
        b {
          margin-right: 10px;
        }
      }
    }

    .tableContainer {
      margin-top: 10px;
      border-radius: 8px;

      table {
        border-collapse: initial;
      }

      &.fixedHeader {
        overflow-x: initial;
      }

      table {
        border-collapse: separate;
      }

      td {
        padding: 0 !important;
        border-bottom: none;
        width: 240px;
        min-width: 180px;
        -webkit-text-size-adjust: 100%;
        div {
          padding: 14px 16px;
        }

        &:first-child {
          font-weight: bold;
        }
        &:nth-child(25n + 2) div {
          background-color: rgba(249, 209, 185, 0.3);
        }
        &:nth-child(25n + 3) div {
          background-color: rgba(245, 203, 193, 0.3);
        }
        &:nth-child(25n + 4) div {
          background-color: rgba(239, 195, 214, 0.3);
        }
        &:nth-child(25n + 5) div {
          background-color: rgba(255, 173, 175, 0.3);
        }
        &:nth-child(25n + 6) div {
          background-color: rgba(254, 192, 173, 0.3);
        }
        &:nth-child(25n + 7) div {
          background-color: rgba(255, 181, 147, 0.3);
        }
        &:nth-child(25n + 8) div {
          background-color: rgba(255, 212, 173, 0.3);
        }
        &:nth-child(25n + 9) div {
          background-color: rgba(252, 197, 147, 0.3);
        }
        &:nth-child(25n + 10) div {
          background-color: rgba(248, 211, 153, 0.3);
        }
        &:nth-child(25n + 11) div {
          background-color: rgba(244, 225, 146, 0.3);
        }
        &:nth-child(25n + 12) div {
          background-color: rgba(217, 231, 147, 0.3);
        }
        &:nth-child(25n + 13) div {
          background-color: rgba(202, 228, 144, 0.3);
        }
        &:nth-child(25n + 14) div {
          background-color: rgba(193, 222, 139, 0.3);
        }
        &:nth-child(25n + 15) div {
          background-color: rgba(172, 219, 152, 0.3);
        }
        &:nth-child(25n + 16) div {
          background-color: rgba(154, 214, 153, 0.3);
        }
        &:nth-child(25n + 17) div {
          background-color: rgba(107, 217, 185, 0.3);
        }
        &:nth-child(25n + 18) div {
          background-color: rgba(115, 216, 205, 0.3);
        }
        &:nth-child(25n + 19) div {
          background-color: rgba(138, 218, 216, 0.3);
        }
        &:nth-child(25n + 20) div {
          background-color: rgba(113, 212, 213, 0.3);
        }
        &:nth-child(25n + 21) div {
          background-color: rgba(141, 210, 217, 0.3);
        }
        &:nth-child(25n + 22) div {
          background-color: rgba(149, 208, 221, 0.3);
        }
        &:nth-child(25n + 23) div {
          background-color: rgba(181, 207, 227, 0.3);
        }
        &:nth-child(25n + 24) div {
          background-color: rgba(203, 213, 228, 0.3);
        }
        &:nth-child(25n + 25) div {
          background-color: rgba(240, 184, 210, 0.3);
        }
        &:nth-child(25n + 26) div {
          background-color: rgba(248, 156, 185, 0.3);
        }
      }

      th {
        white-space: nowrap;
      }

      td:not(:last-child) {
        border-right: 1px solid #dcdcdc !important;
      }

      .emptyTable {
        background-color: #fff;
        box-sizing: border-box;
      }

      .emptyResponseTable {
        background-color: #f7f7f7;
        padding: 20px !important;
        font-size: 18px;
        text-align: center;
        font-weight: normal !important;
      }
    }
  }
}

.pickerFromPosition {
  & > div[class='rdtPicker'] {
    right: 50%;
    transform: translateX(70%) translateY(10px);
  }
}
.pickerToPosition {
  & > div[class='rdtPicker'] {
    right: 50%;
    transform: translateX(30%) translateY(10px);
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 40px 20px 20px;
    &.precondition {
      padding-top: 40px;
      .mainInfoBlock {
        height: calc(100vh - 80px);

        .controlButtons {
          justify-content: center;
          button {
            width: 50%;
          }
        }
      }
    }
    .pageHeader {
      position: fixed;
      padding-top: 10px;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 1;

      .headerTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        padding-right: 20px;
        h1 {
          padding-left: 60px;
          line-height: 40px;
          margin-bottom: 0;
        }
      }
    }
    .formCell {
      width: calc(100vw - 40px) !important;
      &.rowCell {
        flex-direction: column !important;
        .inputWrapper {
          & > div {
            & > div {
              width: calc(100vw - 40px) !important;
            }
          }
        }
      }
      &.select {
        .inputWrapper {
          & > div {
            & > div {
              & > div {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
    &.select {
      .inputWrapper {
        & > div {
          & > div {
            & > div {
              width: 100% !important;
            }
          }
        }
      }
    }
    .inputWrapper {
      & > div {
        & > div {
          width: calc(100vw - 40px) !important;
        }
      }
    }
    .dateInputWrapper {
      width: calc(100vw - 40px) !important;
      .picker {
        width: 47% !important;
      }
    }
    .chipsBlock {
      margin-bottom: 20px;
    }

    &.dataView {
      padding: 100px 20px 20px;

      .pageHeader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background: #fff;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid transparent;

        padding-top: 0;
        z-index: 8;

        h1 {
          padding: 0;
          padding-left: 60px;
          line-height: 60px;
        }

        &__controls {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          button {
            margin-left: 0;
            width: 100%;
          }
        }
      }
      .tableContainer {
        position: relative;
        left: -20px;
        width: 100vw;
      }
    }
  }
}
