@import 'assets/styles/variables.scss';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  width: 100%;
  font-family: $monserrat;
  &__field {
    width: calc(100% - 60px);
    position: relative;
    &_input {
      background: #f7f7f7;
      border-radius: 8px;
      width: 100%;
      margin: 0 !important;
      border: 1px solid rgba(0, 0, 0, 0.15) !important;
      height: 30px;
      left: -7px;
      & > div {
        height: 30px;
      }
      fieldset {
        border: none;
      }
      &.error {
        border-color: $error_color !important;
      }
      input {
        font-weight: 400;
        padding: 6px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;
        font-family: $monserrat;

        &::placeholder {
          opacity: 1;
          color: lightgray !important;
        }
      }
    }
    & > span {
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: -7px;
      bottom: -14px;
      color: $error_color;
      line-height: normal;
    }
  }

  button.row__button {
    width: 60px;
    min-width: 60px;
    height: 30px;
    background: $extra_color;
    border-radius: 8px;
    color: black;
    cursor: pointer;
    box-shadow: $shadow;
    font-family: $monserrat;
    font-size: 12px;

    &.disabled {
      background-color: #e6e6e6;
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      box-shadow: $shadow_hover;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .row {
    &__field {
      &_input {
        left: 0;
      }
      & > span {
        left: 0;
      }
    }
  }
}
