@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;
  }
}
