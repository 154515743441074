@import 'assets/styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;

  div.chip {
    font-family: $monserrat;
    max-width: 100%;
    height: 34px;
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}
