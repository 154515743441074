@import 'assets/styles/variables.scss';

.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $main_color;
  min-height: 51px;

  &__controls {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    button {
      margin-left: 30px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid transparent;

    &__controls {
      padding-bottom: 0;
      width: 100%;
      justify-content: space-between;

      button {
        margin-left: 0;
        width: 100%;
        min-width: 40%;
      }
    }
    &__fitControls {
      button {
        width: 46% !important;
      }
    }

    h1 {
      padding-left: 40px;
      line-height: 40px;
    }
  }
}
