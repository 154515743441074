@import 'assets/styles/variables.scss';

.editing {
  width: 100%;
  min-height: 20px;
  cursor: pointer;
  &_disabled {
    cursor: default;
    pointer-events: none;
  }

  .textInput {
    width: 100%;
    &.multiline {
      & > div {
        height: auto;
      }
    }
    & > div {
      width: 100%;
      border: 1px solid #999;
      padding: 2px !important;
      font-size: 14px;
      border-radius: 8px;
      height: 34.625px;
    }
    & > svg {
      right: 5px;
    }

    fieldset {
      border: none;
    }
    input,
    textarea {
      font-family: $monserrat;
      font-weight: 400;
      padding: 8px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
    input {
      padding: 3px 5px;
    }
  }
  .helpers {
    display: flex;
    button {
      margin: 4px 0 0;
      flex: 1;
      border-radius: 4px;
      padding: 0;
      min-width: 20px;
      &:first-child {
        margin-right: 5px;
      }
      svg {
        height: 15px;
        padding: 3px;
      }
    }
  }
  .alert {
    color: #fff;
    background: $alert_color;
    &:hover {
      background: #c47777;
    }
  }
  .success {
    color: #fff;
    background: $regular_color;
    &:hover {
      background: #5aa45a;
    }
  }
}
