@import 'assets/styles/variables.scss';

.text_section {
  display: flex;
  flex-direction: column;
  position: relative;
  .checkbox {
    label {
      margin: 0 0 15px;
      span {
        font-family: $monserrat;
        font-size: 12px;
      }
      & > span {
        margin-right: 15px;
      }
    }
  }
}

.signature {
  display: flex;
  flex-direction: column;

  & > h1 {
    margin-bottom: 15px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &__input {
      display: flex;
      flex-direction: column;
      position: relative;
      & > label {
        font-size: 14px;
        font-family: $monserrat;
        margin-bottom: 10px;
        white-space: normal;
        word-break: break-word;
      }
      & > div {
        width: 360px;
      }
    }
  }
}

.resource_section {
  &:has(label) {
    .image {
      margin-bottom: 15px;
    }
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 100%;
      height: 100%;
      max-height: 250px;
      object-fit: contain;
    }
  }
}

.line {
  border-top: 1px solid $main_color;
  border-color: $main_color;
  background-color: $main_color;
  color: $main_color;
  border-bottom: none;
  margin: 0;
}

.template_section {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
}

.choice_hint_text {
  padding-left: 40px;
  font-size: 10px;
  font-style: italic;
}

.custom_details {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.choice_wrapper {
  position: relative;
  .choice_section {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    &.error {
      border-color: $error_color;
      padding: 15px 20px;
    }
    .choice_checkboxes {
      .checkbox_option {
        display: flex;
        flex-direction: column;
        width: fit-content;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        & > label {
          margin: 0;
          span {
            font-family: $monserrat;
            font-size: 16px;
          }
          & > span {
            &:first-child {
              margin-right: 15px;
            }
            &:last-child {
              white-space: normal;
              word-break: break-word;
            }
          }
        }
      }
    }
    .choice_radios {
      & > fieldset {
        width: 100%;
        .radio_option {
          display: flex;
          flex-direction: column;
          width: fit-content;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          & > label {
            font-family: $monserrat;
            margin-left: 0;
            margin-right: 20px;
            & > span {
              &:first-child {
                margin-right: 15px;
              }
            }
          }

          span.radio_label {
            font-family: $monserrat;
            white-space: normal;
            word-break: break-word;
          }
        }
      }
    }
    .comment {
      margin-bottom: 15px;
      &__input {
        width: 100%;
      }
    }
  }
  .validation_error {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
  }
}

.custom_table_section {
  .table {
    border-radius: 8px;
    overflow-x: auto;

    table {
      border-spacing: 10px;
      border-collapse: separate;
    }

    th,
    td {
      border: none;
      background-color: white !important;
      height: 40px;
      white-space: normal;
      word-break: break-word;
      text-align: center;
      max-width: 0;
      min-width: 150px;
      &:first-child {
        width: 0;
      }
    }

    th {
      padding: 0;
    }

    td {
      padding: 0;
      &:nth-child(1) {
        width: 150px;
        white-space: normal;
        word-break: break-word;
      }
      & > div {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .choice_wrapper {
    .choice_section {
      .choice_radios {
        width: 100%;
        & > fieldset {
          .radio_option {
            & > label {
              font-family: $monserrat;
              margin-left: 0;
              margin-right: 20px;
              & > span {
                &:first-child {
                  display: flex !important;
                }
              }
            }
            span.radio_label {
              font: {
                family: $monserrat;
              }
              margin-left: 15px;
            }
          }
        }
        .radio_option {
          & > label {
            & > span {
              display: block !important;
            }
          }
        }
      }
      .choice_checkboxes {
        width: 100%;
      }
    }
  }

  .custom_table_section {
    .table {
      position: relative;
      left: -20px;
      width: 100vw;

      td,
      th {
        &:first-child {
          min-width: 150px;
          max-width: 150px;
        }
        &:not(:first-child) {
          max-width: 250px;
        }
      }
    }
  }

  .signature {
    .fields {
      &__input {
        & > div {
          width: 100%;
        }
      }
    }
  }
}
