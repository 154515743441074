@import 'assets/styles/variables.scss';

.header {
  display: flex;
  justify-content: center;

  & > h2 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    font-family: $monserrat;
  }

  &__controls {
    display: flex;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: #fff;
    padding: 0 20px 0 !important;
    z-index: 2;

    & > h2 {
      padding-left: 40px;
      line-height: 60px;
      margin: 0;
    }

    &__controls {
      display: flex;
      align-items: center;
    }
  }
}
