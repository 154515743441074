@import 'assets/styles/variables.scss';

.wrapper {
  width: 1050px;
  height: 550px;
  padding: 15px;
  box-sizing: border-box;
  font-family: $monserrat;
  position: relative;

  .header {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    padding-bottom: 5px;

    &__close {
      width: 25px;
      height: 25px;
      svg {
        color: black;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 35px);
    position: relative;
  }

  .buttonWrapper {
    z-index: 1000;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    & > button {
      transition: color 0.01s; // safari bug fix
      span {
        transition: color 0.01s; // safari bug fix
      }
    }
  }
}

button.map_button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  padding: 5px;
  height: 30px;
  border-radius: 8px;
  color: black;
  & > svg,
  & > img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 0;

    .header {
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 60px;
    }

    .content {
      height: calc(100% - 60px);
    }
  }
}
