.wrapper {
  .field {
    display: flex;
    align-items: center;
    position: relative;
    &__qr {
      cursor: pointer;
      margin-left: 20px;
      width: 25px;
      height: 25px;
    }
  }
}
