@import 'assets/styles/variables.scss';

.signatures {
  display: flex;
  flex-direction: column;
  margin: 25px 0 0;
  &__add {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $main_color;
    &:has(button) {
      padding: 25px 0 0;
    }
    button.signatures__add_button {
      background: $main_color;
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-radius: 8px;
      font-size: 12px;
      box-shadow: $shadow;
      &:hover {
        background: $main_color;
        box-shadow: $shadow_hover;
      }
      svg {
        width: 16px;
        height: 16px;
        color: white;
      }
    }

    & > span {
      font-size: 18px;
      font-family: $monserrat;
      color: #000000;
      margin-right: 20px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.form {
  margin-bottom: 25px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    & > label {
      margin-bottom: 0;
    }

    &_delete {
      svg {
        color: black;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &_input {
      display: flex;
      flex-direction: column;
      & > label {
        font-size: 14px;
        color: #000000;
        font-family: $monserrat;
        margin-bottom: 10px;
      }
      & > div {
        width: 360px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    &__body {
      &_input {
        & > div {
          width: 100%;
        }
      }
    }
  }
}
