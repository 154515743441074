@import 'assets/styles/variables.scss';

.inputWrapper {
  position: relative;
  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.error {
      color: $error_color;
    }

    &.hint {
      color: black;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
    & > div {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
  }
}
