@import 'assets/styles/variables.scss';

.backDrop {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.confirmPopupContainer {
  border-radius: 8px !important;
}

.popupContainer {
  max-width: fit-content !important;
  max-height: calc(100% - 80px) !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.popupTitle {
  padding: 0 !important;

  .headerTitle {
    margin-bottom: 30px;
    display: flex;
    h2 {
      font-weight: 700;
      font-size: 24px;
      max-width: 340px;
      line-height: 34px;
      text-align: left;
      margin: 0;
      font-family: $monserrat;
    }
    &__controls {
      display: none;
    }
  }
}

.popupText {
  padding: 0 !important;
  overflow: visible !important;
}

.popupControls {
  padding: 0 !important;
}

@media screen and (max-width: $breakpoint-1180) {
  .confirmPopupContainer {
    width: 100vw !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .popupContainer {
    margin: 0 !important;
    border-radius: 0 !important;
    max-height: 100% !important;
    height: 100% !important;
    transition: none !important;
    box-sizing: border-box;
    width: 100%;
    max-width: 100% !important;
  }

  .popupTitle {
    position: fixed;
    padding: 0 20px 0 !important;
    top: 0;
    left: 0;
    background: #fff;
    box-sizing: border-box;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 60px;
    .headerTitle {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      h2 {
        padding-left: 40px;
        line-height: 60px;
        margin: 0;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      &__controls {
        display: flex;
        align-items: center;

        svg {
          color: black;
        }

        button {
          display: flex;
          align-items: center;
          border: none;
          background: #f6f6f6;
          border-radius: 8px;
          height: 30px;
          width: 30px;
          margin: 0 0 0 20px;
          padding: 0;
          outline: none;
          justify-content: center;
          &:only-child {
            margin: 0;
          }
        }
      }
    }
  }
}
