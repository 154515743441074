@import 'assets/styles/variables.scss';

.map {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  div[class='leaflet-control-attribution leaflet-control'] {
    display: none;
  }
  div[class='leaflet-control-zoom leaflet-bar leaflet-control'] {
    margin-bottom: 30px;
    margin-right: 30px;
    z-index: 1;
  }
  div[class^='leaflet-pane'] {
    z-index: 0;
  }
  div[class^='leaflet-top'] {
    z-index: 0;
  }
  div[class^='leaflet-bottom'] {
    z-index: 0;
  }
}

.popupStyle {
  border-radius: 8px !important;
  opacity: 1 !important;
}

.popup {
  position: relative;

  &__title {
    font-weight: 700;
    font-size: 14px;
    font-family: $monserrat;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.87) !important;
    width: calc(100% - 20px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    font-family: $monserrat;

    &.subtitle {
      font-style: italic;
    }
    &.jobNumber {
      margin-top: 10px;
      white-space: normal;
      word-break: break-word;
    }

    &.info {
      margin-top: 10px;
    }

    &.info__tooltip {
      margin: 10px;
    }

    &.directions {
      margin-top: 25px;
    }

    & > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .map {
    div[class='leaflet-control-zoom leaflet-bar leaflet-control'] {
      margin-bottom: 80px;
      margin-right: 15px;
    }
  }
}
