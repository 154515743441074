@import 'assets/styles/variables.scss';

button.add_button,
button.remove_button {
  width: 130px;
  height: 40px;
  border-radius: 8px;
  background-color: $main_color;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  font-family: $monserrat;
  line-height: 15px;
  display: flex;
  margin: 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0 rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  label {
    padding-left: 10px;
    cursor: pointer;
  }
  &:hover {
    box-shadow: 0 2px 4px -1px rgb(0, 0, 0, 0.2), 0px 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: $main_color !important;
  }
  &:disabled {
    background: #ccc;
    color: white;
  }
}
button.remove_button {
  margin-top: 40px;
}

@media screen and (max-width: $breakpoint-1180) {
  button.add_button,
  button.remove_button {
    width: 100%;
  }
}
