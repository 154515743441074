@import 'assets/styles/variables.scss';

$header-width: 115px;
$drat-header-width: 60px;
$pagination-width: 90px;

.table {
  max-height: calc(100vh - #{$drat-header-width} - #{$header-width} - #{$pagination-width});
  border-radius: 8px;
  margin-bottom: 30px;

  table {
    border-collapse: separate;
  }

  td {
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc !important;
    }
  }

  th {
    white-space: nowrap;
  }
}

.emptyTable {
  text-align: center;
  padding-top: 50px;
  & > span {
    font-size: 24px;
    font-family: $monserrat;
  }
}
