@import 'assets/styles/variables.scss';

.dialog {
  width: fit-content;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 427px;
    margin-bottom: 100px;
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.checkbox {
        width: 320px;
      }

      &.alignTop {
        align-items: flex-start;
        & > label {
          line-height: 32px;
        }
      }
    }
    &__column {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    & > button {
      width: 160px !important;
      min-width: 160px !important;
      height: 40px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    padding: 70px 20px 20px;
    min-height: 100%;
    height: auto;

    .form {
      width: 100%;
      &__row {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 10px;
        &.checkbox {
          width: 100%;
          justify-content: flex-end;
          flex-direction: row;
          align-items: center;
          column-gap: 50px;
        }

        &.alignTop {
          & > label {
            line-height: normal;
          }
        }
      }
    }

    .footer {
      & > button {
        width: 49% !important;
        min-width: 49% !important;
      }
    }
  }
}
