@import 'assets/styles/variables.scss';

.popup {
  width: fit-content;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow-x: hidden;

  .form {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__row,
    &__checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__notes {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__row,
    &__notes {
      width: 427px;
    }

    &__checkbox {
      width: 320px;
    }

    &__row {
      &_day_count {
        width: 250px;
        pointer-events: none;
        & > div {
          width: 80px !important;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 20px;
    row-gap: 20px;
    width: 427px;
    margin-top: auto;

    &:has(button:only-child) {
      justify-content: center;
    }

    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .form {
      &__row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 10px;
      }

      &__row,
      &__notes {
        width: 100%;
      }

      &__checkbox {
        width: 100%;
        justify-content: flex-end;
        column-gap: 50px;
      }
    }

    .footer {
      width: 100%;
    }
  }
}
