@import 'assets/styles/variables.scss';

button.main_button {
  height: 30px;
  width: 235px;
}

tr.template_row {
  &:hover {
    & > td.controlsCell {
      .controls {
        display: flex;
      }
    }
  }

  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  td.nameCell {
    width: 40%;
    min-width: 205px;
  }
  td.typeCell {
    width: 15%;
    min-width: 205px;
  }
  td.lastUpdateCell {
    width: 30%;
    min-width: 205px;
  }
  td.statusCell {
    width: 15%;
    min-width: 205px;
  }
  td.controlsCell {
    width: 90px;
    min-width: 90px;
    padding: 0;

    .controls {
      padding: 5px 16px;
      display: none;
      column-gap: 10px;
      text-align: center;
      justify-content: center;
      .icon_button {
        svg {
          color: black;
          width: 23px;
          height: 23px;
        }
      }
      .editingSpan {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
