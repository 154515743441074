@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 427px;

    & > label {
      font-family: $monserrat;
      font-size: 14px;
    }
  }

  &__toggleBar {
    margin: 20px 0;

    button {
      min-width: 125px;
    }

    span {
      font-size: 14px;
    }
  }

  .emptyBlock {
    text-align: center;
    padding-top: 50px;
    & > span {
      font-size: 18px;
      font-family: $monserrat;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    flex-direction: column;
    row-gap: 20px;
    &__row {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      & > label {
        margin-bottom: 10px;
      }
    }
  }
}
