@import 'assets/styles/variables.scss';

div.tooltip {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: white;
  color: black;
  box-shadow: $shadow;
  opacity: 1;
  max-width: 700px;
  white-space: normal;
  word-break: break-word;
}
