@import 'assets/styles/variables.scss';

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.file {
    align-items: flex-start;
    height: auto;
    & > label {
      line-height: $field_default_height;
    }
  }

  &.checkbox {
    width: 320px;
    height: $field_default_height;
  }

  &.column {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .formRow {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;

    &.file {
      & > label {
        line-height: normal;
      }
    }

    &.checkbox {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      column-gap: 50px;
    }
  }
}
