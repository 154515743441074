.table {
  tr.total {
    & > td {
      background: #7aa5ba;
      color: white;
      font-weight: 700;
      &:not(:first-child) {
        text-align: center;
      }
    }
    &:hover {
      & > td {
        background: #7aa5ba;
      }
    }
  }

  th,
  td {
    height: 40px;
    &:nth-child(1) {
      width: 25%;
      min-width: 200px;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 25%;
      min-width: 110px;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;

    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    &.capacityCell {
      font-weight: 700;
    }
    &.onRosterCell,
    &.onSiteCell,
    &.availableCell {
      text-align: center;
    }
  }
}
