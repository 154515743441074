@import 'assets/styles/variables.scss';

.form {
  display: flex;
  justify-content: space-between;
  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 20px;
    &:first-child {
      width: 427px;
    }
    &:last-child {
      width: 520px;
      .form__block_row {
        align-items: flex-start;
      }
    }
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 14px;
      }
      .textarea {
        width: 427px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    flex-wrap: wrap;
    div.form__block {
      width: 100%;
      &_row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        & > label {
          margin-bottom: 14px;
        }
        .textarea {
          width: 100%;
        }
      }
    }
  }
}
