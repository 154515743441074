@import 'assets/styles/variables.scss';

.popup {
  width: 1200px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;
  overflow-x: hidden;

  .title {
    & > h2 {
      text-align: center;
      width: 100%;
      max-width: 100%;
    }
  }

  .content {
    margin-bottom: 40px;

    .status_header {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .asset_field_row {
      & > td {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-text-size-adjust: 100%;
        &:not(:last-child) {
          border-right: 1px solid #dcdcdc;
        }

        &.nameCell {
          width: 20%;
          min-width: 125px;
        }
        &.countCell {
          width: 8%;
          min-width: 80px;

          &.bold {
            font-weight: bold;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: auto;

    & > button {
      width: 200px !important;
      min-width: 200px !important;
      height: 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .title {
      & > h2 {
        text-align: left;
      }
    }
  }
}
