@import 'assets/styles/variables.scss';

.wrapper {
  width: 60vw;
  height: 600px;

  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0;
    padding: 0 10px;
    box-sizing: border-box;
    &__controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      column-gap: 15px;
      button {
        padding: 3px !important;
        border-radius: 8px;
        &:hover {
          background: rgba(0, 0, 0, 30%);
        }
        svg {
          color: #ffffff;
        }
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
      &.rotation0 {
        transform: rotate(0);
      }
      &.rotation90 {
        transform: rotate(90deg);
        max-width: 550px;
      }
      &.rotation180 {
        transform: rotate(180deg);
      }
      &.rotation270 {
        transform: rotate(270deg);
        max-width: 550px;
      }
    }
  }

  .footer {
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;

    .header {
      background: #fff;
      height: 60px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 40px;
      &__controls {
        width: fit-content;
        button {
          background: #f6f6f6;
          height: 30px;
          width: 30px;
          &:hover {
            background: #f6f6f6;
          }
          svg {
            color: black;
          }
        }
      }
      h1 {
        font-family: $monserrat;
        line-height: 38px;
        margin: 0;
      }
    }

    .image {
      height: calc(100vh - 60px - 60px - 20px);
      margin-bottom: 20px;
      padding: 0;
      img {
        &.rotation90 {
          max-width: calc(100vh - 60px - 20px - 60px);
        }
        &.rotation270 {
          max-width: calc(100vh - 60px - 20px - 60px);
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: auto;
      & > button {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
