@import 'assets/styles/variables.scss';

.selectorContainer {
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px !important;
  width: 250px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;

  .itemsBlock {
    display: flex;
    flex-direction: column;
    max-height: calc(40px * 8);
    overflow-y: auto;

    .itemsRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      .item_label {
        font-family: $monserrat;
        font-size: 14px;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
        cursor: pointer;
      }
    }

    & label {
      margin: 0;
    }
  }

  .popup_buttons {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;

    button {
      min-width: 30px;
      width: 49%;
      margin: 0;
      height: 25px;
    }
  }
}

.checkbox {
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &__rihghtMargin {
    margin-right: 20px;
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popperContainer {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100vw;
    height: 100%;
    z-index: 10 !important;

    .selectorContainer {
      border-radius: 0 !important;
      margin: 0;
      width: 100vw;
      height: 100%;
      padding: 60px 20px 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .popupHeader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding-left: 60px;
      display: flex;
      justify-content: space-between;

      h1 {
        margin: 0;
        line-height: 60px;
        font-family: $monserrat;
      }
    }

    .itemsBlock {
      max-height: 100%;
    }

    .popup_buttons {
      & button {
        height: 40px;
      }
    }
  }
}
