@import 'assets/styles/variables.scss';

$block-width: 450px;
$gap-large: 100px;
$gap-medium: 20px;
$input-padding: 18px 46px 18px 32px;
$input-padding-mobile: 8px 16px;

@mixin flex-column($gap: $gap-medium) {
  display: flex;
  flex-direction: column;
  row-gap: $gap;

  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;
  }
}

@mixin flex-row($gap: $gap-large) {
  display: flex;
  column-gap: $gap;

  @media screen and (max-width: $breakpoint-1180) {
    flex-direction: column;
    row-gap: $gap-medium;
    width: 100%;
  }
}

.container {
  @include flex-column;

  .other {
    @include flex-column;
    width: 100%;
    .expandedField {
      width: 100%;
      input {
        padding: $input-padding;

        @media screen and (max-width: $breakpoint-1180) {
          padding: $input-padding-mobile;
        }
      }
    }
  }

  .looseItems {
    @include flex-row;

    .block {
      @include flex-column;
      width: $block-width;
    }
  }

  .assets {
    @include flex-column;

    &__filter {
      @include flex-row;
      &_block {
        @include flex-column;
        width: $block_width;
      }
    }

    &__field {
      width: 100%;

      &_search {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
      &_select {
        width: $block_width;
      }
    }
  }
}
