@import 'assets/styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__row {
    display: flex;
    align-items: center;

    & > label {
      font-size: 14px;
      font-family: $monserrat;
      width: 205px;
      margin-right: 20px;
    }

    &.selector {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    &__row {
      flex-direction: column;
      align-items: flex-start;

      & > label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      & > div {
        width: 100%;
      }
    }
  }
}
