@import 'assets/styles/variables.scss';

.wrapper {
  width: 550px;
  height: 560px;
  box-sizing: border-box;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  .form {
    &__input {
      margin-bottom: 10px;
      &_field {
        background: #f7f7f7;
        border-radius: 8px;
        width: 100%;
        margin: 0;
        border: 1px solid transparent;
        fieldset {
          border: none;
        }
        input {
          font-family: $monserrat;
          font-weight: 400;
          padding: 8px 16px 8px 8px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          color: #000;

          &::placeholder {
            opacity: 1;
            color: #000;
          }
        }
      }
      &_clear {
        width: 15px;
        height: 15px;
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      max-height: calc(9 * 35px);
      row-gap: 10px;
      overflow: hidden auto;
      &_option {
        display: flex;
        flex-direction: column;
        & > label {
          display: flex;
          align-items: center;
          min-height: 25px;
          line-height: 25px;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
          margin: 0;
          & > span {
            font-family: $monserrat;
            min-height: 24px;
            line-height: 24px;
            white-space: nowrap;
            max-width: calc(100% - 25px - 30px);
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
      &_empty {
        font-family: $monserrat;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    row-gap: 20px;
    button {
      margin: 0;
      width: 180px;
      min-width: 180px;
    }
  }
}

.separator {
  margin-top: 10px;
  max-width: 99%;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .form {
      height: calc(100% - 40px - 20px);
      &__fields {
        max-height: calc(100% - 35px - 10px);
      }
    }

    .footer {
      column-gap: 20px;
      & > button {
        width: 46% !important;
        min-width: 46% !important;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
