@import 'assets/styles/variables.scss';

.header {
  margin: 0 0 -10px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  font-family: $monserrat;
  line-height: 30px;
  margin: 0;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  font-family: $monserrat;
  margin: 5px 0;
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    margin: 0 0 30px;
  }
}
