@import 'assets/styles/variables.scss';

.input_wrapper {
  width: 250px;
  position: relative;

  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
    font-family: $monserrat;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .input_wrapper {
    width: 100%;
  }
}
