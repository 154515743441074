@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerBlock {
    margin: 0 0 30px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    width: 427px;
    .formRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      & > label {
        font-family: $monserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .footerControls {
    margin: 30px 0 0;
    button {
      margin: 0 25px 40px 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 80px 20px 20px;
    min-height: 100vh;

    .headerBlock {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      padding: 10px 20px;
      background: #fff;
      box-sizing: border-box;
      z-index: 1;

      h1 {
        padding-left: 40px;
        line-height: 40px;
      }
    }

    .formWrapper {
      width: 100%;
      .formRow {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        label {
          margin: 0 0 10px;
        }
      }
    }

    .footerControls {
      display: flex;
      justify-content: space-between;
      margin: 0;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
}
