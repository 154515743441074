.form_manager {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  margin-bottom: 50px;
  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    row-gap: 50px;
    &_delete {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.form {
  display: flex;
  column-gap: 100px;
  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 427px;
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.alignTop {
        align-items: flex-start;
        & > label {
          line-height: 32px;
        }
      }
    }
  }
}
