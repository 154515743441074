@import 'assets/styles/variables.scss';

.prompt {
  font-family: $monserrat;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

.form {
  display: flex;
  flex-direction: column;
  column-gap: 100px;
  row-gap: 30px;
  margin-bottom: 60px;

  .type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 427px;
  }

  $row_height: 33.6px;
  $rows_per_block: 5;
  $row_gap: 30px;

  .columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: $row_gap;
    height: calc(($row_height * $rows_per_block) + ($row_gap * ($rows_per_block - 1)));
    width: 1000px;
    &__row {
      width: 427px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $row_height;
    }
  }
}
