@import 'assets/styles/variables.scss';

.info {
  display: block;
  label,
  span {
    font-style: normal;
    font-size: 14px;
    font-family: $monserrat;
  }
  & > label {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 10px;
  }
  & > span {
    white-space: normal;
    word-break: break-all;
  }
  &.checkboxRow {
    align-items: center;
    & > span {
      margin-left: 40px;
    }
  }
}
