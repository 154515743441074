@import 'assets/styles/variables.scss';

.wrapper {
  margin-bottom: 30px;
  .table {
    box-sizing: border-box;
    width: 860px;

    th,
    td {
      padding: 5px 0;
      background-color: transparent !important;
      border: none;
      &:nth-child(1) {
        width: 70%;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 180px;
        text-align: center;
      }
      &:nth-child(3) {
        width: 15%;
        min-width: 220px;
        text-align: center;
      }
    }

    .chip {
      background-color: #7aa5ba;
      border-radius: 8px;
      color: white;
      margin: 0;
      font-family: $monserrat;

      & > span {
        padding: 6px 16px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .table {
      width: 100%;

      th {
        font-size: 10px;
      }

      th,
      td {
        &:nth-child(1) {
          max-width: calc(100vw - 80px - 85px - 40px);
        }
        &:nth-child(2) {
          width: 80px;
          min-width: 80px;
        }
        &:nth-child(3) {
          width: 85px;
          min-width: 85px;
          line-height: initial;
          padding-left: 5px;
        }
      }

      .chip {
        max-width: 100%;
      }
    }
  }
}
