@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  margin-bottom: 50px;
  column-gap: 20px;

  h3 {
    margin: 0 0 20px;
    word-break: break-word;
  }
}

.main {
  font-size: 14px;
  width: calc(100% - 345px);

  &__info {
    margin-bottom: 40px;
    box-sizing: border-box;

    & > div {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &_row {
      display: flex;
      align-items: baseline;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      label,
      span {
        font-style: normal;
        font-size: 14px;
        color: #000000;
      }
      & > label {
        font-weight: bold;
        white-space: nowrap;
      }
      & > span {
        margin-left: 10px;
        white-space: normal;
        word-break: break-all;
      }
    }
  }

  &__position {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    &_mismatched {
      flex-basis: 100%;
      margin-bottom: 20px;

      & > span {
        font-family: $monserrat;
        font-weight: 600;
        font-style: italic;
        color: rgba(138, 138, 138, 1);
      }
    }

    &_info {
      flex-basis: 40%;
      h3 {
        margin-bottom: 0;
      }

      p {
        white-space: normal;
        word-break: break-word;
      }

      p:nth-of-type(1) {
        margin-top: 20px;
      }

      span {
        display: block;
        margin: 15px 0 10px;
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__sublocations,
  &__notes {
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      word-break: break-word;
      white-space: pre-line;
    }
  }

  &__sublocations {
    margin-bottom: 30px;
  }
}

.extra {
  display: flex;
  flex-direction: column;
  width: 345px;

  &__actionsMenu {
    z-index: 1;
    button {
      display: flex;
      justify-content: space-between;
      font-family: $monserrat;
      color: #fff;
      background-color: $main_color;
      border-radius: 8px;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      margin-bottom: 10px;
      padding-left: 15px;
      transition: all 0.3ms ease-out;
      box-shadow: $shadow;

      svg {
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        background-color: $main_color;
      }
    }

    .menuContainer {
      width: 346px;
      border-radius: 8px;
      margin-top: 10px;

      ul {
        padding: 0;
        li {
          font-family: $monserrat;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          height: 40px;

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    .opened {
      transform: rotate(180deg);
    }
  }

  &__creator {
    margin: 5px 0 25px;
    &_info {
      font-size: 10px;
      font-style: italic;
      margin: 5px 0;
      padding: 0 10px;
    }
  }

  &__info {
    margin-bottom: 30px;
    h3 {
      margin: 0 0 10px;
    }
    &_data {
      overflow-y: auto;
      max-height: calc(((17px + 10px) * 4) + 2px);
      p {
        margin: 0 0 10px;
        font-size: 14px;
      }
      p:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &_file {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    flex-direction: column-reverse;
  }

  .main {
    width: 100%;
    &__info {
      &_row {
        & > label {
          font-weight: normal;
        }
        & > span {
          font-weight: bold;
          font-size: 12px;
        }
      }
      button {
        width: 100%;
      }
    }

    &__position {
      flex-direction: column;
      &_info {
        flex-basis: 100%;
        &:first-child {
          margin-bottom: 30px;
        }
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .extra {
    width: 100%;
    &__actionsMenu {
      width: 100%;
      button {
        width: 100%;
      }
      .menuContainer {
        transform: translate3d(calc((100% - 346px) / 4), 0px, 0px) !important;
        will-change: transform !important;
      }
    }
  }
}
