@import 'assets/styles/variables.scss';

.wrapper {
  width: 40px;
  display: flex;
  justify-content: center;
  button.btn {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    & > span {
      width: inherit;
      & > img {
        width: inherit;
        height: inherit;
      }
    }
    &:hover {
      background: transparent;
    }
  }
}

.paper {
  padding: 10px;
  border-radius: 8px !important;
  width: auto;
  min-width: calc((54px * 3) + 20px + 20px);
  max-width: calc((54px * 3) + 20px + 20px + 20px);
  max-height: calc((54px * 3) + 20px + 20px + 10px);
  overflow: auto;
  box-sizing: border-box;

  .files {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    button.files_item {
      max-width: 100%;
      border-radius: initial;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
        font-size: 14px;
        display: block;
        text-align: left;
        font-family: $monserrat;
        text-decoration: underline;
      }
    }
  }
}
