@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  width: fit-content;
  .signature {
    position: relative;
    &__preview {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      &_button {
        padding: 0;
        border-radius: 8px;
        background: $main_color;
        width: 135px;
        height: 30px;
        box-shadow: $shadow;
        &:hover {
          box-shadow: $shadow_hover;
          background: $main_color;
        }
        span {
          font: {
            family: $monserrat;
            size: 12px;
          }
          color: #fff;
          text-transform: uppercase;
        }
      }
      &_image {
        width: 135px;
        height: 135px;
        border: 0.5px solid #8a8a8a;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        position: relative;
        &_delete {
          position: absolute;
          top: -8px;
          right: -7px;
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 50%;
          &:hover {
            background-color: #f6f6f6;
          }
          svg {
            width: 100%;
            height: 100%;
            color: black;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__error {
      position: absolute;
      font-weight: 400;
      font-size: 10px;
      left: 0;
      bottom: -16px;
      color: $error_color;
      font-family: $monserrat;
    }
  }
}
