@import 'assets/styles/variables.scss';

$option_height: 35px;

.wrapper {
  width: 250px;
  box-sizing: border-box;
  padding: 0;
  border-radius: 8px;
  position: relative;

  div.input_root {
    padding: 8px 16px;
    background: #f7f7f7;
    border: 1px solid transparent;
    border-radius: 8px;
    height: $field_default_height;

    &.invalid {
      border-color: $error_color;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    & > input {
      font-family: $monserrat;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #000;
      padding: 0 !important;
    }
    fieldset {
      border: none;
    }
  }

  & > span {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    left: 0;
    bottom: -16px;
    color: $error_color;
  }
}

.popper {
  position: absolute;
  font-family: $monserrat;
  margin-top: 3px;
  min-width: 250px;
  width: auto;
  max-width: 40vw;
}

.no_options {
  font-family: $monserrat;
  color: black;
  cursor: default;
  padding: 5px 12px;
}

li.option {
  padding: 5px 12px;
  color: black;
  height: $option_height;
  font-family: $monserrat;
  font-size: 14px;
}

ul.listbox {
  max-height: calc(($option_height * 7) + 10px);
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
  }
}
