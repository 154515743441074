@import 'assets/styles/variables.scss';

.fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  &__header {
    color: black;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
  }

  &__message {
    color: black;
    font-size: 16px;
    margin: 0;
  }
}
