@import 'assets/styles/variables.scss';

.form {
  width: 900px;
  box-sizing: border-box;
  display: flex;
  column-gap: 60px;
  &__block {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    row-gap: 20px;
    margin-bottom: 20px;
    &_cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
      &_input {
        position: relative;
        width: 250px;
      }
      &.checkbox {
        height: 34px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    &__block {
      flex-direction: column;
      &_cell {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &_label {
          margin: 0 0 10px 0;
        }
        &_input {
          width: 100%;
        }
        &.checkbox {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          & > label {
            margin: 0 40px 0 0;
          }
        }
      }
    }
  }
}
