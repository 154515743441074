@import 'assets/styles/variables.scss';

.release {
  font-family: $monserrat;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ddd;
    padding-bottom: 8px;
  }

  &__version {
    font-size: 14px;
    font-weight: bold;
    background-color: $main_color;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  &__date {
    font-size: 16px;
    color: #666;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__section {
    background: #fff;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__section_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    margin: 0;
  }

  &__section_notes {
    padding-left: 20px;
    list-style: disc;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 0;
  }

  &__section_note {
    font-size: 14px;
    color: #333;
    line-height: 1.4;
  }
}
