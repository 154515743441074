@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 60px 20px;
  font-family: $monserrat;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 110px 20px 20px;
  }
}
