.footer {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin: auto 0 0;
  width: 427px;
  position: relative;

  & > button {
    width: 50%;
    min-width: 40%;
    margin: 0 !important;
  }
}
