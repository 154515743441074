@import 'assets/styles/variables.scss';

button.dotsButton {
  border-radius: 50%;
  background-color: #aaa;
  width: 20px;
  min-width: 20px;
  height: 20px;
  color: white;
  padding: 0;
  &:hover {
    background-color: #aaa;
  }
}

.paper {
  margin-top: 10px;
  padding: 15px 0;
  border-radius: 8px !important;

  ul {
    padding: 0;
    li {
      font-family: $monserrat;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      min-height: 40px;
      padding: 6px 10px;

      svg,
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: scale-down;
        margin-left: 3px;
      }
    }
  }
}
