@import 'assets/styles/variables.scss';

.field {
  padding: 8px 8px 8px 16px;
  background: #f7f7f7;
  border: 1px solid transparent;
  border-radius: 8px;
  height: 36px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.invalid {
    border-color: $error_color;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__value {
    font-family: $monserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000;
  }

  &__controls {
    display: flex;
    align-items: center;

    button.event {
      svg {
        color: black;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.inputWrapper {
  position: relative;
  width: 250px;
  font-family: $monserrat;

  button.clearButton {
    font-family: $monserrat;
    font-weight: 500;
    font-size: 14px;
    color: $main_color;
    display: flex;
    align-items: center;
    margin: 5px 0 5px;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  & > div {
    width: 100%;
  }

  .picker {
    & > div[class='react-datepicker__month-container'] {
      & > div:first-child {
        background-color: #f6f6f6;
        font-family: $monserrat;
        & > div:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }

  .calendarDay {
    font-family: $monserrat;
    font-size: 14px;
  }

  div.popperClassName {
    z-index: 2;
  }

  div[class='react-datepicker__month-container'] {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .inputWrapper {
    width: 100%;
  }
}
