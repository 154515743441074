@import 'assets/styles/variables.scss';

.inputValueSpan {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapper {
  position: relative;

  .clear {
    position: absolute;
    top: 9px;
    right: 35px;
    width: 16px;
    height: 16px;
    svg {
      color: black;
      width: 16px;
      height: 16px;
    }
  }
}

div.selectInput {
  border: 1px solid transparent;
  transition: none;
  height: $field_default_height;
  display: flex;
  align-items: center;

  &.clearable_input {
    padding: 6px 52px 6px 16px !important;
  }

  &.error {
    border-color: $error_color;
  }

  &.table_view {
    background-color: transparent;
  }
}

li.selectInput__menu {
  font-family: $monserrat;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  line-height: 32px;
}

li.selectInput__emptyMenu {
  font-family: $monserrat;
  max-width: 100%;
  cursor: default;
}

svg.selectInput__icon {
  right: 10px;
  color: #000;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: calc(100vw - 40px);
    div.selectInput {
      width: 100%;
    }
  }
}
