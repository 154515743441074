@import 'assets/styles/variables.scss';

button.quickButton {
  width: 150px;
  height: 30px;
  background: #ffffff;
  border: 0.5px solid #8a8a8a;
  border-radius: 6px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  font: {
    family: $monserrat;
    style: normal;
    weight: 500;
    size: 12px;
  }
  &:hover {
    background: #ffd83b;
  }
  &:not(:last-child) {
    margin-right: 20px;
  }
}
