@import 'assets/styles/variables.scss';

$checkboxWidth: 50px;
$assetWidth: 140px;
$sublocationWidth: 180px;
$statusWidth: 80px;
$rentalWidth: 140px;
$lastVerifiedWidth: 120px;
$verifiedWidth: 180px;
$noteWidth: 200px;

div.table {
  border-radius: 8px;
  overflow-x: initial;
  margin-bottom: 15px;

  table {
    border-collapse: initial;
  }
  th {
    white-space: nowrap;
    &.checkboxHead {
      width: $checkboxWidth;
      min-width: $checkboxWidth;
    }
    &.assetHead {
      width: 15%;
      min-width: $assetWidth;
    }
    &.sublocationHead {
      width: 10%;
      min-width: $sublocationWidth;
    }
    &.statusHead {
      width: $statusWidth;
      min-width: $statusWidth;
    }
    &.rentalHead {
      width: 10%;
      min-width: $rentalWidth;
    }
    &.lastVerifiedHead {
      width: 10%;
      min-width: $lastVerifiedWidth;
    }
    &.verifiedByHead {
      width: 10%;
      min-width: $verifiedWidth;
    }
    &.noteHead {
      width: 25%;
      min-width: $noteWidth;
    }
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &.checkboxCell {
      width: $checkboxWidth;
      min-width: $checkboxWidth;
    }
    &.assetCell {
      width: 15%;
      min-width: $assetWidth;
    }
    &.sublocationCell {
      width: 10%;
      min-width: $sublocationWidth;
    }
    &.statusCell {
      width: $statusWidth;
      min-width: $statusWidth;
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
    }
    &.rentalCell {
      width: 10%;
      min-width: $rentalWidth;
      text-align: center;
    }
    &.lastVerifiedCell {
      width: 10%;
      min-width: $lastVerifiedWidth;
    }
    &.verifiedByCell {
      width: 10%;
      min-width: $verifiedWidth;
    }
    &.noteCell {
      width: 25%;
      min-width: $noteWidth;
    }

    &.assetCell,
    &.lastVerifiedCell,
    &.verifiedByCell,
    &.noteCell {
      white-space: normal;
      word-break: break-word;
    }
  }
  td.empty {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 35px;

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 20px;

    div.header__actions_field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 427px;
      button.header__actions_field_status_button {
        width: 150px;
        height: 30px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        font-family: $monserrat;
        background: $extra_color;
        color: black;
        box-shadow: $shadow;
        &:hover {
          background: $extra_color;
          box-shadow: $shadow_hover;
        }
      }
    }
  }

  div.controls {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  & > section {
    padding-top: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.table {
    width: 100vw;
    overflow-x: auto;
  }

  .header {
    height: 33px;
    padding: 0 20px;

    &__actions {
      width: 100%;
      box-sizing: border-box;
      div.header__actions_field {
        width: 100%;
        & > div {
          width: calc(100% - 30px - 20px);
          & > div {
            width: 100%;
          }
        }
        button.header__actions_field_status_button {
          min-width: 30px;
          width: 30px;
          height: 30px;
          svg {
            width: 20px;
            height: 20px;
            color: black;
          }
        }
      }
    }
  }

  .footer {
    padding: 0 20px 0;
  }
}
